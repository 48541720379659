@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap);
@charset "utf-8";
/*loading*/
.common_pageLoading__9EedR { 
	overflow:hidden; 
	position:fixed; 
	top:0; 
	left:0; 
	z-index:1000; 
	width:100%; 
	height:100%;
	}
.common_pageLoading__9EedR::before { 
	content:''; 
	display:block; 
	position:absolute; 
	top:0;
	left:0;
	width:100%;
	height:100%;
	background:#ffffff;
	transition:all .4s ease;
	}
.common_pageLoading__9EedR .common_loadingImgArea__3_Ax0 {
	display:block; 
	position:absolute; 
	top:50%; 
	left:50%;
	width:100px; 
	height:100px;
	transform:translate(-50%, -50%);
}

/*imgMove*/
.common_loadingImgMoveWrap__1Xjhz{
	width:100%;
	height:100%;
	position:absolute;
}
.common_loadingImgMove__1VHs6{
	display:block;
	width:100%;
	height:100%;
	position:absolute;
	overflow:hidden;
}
.common_loadingImgMove__1VHs6 img{
	height:100%;
	animation:common_imgMove__3IUMu 5s steps(5) infinite both;
}
@keyframes common_imgMove__3IUMu{
	0%{
		transform:translateX(0);
	}
	100%{
		transform:translateX(-100%);
	}
}
/*loadingBaloon*/
.common_loadingBaloon__3slJC{
	animation:common_baloon__2Qhv1 1s linear infinite alternate both;
}
@keyframes common_baloon__2Qhv1{
	0%{
		transform:translateY(0px);
		}
	100%{
		transform:translateY(20px);
		}
}
/*common*/
@font-face {
     font-family: 'S-CoreDream-3Light';
     src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_six@1.2/S-CoreDream-3Light.woff') format('woff');
     font-weight: normal;
     font-style: normal;
}
@font-face {
     font-family: 'S-CoreDream-5Medium';
     src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_six@1.2/S-CoreDream-5Medium.woff') format('woff');
     font-weight: normal;
     font-style: normal;
}
@font-face {
     font-family: 'S-CoreDream-6Bold';
     src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_six@1.2/S-CoreDream-6Bold.woff') format('woff');
     font-weight: normal;
     font-style: normal;
}
@font-face {
     font-family: 'S-CoreDream-7ExtraBold';
     src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_six@1.2/S-CoreDream-7ExtraBold.woff') format('woff');
     font-weight: normal;
     font-style: normal;
}
@font-face {
     font-family: 'S-CoreDream-8Heavy';
     src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_six@1.2/S-CoreDream-8Heavy.woff') format('woff');
     font-weight: normal;
     font-style: normal;
}
@font-face {
     font-family: 'S-CoreDream-9Black';
     src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_six@1.2/S-CoreDream-9Black.woff') format('woff');
     font-weight: normal;
     font-style: normal;
}
*{
	font-family:'S-CoreDream-5Medium','Noto Sans KR', AppleSDGothicNeo-Regular,"Malgun Gothic","맑은 고딕",dotum,'돋움',sans-serif; 
	font-size:18px; 
	color:#3b3b3b; 
	line-height: 1.6; 
	margin:0; 
	padding:0;
}
br{
	line-height:0;
}
html,body{
	height:100%;
	margin:0;
	padding:0;
	display:block;
	overflow:visible;
}
.common_body_black__2d8YG{
	display:none;
	background:#00000090;
	position:fixed;
	top:0;
	left:0;
	width:100%;
	height:100%;
	overflow:hidden;
	z-index:130;
}
section{
	width:100%;
	height:100vh;
	position:relative;
	overflow:hidden;
	padding-top:80px;
	box-sizing:border-box;
	font-size:16px;
}
section.common_footer_section__23XeD{
	height:auto;
	padding-top:0;
}
.common_inner__1cU6t{
	width:1200px;
	height:100%;
	margin:0 auto;
}
.common_inner90__3qaGh{
	width:90%;
	height:100%;
	margin:0 auto;
	position:relative;
}
.common_cf__3GUWP::after{
	display:block; 
	content:''; 
	clear:both;
}
.common_content_wrapper__2zRjo{
	width:100%;
	height:100%;
	min-height:100%;
	position:relative;
	padding-top:80px;
	box-sizing:border-box;
}
span.common_tit__PVpaj{
	font-size:32px; 
	font-family: 'S-CoreDream-6Bold';
	text-align:center;
}
span.common_color__1GXBX{
	color:#009cc6;
	font-size:inherit;
	font-family:inherit;
	font-weight:inherit;
}
span, p{
	white-space:normal; 
	word-break:keep-all; 
	word-wrap:break-word;
	letter-spacing:-.05rem;
}
.common_main_full_area__2meLD img, 
.common_main_full_area__2meLD div, 
.common_main_full_area__2meLD li, 
.common_main_full_area__2meLD a, 
.common_main_full_area__2meLD span, 
.common_main_full_area__2meLD p, 
.common_main_full_area__2meLD ul, 
.common_main_full_area__2meLD section{
	transition:all 1s;
}
li{list-style:none;}
a{
	text-decoration:none; 
	color:#333333;
}
a:hover{
	color:#f29500;
}
.common_showBounce__E28_q{
	opacity:0;
	animation:common_showBounce__E28_q 1s ease-out both;
}
.common_showBounce__E28_q.common_sb2__2xvTB{
	animation-delay:.18s;
}
.common_showBounce__E28_q.common_sb3__1qIWt{
	animation-delay:.36s;
}
.common_showBounce__E28_q.common_sb4__11dcH{
	animation-delay:.54s;
}
/*showBounce*/
@keyframes common_showBounce__E28_q {
  0% {
	  opacity:0;
	  transform: translateY(12px);
	  }
  40% {
	  opacity:1;
	  transform: translateY(-12px);
	  }
  100% {
	  opacity:1;
	  transform: translateY(0px);
	  }
}
/*fadeInUp*/
.common_fadeInUp__ZyYuU{
	opacity:0;
	animation:common_fadeInUp__ZyYuU .8s ease-in-out .1s both;
}
.common_fiu2__3ACpl{animation-delay:.8s;}
.common_fiu3__3Xn2z{animation-delay:1.6s;}
.common_fiu4__35O5c{animation-delay:2.4s;}
/*fadeInUp*/
@keyframes common_fadeInUp__ZyYuU {
  0% {
    opacity: 0;
    transform: translate3d(0, 20%, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
/*bounceIn*/
.common_bounceIn__36vv4{ 
	animation:common_bounceIn__36vv4 1s linear both;
}
@keyframes common_bounceIn__36vv4 {
  0% {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  25% {
    transform: scale3d(1.1, 1.1, 1.1);
  }

  50% {
    transform: scale3d(.9, .9, .9);
  }

  75% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03);
  }

  to {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}

/*z-index guide*/
/*
body_bg(반투명 검은 배경) : 130
모바일 메뉴 : 999;
layer_popup : 200~250
float_btn_wrap : 90~99
header : 100~110
contents(.section) : 0~30
footer : 30~40
*/
/*header*/
.common_header__3rYGR{
	width:100%; 
	height:80px; 
	background:#ffffff; 
	position:fixed; 
	top:0; 
	z-index:100; 
}
.common_header__3rYGR .common_inner__1cU6t{
	display:-webkit-box; 
	-webkit-box-align:center; 
	display:flex; 
	align-items:center;
	position:relative;
	z-index:101;
}
.common_header__3rYGR .common_logo__32lKi{
	display:block; 
	width:auto; 
	height:50px; 
	margin-left:66px;
	margin-top:12px;
}
.common_header__3rYGR .common_logo__32lKi img{
	height:100%;
}
.common_menu_wrapper__3UAYG{
	margin-left:86px;
}
.common_header__3rYGR .common_menu__2IzD_{
	width:auto;
	margin:0 auto; 
	display:flex; 
}
.common_menu_list__vB34A{
	margin:0 12px;
	width:159px;
	height:80px;
}
.common_menu_list__vB34A:nth-child(1){
	margin-left:0;
}
.common_menu_list__vB34A a{
	width:100%;
	height:100%;
	font-size:15px; 
	text-align:center;
	display:-webkit-box; 
	-webkit-box-align:center; 
	-webkit-box-pack:center; 
	display:flex; 
	justify-content:center;
	align-items:center;
	color:#1a1a1a;
	font-family: 'S-CoreDream-6Bold';
}
.common_menu_list__vB34A.common_on__2QUU7{
	background:#009cc6;
}
.common_menu_list__vB34A.common_on__2QUU7 a{
	color:#ffffff;
}
.common_menu_list__vB34A:nth-child(1).common_on__2QUU7{
	background:none;
}
.common_menu_list__vB34A:nth-child(1).common_on__2QUU7 a{
	color:#009cc6;
}
.common_menu_list__vB34A a:hover{
	color:#009cc6;
}
.common_menu_list__vB34A.common_on__2QUU7 a:hover{
	color:#ffffff;
}
.common_menu_list__vB34A:nth-child(1).common_on__2QUU7 a:hover{
	color:#009cc6;
}
/*login&mypage*/
.common_header__3rYGR .common_button__2VIg2{
	display:block; 
	width:167px; 
	height:38px;
	position:relative;
}
.common_header__3rYGR .common_button__2VIg2 .common_member__20zDw{
	width:100%; 
	height:100%;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
	/* visibility:hidden; */
	visibility:visible;
}
.common_header__3rYGR .common_button__2VIg2 .common_member__20zDw a{
	width:86px; 
	height:auto; 
	display:-webkit-box; 
	-webkit-box-align:center; 
	-webkit-box-pack:center; 
	display:flex; 
	align-items:center; 
	justify-content:center;
	position:relative;
}
.common_header__3rYGR .common_button__2VIg2 .common_member__20zDw .common_login__btOgY{
	color:#009cc6; 
	width:80px;
}
.common_header__3rYGR .common_button__2VIg2 .common_member__20zDw .common_login__btOgY::after{
	content:'|';
	display:block;
	color:#e6e6e6;
	position:absolute;
	right:0;
}
.common_header__3rYGR .common_button__2VIg2 .common_member__20zDw .common_join__3Z5xH{
	color:#666666;
}
/*로그인시 #my_page=display:block, .member=visibility:hidden;*/
/*비로그인시 #my_page=display:none, .member=visibility:visible;*/
#common_my_page__2fmog{
	/* display:block;  */
	display:none; 
	width:100%; 
	height:100%; 
	position:absolute; 
	top:0px; 
	left:0px; 
	background:inherit;
}
#common_my_page__2fmog .common_my_cover__CW04B{
	width:100%; 
	height:100%; 
	position:relative; 
	display:-webkit-box; 
	-webkit-box-align:center; 
	-webkit-box-pack:end; 
	display:flex; 
	align-items:center; 
	justify-content:flex-end;
	box-sizing:border-box;
	cursor:pointer;
}
.common_my_nikname__2j_oE{
	width:70px;
	text-align:right;
	overflow:hidden;
	text-overflow:ellipsis; 
	white-space:nowrap;
	color:#666666;
}
#common_my_page__2fmog .common_my__1rm2j{
	width:auto; 
	height:38px; 
	margin-left:26px; 
	cursor:pointer; 
}
#common_my_page__2fmog .common_my__1rm2j img{
	width:auto; 
	height:100%;
}
#common_my_page__2fmog .common_my_menu__1DFwV{
	display:none; 
	width:150px; 
	height:auto; 
	position:absolute; 
	top:64px;  
	left:50%;
	transform:translateX(-50%);
}
#common_my_page__2fmog .common_my_menu__1DFwV ul{
	display:block; 
	position:absolute;
	width:100%; 
	height:auto; 
	background:#ffffff;
	border-radius:40px;
	border:1px solid #e1e1e1;
	padding:10px;
}
#common_my_page__2fmog .common_my_menu__1DFwV li{
	width:100%; 
	margin:0;
	display:-webkit-box; 
	-webkit-box-align:center; 
	-webkit-box-pack:center; 
	display:flex; 
	align-items:center; 
	justify-content:center; 
}
#common_my_page__2fmog .common_my_menu__1DFwV li:first-child{
	height:65px;
	border-bottom:1px solid #bdbdbd; 
	box-sizing:border-box;
}
#common_my_page__2fmog .common_my_menu__1DFwV li:first-child div{
	width:auto; 
	height:32px; 
	margin-right:10px;
	position:relative;
}
#common_my_page__2fmog .common_my_menu__1DFwV li:first-child div img{
	width:auto; 
	height:100%;
}
#common_my_page__2fmog .common_my_menu__1DFwV li:first-child p{
	display:block; 
	width:70px; 
	overflow:hidden; 
	text-overflow:ellipsis; 
	white-space:nowrap;
}
#common_my_page__2fmog .common_my_menu__1DFwV .common_page__3ZnCt{
	height:44px; 
}
#common_my_page__2fmog .common_my_menu__1DFwV .common_page__3ZnCt a{
	font-size:16px; 
	font-weight:400;
	display:block;
	position:relative; 
}

/*mobile_menu_btn_area*/
.common_mobile_menu_btn_area__u63Re{
	display:none; 
	width:auto;
	height:24px;
}
.common_my_menu__1DFwV .common_page__3ZnCt a:hover{color:#f29500;}
.common_mobile_menu_btn_area__u63Re>img{
	height:100%;
	cursor:pointer;
}
.common_mobile_menu_area__2aife{ 
	overflow:hidden; 
	position:fixed; 
	top:0; 
	left:-100%; 
	z-index:999; 
	width:100%; 
	height:100%;
	display:-webkit-box;
	-webkit-box-pack:start;
	display:flex;
	justify-content:flex-start;
}
.common_mobile_menu_wrap__2klDq{
	width:100%;
	height:100%;
	background:#ffffff;
	position:relative;
}
.common_mobile_menu_wrap__2klDq .common_mobile_menu_top_area__3ziQd{
	background:#ffffff;
	position:relative;
	top:0;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:justify;
	display:flex;
	align-items:center;
	justify-content:space-between;
	width:100%;
	height:80px;
	padding:20px;
	box-sizing:border-box;
}
.common_mobile_menu_wrap__2klDq .common_mobile_menu_top_area__3ziQd img{
	height:42px;
	margin-top:12px;
}
.common_mobile_menu_wrap__2klDq .common_mobile_menu_top_area__3ziQd img.common_back__CubvJ{
	height:24px;
	cursor:pointer;
	margin-top:0;
}
/* mobile_my_area */
/* 비로그인 상태 : .login_wrap{display:block;}, .my_wrap{display:none;} */
/* 로그인 상태 : .login_wrap{display:none;}, .my_wrap{display:block;} */
.common_mobile_menu_wrap__2klDq .common_mobile_my_area__x2h0y{
	position:relative;
	top:0;
	width:100%;
	height:auto;
}
.common_mobile_my_area__x2h0y .common_login_wrap__10a5c{
	/* display:block; */
	display:none;
	width:100%;
	height:60px;
	border-top:1px solid #009cc6;
	border-bottom:1px solid #009cc6;
	box-sizing:border-box;
}
.common_mobile_my_area__x2h0y .common_login_wrap__10a5c>div{
	display:flex;
	width:100%;
	height:100%;
}
.common_mobile_my_area__x2h0y .common_login_wrap__10a5c a{
	width:50%;
	height:100%;
	background:#ffffff;
	font-size:20px;
	color:#009cc6;
	text-align:center;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
}	
.common_login_wrap__10a5c a:first-child{
	background:#009cc6;
	color:#ffffff;
}	
.common_mobile_my_area__x2h0y .common_my_wrap__1oYVs{
	/* display:none; */
	display:block;
	width:100%;
	height:auto;
}
.common_mobile_my_area__x2h0y .common_my_wrap__1oYVs .common_top_area__1Ir0S{
	width:100%;
	height:80px;
	background:#009cc6;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
	box-sizing:border-box;
	padding:10px 30px;
}
.common_mobile_my_area__x2h0y .common_my_wrap__1oYVs .common_left_area__2Nwxl{
	width:auto;
	height:44px;
	margin-right:24px;
}
.common_mobile_profile__2sfK5{
	width:auto;
	height:100%;
	box-sizing:border-box;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
}
.common_mobile_profile__2sfK5 img{
	height:100%;
	width:auto;
}
.common_mobile_my_area__x2h0y .common_my_wrap__1oYVs .common_right_area__3060r{
	width:auto;
	max-width:180px;
	height:auto;
}
.common_mobile_my_area__x2h0y .common_my_wrap__1oYVs .common_name__3XKX4{
	width:100%;
	height:auto;
	display:-webkit-box;
	-webkit-box-align:end;
	-webkit-box-pack:start;
	display:flex;
	align-items:flex-end;
	justify-content:flex-start;
}
.common_mobile_my_area__x2h0y .common_my_wrap__1oYVs .common_name__3XKX4 p{
	width:auto;
	max-width:135px;
	height:auto;
	overflow:hidden;
	text-overflow:ellipsis;
	white-space:nowrap;
	word-break:all;
	word-wrap:break-word;
	text-align:left;
	margin-right:5px;
	font-family:'S-CoreDream-6Bold';
	color:#ffffff;
	line-height:1;
	padding-top:2px;
}
.common_mobile_my_area__x2h0y .common_my_wrap__1oYVs .common_name__3XKX4 span{
	font-size:18px;
	color:#4d4d4d;
	line-height:1;
}
.common_mobile_my_area__x2h0y .common_my_wrap__1oYVs .common_bottom_area__16Bx2{
	width:100%;
	height:40px;
	display:flex;
}
.common_mobile_my_area__x2h0y .common_my_wrap__1oYVs .common_bottom_area__16Bx2 a{
	width:50%;
	height:100%;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
	font-size:14px;
	color:#666666;
	background:#eeeeee;
}
.common_mobile_my_area__x2h0y .common_my_wrap__1oYVs .common_bottom_area__16Bx2 a:last-child{
	border-left:1px solid #cccccc;
	color:#bbbbbb;
}
.common_mobile_menu_wrap__2klDq .common_bottom_menu_area__3f9w5{
	position:relative;
	top:0;
	width:100%;
	height:auto;
}
.common_mobile_menu_wrap__2klDq .common_bottom_menu_area__3f9w5 a{
	font-size:15px;
	color:#1a1a1a;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
	width:100%;
	height:50px;
	box-sizing:border-box;
	border-bottom:1px solid #e6e6e6;
}

/*footer*/
.common_footer__18aab{
	width:100%;
	height:270px;
	background:#1a1a1a;
	position:relative;
	bottom:0px;
}
.common_footer__18aab .common_inner90__3qaGh{
	display:flex;
}
.common_f_left__1epgt{
	width:80%;
	height:100%;
}
.common_f_left__1epgt ul{
	width:100%;
	height:100%;
	display:-webkit-box;
	-webkit-box-align:start;
	-webkit-box-pack:center;
	display:flex;
	align-items:flex-start;
	justify-content:center;
	flex-direction:column;
}
.common_f_left__1epgt li{
	font-family: 'S-CoreDream-3Light';
	font-size:14px;
	color:#666666;
	text-align:left;
}
.common_f_left__1epgt li:nth-child(1){
	height:46px;
	width:auto;
	margin-bottom:36px;
}
.common_f_left__1epgt li:nth-child(1) img{
	height:100%;
	width:auto;
}
.common_f_left__1epgt li:nth-child(2){
	display:flex;
	margin-bottom:18px;
}
.common_f_left__1epgt li:nth-child(2) a{
	font-family: 'S-CoreDream-3Light';
	font-size:14px;
	color:#e6e6e6;
}
.common_f_left__1epgt li:nth-child(2) a:nth-child(2){
	margin:0 24px;
}
.common_f_left__1epgt li:nth-child(2) a:nth-child(3){
	color:#009cc6;
}
.common_f_left__1epgt li:nth-child(3){
	margin-bottom:32px;
}
.common_f_right__-R8OE{
	width:20%;
	height:100%;
}
.common_f_right__-R8OE ul{
	width:100%;
	height:100%;
	display:-webkit-box;
	-webkit-box-align:end;
	-webkit-box-pack:center;
	display:flex;
	align-items:flex-end;
	justify-content:center;
	flex-direction:column;
}
.common_f_right__-R8OE li:nth-child(1){
	display:flex;
	margin-bottom:30px;
}
.common_f_right__-R8OE li:nth-child(1) a{
	width:50px;
	height:50px;
}
.common_f_right__-R8OE li:nth-child(1) a img{
	height:100%;
	width:100%;
}
.common_f_right__-R8OE li:nth-child(1) a:nth-child(2){
	margin:0 10px;
}
.common_f_right__-R8OE li.common_faq__nwhXw{
	color:#e6e6e6;
	font-family: 'S-CoreDream-6Bold';
	text-align:right;
	margin-bottom:10px;
}
.common_f_right__-R8OE li.common_faq__nwhXw a{
	color:#ffffff;
	font-family: 'S-CoreDream-6Bold';
	text-align:right;
}
.common_f_right__-R8OE li.common_faq__nwhXw a:hover{
	color:#009cc6;
}
.common_f_right__-R8OE li.common_inquiry__mMQ2a{
	color:#666666;
	font-size:12px;
	font-family: 'S-CoreDream-3Light';
	text-align:right;
}

/*login.html*/
.common_login_title__2TZdC{
	display:block; 
	margin:0 auto; 
	position:relative; 
	font-size:34px; 
	text-align:center;
	color:#009cc6; 
	top:32px;
	font-family: 'S-CoreDream-6Bold';
}
.common_center_align__34t2o{
	display:-webkit-box;
	-webkit-box-align:center;
	display:flex;
	flex-direction:column;
	align-items:center;
}
.common_login_bg_wrapper__3YAS5{
	font-size:18px; 
	margin:0 auto; 
	color:#333333; 
	background:#009cc6; 
	width:100%; 
	height:100%; 
	min-height:650px; 
	position:absolute; 
	top:0; 
	left:0; 
	display:-webkit-box; 
	-webkit-box-align:center; 
	display:flex; 
	align-items:center;
}
.common_login_bg_wrapper__3YAS5.common_password_bg_wrapper__1VZMQ{
	min-height:400px;
}
.common_login_bg_wrapper__3YAS5.common_join_bg_wrapper__imDVy{
	min-height:800px;
}
.common_login_bg_wrapper__3YAS5.common_join_group_bg_wrapper__3dYfb{
	min-height:860px;
}
.common_login_bg_wrapper__3YAS5.common_stuedent_make_bg_wrapper__3rc2K{
	min-height:900px;
}
.common_login_bg_wrapper__3YAS5.common_join_agree_bg_wrapper__1XWjI{
	min-height:1100px; 
	line-height: 1.5;
}
.common_middle__3zpzE{
	position:relative; 
	margin:0 auto;
}

.common_login_top__r5W07{
	width:100%; 
	height:20px; 
	position:relative; 
	top:0;
}
.common_login_top__r5W07 a.common_logo__32lKi{
	display:block; 
	height:100%; 
	position:absolute;
}
.common_login_top__r5W07 a.common_logo__32lKi img{height:100%;}
.common_login_top__r5W07 a.common_to_join__2uN5t{
	font-size:15px; 
	color:#999999; 
	position:absolute; 
	right:0;
}
a.common_ok__14jgJ{
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
	width:auto; 
	height:36px; 
	background:#009cc6; 
	border-radius:30px; 
	color:#ffffff; 
	font-weight:400; 
	text-align:center; 
	padding:5px 30px;
	padding-top:7px;
	box-sizing:border-box; 
	margin-top:20px;
}
a.common_ok__14jgJ:hover{color:#ffffff;}
div.common_input_area__2Lws9{
	width:100%; 
	height:55px; 
	box-sizing:border-box; 
	font-size:20px; 
	/* background:pink;  */
	margin-top:20px;
}
.common_check_wrap__2Ajix{
	height:20px; 
	display:-webkit-box; 
	-webkit-box-align:center;
	display:flex;
}
.common_check__1R9cf{
	width:20px; 
	height:20px; 
	background:red; 
	margin-right:10px;
}
p.common_continue__3vfJ6{
	font-size:16px; 
	font-weight:400; 
	display:block; 
}
/*로그인 login.html*/
.common_login_area__3MH8G{
	width:420px; 
	height:630px; 
	background:#ffffff; 
	border-radius:20px; 
	padding:30px; 
	box-sizing:border-box; 
}
.common_login_area__3MH8G .common_loginpage_wrap__34rnP{
	width:100%; 
	height:auto; 
	margin-top:25px;
}
.common_login_area__3MH8G .common_input_area__2Lws9.common_password__38SZp{top:70px;}
.common_box_under_wrap__1rJ4_{
	width:100%; 
	height:20px; 
	position:relative; 
	top:0px; 
	margin-top:30px;
	display:-webkit-box; 
	-webkit-box-align:center; 
	display:flex;
}
.common_login_area__3MH8G a.common_change__1GtcO{
	display:block; 
	width:96px; 
	font-size:14px; 
	font-weight:400; 
	color:#009cc6;  
	position:absolute; 
	right:0;
}
.common_login_area__3MH8G .common_line__RCi7Y{
	width:100%; 
	height:1px; 
	background:#009cc6; 
	margin-top:30px;
}
.common_login_area__3MH8G span.common_sub_tit__3_GE4{
	text-align:center; 
	color:#444444; 
	display:block; 
	margin:0 auto; 
	margin-top:22px;
}
/* 간편로그인 버튼 */
.common_login_area__3MH8G button{
	height:44px;
	width:300px;
	background:#000000;
	cursor:pointer;
	border-radius:30px;
	border:none;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:start;
	display:flex;
	align-items:center;
	justify-content:flex-start;
	overflow:hidden;
	margin-top:16px;
	position:relative;
}
.common_login_area__3MH8G button img{
	height:100%;
}
.common_login_area__3MH8G button.common_apple__1AV-n::after{
	content:'Apple로 로그인';
	position:absolute;
	width:100%;
	height:100%;
	color:#ffffff;
	font-size:19px;
	font-family:'Roboto', AppleSDGothicNeo-Regular,sans-serif;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:Center;
}
.common_login_area__3MH8G button.common_google__3cloo{
	background:#ffffff;
	box-shadow: 0px 0px 2px 0 rgba(0, 0, 0, .24);
	margin-top:20px;
}
.common_login_area__3MH8G button.common_google__3cloo::after{
	content:'Google로 로그인';
	position:absolute;
	width:100%;
	height:100%;
	color:#3c4043;
	font-size:19px;
	font-family:'Roboto',sans-serif;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:Center;
}
.common_login_area__3MH8G button.common_google__3cloo img{
	height:18px;
	margin-left:14.5px;
}

/*password_reset_area*/
.common_password_reset_area__3lxFX{
	width:500px; 
	height:381px; 
	background:#ffffff; 
	border-radius:20px; 
	padding:30px;  
	box-sizing:border-box; 
}
.common_password_reset_area__3lxFX .common_password_reset_wrap__2oZax{
	width:100%; 
	height:220px; 
	margin-top:30px;
}
.common_password_reset_area__3lxFX .common_password_reset_wrap__2oZax p{
	text-align:center; 
	width:100%;
	font-size:16px;
	margin-top:16px;
}
.common_password_reset_area__3lxFX .common_password_reset_wrap__2oZax .common_password_reset_input_area__3eDF5.common_input_area__2Lws9 {
	width: 100%; 
	height: 40px; 
	box-sizing: border-box; 
	font-size: 20px; 
	margin-top: 10px;
}
.common_password_reset_area__3lxFX .common_password_reset_wrap__2oZax .common_ok__14jgJ {margin-top: 60px;}

/*회원가입 join.html*/
.common_join_area__YlYC1{
	width:420px; 
	height:752px; 
	background:#ffffff; 
	border-radius:20px; 
	padding:30px; 
	box-sizing:border-box;
}
.common_join_area__YlYC1 .common_join_wrap__3sjN1{
	width:100%; 
	height:520px; 
	margin-top:50px;
}
.common_join_area__YlYC1 .common_input_area__2Lws9.common_email__1aJmm{
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:start;
	display:flex;
	align-items:center;
	justify-content:flex-start;
	position:relative;
}
.common_join_area__YlYC1 .common_input_area__2Lws9.common_email__1aJmm.common_overlap__1sBm2::before{
	content:'중복된 이메일입니다';
	display:block;
	color:#F15A24;
	font-size:13px;
	font-family:'S-CoreDream-3Light';
	position:absolute;
	top:104%;
	left:10px;
}
.common_join_area__YlYC1 .common_input_area__2Lws9.common_email__1aJmm.common_overlapno__35iFp::before{
	content:'사용 가능한 이메일입니다';
	display:block;
	color:#009cc6;
	font-size:13px;
	font-family:'S-CoreDream-3Light';
	position:absolute;
	top:104%;
	left:10px;
}
.common_join_area__YlYC1 .common_input_area__2Lws9.common_email__1aJmm .common_input_area__2Lws9{
	margin-top:0;
	max-width:250px;
	margin-right:12px;
}
.common_join_area__YlYC1 .common_email__1aJmm button{
	width:90px;
	height:35px;
	box-sizing:border-box;
	border:1px solid #b3b3b3;
	background:#ffffff;
	border-radius:17px;
	font-size:13px;
	color:#808080;
	cursor:pointer;
}
.common_join_area__YlYC1 .common_input_area__2Lws9.common_password__38SZp{
	margin-top:40px;
}
	.common_join_area__YlYC1 .common_join_wrap__3sjN1 .common_select_area__2EsHG{
	width:100%; 
	height:40px; 
	margin-top:20px;
}
.common_join_area__YlYC1 .common_join_wrap__3sjN1 .common_select_area__2EsHG select{
	width:50%; 
	height:100%;
}
.common_join_area__YlYC1 .common_join_wrap__3sjN1 .common_select_area__2EsHG span{
	margin-left:10px;
}
.common_join_area__YlYC1 .common_join_wrap__3sjN1 a.common_ok__14jgJ{
	margin-top:54px;
}

.common_join_area__YlYC1 .common_join_wrap__3sjN1 .common_tel__1QSRv{
	height:70px; 
	margin-bottom:20px; 
	background:none;
}
.common_join_area__YlYC1 .common_join_wrap__3sjN1 .common_tel__1QSRv p{
	font-size:16px;
}
.common_join_area__YlYC1 .common_join_wrap__3sjN1 .common_tel__1QSRv .common_input_area__2Lws9{
	width:30%; 
	float:left; 
	margin-right:5%;
	position:relative;
	top:-20px;
}
.common_join_area__YlYC1 .common_join_wrap__3sjN1 .common_tel__1QSRv .common_input_area__2Lws9.common_box3__1eYqZ{
	margin-right:0;
}

/*join_group_area*/
.common_join_group_area__TDhWT{
	width:420px; 
	height:825px; 
	background:#ffffff; 
	border-radius:20px; 
	padding:30px; 
	box-sizing:border-box;
}
.common_join_group_area__TDhWT .common_join_group_wrap__3fqH5{
	width:100%;
	height:520px; 
	margin-top:50px;
}
.common_join_group_wrap__3fqH5 .common_input_area__2Lws9>p{
	margin:5px 0;
}
.common_join_group_area__TDhWT .common_join_group_wrap__3fqH5 .common_number__307Tq{
	height:63px;
	background:none;
	margin-top:10px;
}
.common_join_group_area__TDhWT .common_join_group_wrap__3fqH5 .common_number__307Tq p{
	font-size:15px;
}
.common_join_group_area__TDhWT .common_join_group_wrap__3fqH5 .common_number__307Tq .common_input_area__2Lws9{
	width:30%; 
	float:left; 
	margin-right:5%; 
	position:relative; 
	top:-20px;
	margin-top: 10px;
	height: 40px;
}
.common_join_group_area__TDhWT .common_join_group_wrap__3fqH5 .common_number__307Tq .common_input_area__2Lws9.common_box3__1eYqZ{
	margin-right:0;
}
.common_join_group_wrap__3fqH5 .common_input_area__2Lws9.common_fax__1GN8l{
	margin-top:50px;
}
.common_join_group_area__TDhWT .common_join_group_wrap__3fqH5 .common_select_area__2EsHG{
	background:none;
}
.common_join_group_area__TDhWT .common_join_group_wrap__3fqH5 .common_select_area__2EsHG select{
	width:50%; 
	height:100%;
}
.common_join_group_area__TDhWT .common_join_group_wrap__3fqH5 .common_select_area__2EsHG .common_select_name__14ma1{
	margin-left:10px; 
	font-size:14px;
}
.common_post_srch_wrap__3MMxK{
	width:100%;
	height:36px;
	margin-top:20px;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:start;
	display:flex;
	align-items:center;
	justify-content:flex-start;
}
.common_join_group_area__TDhWT .common_post_srch_wrap__3MMxK a.common_post_srch__XYyaM{
	width:100px; 
	height:30px; 
	background:#ffffff; 
	border:1px solid #009cc6; 
	box-sizing:border-box; 
	font-size:14px; 
	color:#009cc6; 
	text-align:center; 
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
}

.common_dimmed_layer_wrapper__o2aKR{
	width:100%;
	height:100%;
	position:fixed;
	top:0;
	left:0;
	background:#00000070;
	overflow:hidden;
	z-index:999;
	display:-webkit-box;
	-webkit-box-align:Center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
}
.common_popClosePosition__AGodm{
	display:none;
    /* background: white; */
    /* width: 40px; */
    /* height: 24px; */
    /* position: relative; */
    /* margin: auto; */
    /* top: 4px; */
    /* padding: 1px; */
    /* text-align: center; */
    /* font-weight: bold; */
    /* border: solid 1px #333; */
    /* border-radius: 15%; */
    /* cursor: pointer; */
}

.common_join_group_area__TDhWT .common_adress_box__2kf6o{
	margin-top:16px; 
	height:36px; 
	font-size:18px;
}
.common_join_group_area__TDhWT a.common_ok__14jgJ{
	margin-top:40px;
}

/*학습자 생성 stuedent_make.html*/
.common_stuedent_make_area__17zR_{
	width:420px; 
	height:auto;
	min-height:860px; 
	background:#ffffff; 
	border-radius:20px; 
	padding:30px; 
	box-sizing:border-box;
}
.common_stuedent_make_area__17zR_ .common_stuedent_make_wrap__24XgP{
	width:100%; 
	height:auto; 
	margin-top:50px;
}
.common_stuedent_make_area__17zR_ .common_input_area__2Lws9.common_nikname__iD7Wu{
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:start;
	display:flex;
	align-items:center;
	justify-content:flex-start;
	position:relative;
}
.common_stuedent_make_area__17zR_ .common_input_area__2Lws9.common_nikname__iD7Wu.common_overlap__1sBm2::before{
	content:'중복된 이메일입니다';
	display:block;
	color:#F15A24;
	font-size:13px;
	font-family:'S-CoreDream-3Light';
	position:absolute;
	top:104%;
	left:10px;
}
.common_stuedent_make_area__17zR_ .common_input_area__2Lws9.common_nikname__iD7Wu.common_overlapno__35iFp::before{
	content:'사용 가능한 이메일입니다';
	display:block;
	color:#009cc6;
	font-size:13px;
	font-family:'S-CoreDream-3Light';
	position:absolute;
	top:104%;
	left:10px;
}
.common_stuedent_make_area__17zR_ .common_input_area__2Lws9.common_nikname__iD7Wu .common_input_area__2Lws9{
	margin-top:0;
	max-width:250px;
	margin-right:12px;
}
.common_stuedent_make_area__17zR_ .common_input_area__2Lws9.common_nikname__iD7Wu button{
	width:90px;
	height:35px;
	box-sizing:border-box;
	border:1px solid #b3b3b3;
	background:#ffffff;
	border-radius:17px;
	font-size:13px;
	color:#808080;
	cursor:pointer;
}

.common_stuedent_make_area__17zR_ .common_input_area__2Lws9.common_password__38SZp{
	margin-top:38px;
}
.common_stuedent_make_area__17zR_ .common_input_area__2Lws9.common_name__3XKX4{
	margin-top:30px;
}
.common_stuedent_make_area__17zR_ .common_input_area__2Lws9.common_over_check_next__1TTcH{
	margin-top:50px;
}
.common_stuedent_make_area__17zR_ .common_stuedent_make_wrap__24XgP a.common_ok__14jgJ{
	margin-top:56px;
}


/*join_agree*/
.common_join_agree_area__3wfyj{
	width:1000px; 
	height:auto; 
	min-height:1000px; 
	background:#ffffff; 
	border-radius:20px; 
	padding:30px; 
	box-sizing:border-box;
}
.common_join_agree_area__3wfyj a.common_agree_logo__cZRcY img{
	width:120px; 
	display:block; 
	margin:0 auto; 
	margin-top:30px;
}
.common_join_agree_area__3wfyj .common_join_agree_wrap__2Nr7l{
	width:100%; 
	height:auto; 
	margin-top:50px;
}
.common_join_agree_area__3wfyj .common_join_agree_wrap__2Nr7l .common_sub_tit__3_GE4{
	font-size:20px; 
	font-weight:700; 
	text-align:left;
}
/*agree_use_wrap*/
.common_join_agree_area__3wfyj .common_join_agree_wrap__2Nr7l .common_agree_use_wrap__39peX{
	width:100%; 
	height:270px; 
	overflow-y:scroll; 
	border:1px solid #333333; 
	box-sizing:border-box;
	margin-top:20px;
}
.common_join_agree_area__3wfyj .common_join_agree_wrap__2Nr7l .common_agree_use_wrap__39peX>div{
	width:100%; 
	height:auto;
}
.common_join_agree_area__3wfyj .common_agree_use_wrap__39peX span.common_tit__PVpaj{
	color:#333333; 
	text-align:left; 
	top:0; 
	padding-left:10px; 
	font-size:28px;
}
.common_join_agree_area__3wfyj .common_agree_use_wrap__39peX .common_wrap__ornby{
	width:98%; 
	height:auto; 
	margin:0 auto;
}
.common_join_agree_area__3wfyj .common_agree_use_wrap__39peX .common_wrap__ornby .common_privacy_area__37ys7.common_area1__1KZ6K{
	margin-top:0;
}
.common_join_agree_area__3wfyj .common_agree_use_wrap__39peX .common_wrap__ornby .common_privacy_area__37ys7.common_area7__3KWlq{
	margin-bottom:0;
}
.common_join_agree_area__3wfyj .common_agree_use_wrap__39peX .common_wrap__ornby .common_privacy_area__37ys7{
	width:100%; 
	height:inherit;
	position:relative; 
	top:0; 
	margin:60px 0;
}
.common_join_agree_area__3wfyj .common_agree_use_wrap__39peX .common_wrap__ornby .common_privacy_area__37ys7 span.common_tit__PVpaj{
	font-size:24px; 
	padding-left:0;
}
.common_join_agree_area__3wfyj .common_agree_use_wrap__39peX .common_wrap__ornby .common_privacy_area__37ys7 span.common_sub_tit__3_GE4{
	font-weight:500; 
	text-align:left;
}
.common_join_agree_area__3wfyj .common_agree_use_wrap__39peX .common_wrap__ornby p{
	font-size:18px; 
	text-align:left;
	padding:10px 20px; 
	white-space:normal; 
	word-break:keep-all; 
	word-wrap:break-word;
}
/*agree_privacy_wrap*/
.common_agree_privacy_wrap__rTGa2 .common_wrap__ornby .common_privacy_area__37ys7.common_area12__19GbS{
	margin-bottom:0;
}
.common_agree_privacy_wrap__rTGa2 .common_wrap__ornby .common_privacy_area__37ys7 span.common_tit__PVpaj{
	font-size:24px; 
	padding-left:0; 
	margin-bottom:20px;
}
.common_agree_privacy_wrap__rTGa2 .common_wrap__ornby .common_privacy_info__2p3oY{
	width:100%; 
	height:auto; 
	margin-bottom:40px; 
	margin-top:20px;
}
.common_agree_privacy_wrap__rTGa2 .common_wrap__ornby .common_privacy_info__2p3oY p{
	padding:0; 
	padding-left:5px;
}

.common_agree_privacy_wrap__rTGa2 .common_wrap__ornby .common_privacy_area__37ys7.common_area1__1KZ6K .common_box_wrap__1S8pe{
	width:100%; 
	height:auto;
	padding:20px; 
	box-sizing:border-box; 
	position:relative; 
	top:0px;
}
.common_agree_privacy_wrap__rTGa2 .common_box_wrap__1S8pe .common_title__1txLf{
	width:100%; 
	height:70px;
	background:#009cc6;
	position:relative;
	top:0;
	left:0;
	display:flex;
}
.common_agree_privacy_wrap__rTGa2 .common_box_wrap__1S8pe .common_title__1txLf span{
	color:#ffffff;
	box-sizing:border-box;
	text-align:center;
	height:100%;
	width:76%;
	display:-webkit-box;
	-webkit-box-align:Center;
	-webkit-box-pack:Center;
	display:flex;
	align-items:center;
	justify-content:Center;
}
.common_agree_privacy_wrap__rTGa2 .common_box_wrap__1S8pe .common_title__1txLf span:first-child{
	border-right:1px solid #ffffff;
	width:24%;
}
.common_agree_privacy_wrap__rTGa2 .common_box_wrap__1S8pe .common_list__1inqC{
	width:100%; 
	height:auto;
	background:#ffffff;
	border-bottom:1px solid #eaeaea;
	box-sizing:border-box;
	position:relative;
	top:0;
	display:flex;
}
.common_agree_privacy_wrap__rTGa2 .common_box_wrap__1S8pe .common_list__1inqC span{
	box-sizing:border-box; 
	width:76%;
	height:auto;
	padding:30px;
	display:-webkit-box;
	-webkit-box-align:Center;
	-webkit-box-pack:start;
	display:flex;
	align-items:center;
	justify-content:flex-start;
}
.common_agree_privacy_wrap__rTGa2 .common_box_wrap__1S8pe .common_list__1inqC span.common_name__3XKX4{
	width:24%;
	height:inherit;
	border-right:1px solid #eaeaea;
	text-align:center; 
	background:#fbfbfb;
	justify-content:center;
	padding:0;
}
/*agree_check_wrap*/
.common_join_agree_area__3wfyj .common_check_wrap__2Ajix{
	height:36px; 
	align-items:center; 
	margin-top:10px; 
	margin-bottom:20px;
}
.common_join_agree_area__3wfyj .common_check_wrap__2Ajix input{
	width:16px; 
	height:16px; 
	margin-right:10px;
}
/*agree_btn*/
.common_join_agree_area__3wfyj .common_agree_btn__2VU4d{
	width:220px; 
	height:36px; 
	margin:0 auto;
	margin-top:30px; 
	display:flex;
}
.common_join_agree_area__3wfyj .common_agree_btn__2VU4d a{
	width:100px; 
	height:36px; 
	background:#ffffff;
	border:1px solid #009cc6;
	box-sizing:border-box; 
	color:#009cc6; 
	text-align:center; 
	border-radius:30px;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
}
.common_join_agree_area__3wfyj .common_agree_btn__2VU4d a.common_agree_continue__39jsN{
	background:#009cc6; 
	color:#ffffff; 
	margin-left:20px;
}

/*index.html*/
.common_stakato__e3n3a{
	font-size:inherit;
	font-family:inherit;
	text-align:inherit;
	letter-spacing:inherit;
	color:#f9be2d;
	position:relative;
	display:-webkit-box;
	-webkit-box-align:center;
	display:flex;
	flex-direction:column;
	align-items:center;
}
.common_stakato__e3n3a::before{
	content:'';
	display:block;
	width:.8vh;
	height:.8vh;
	position:absolute;
	top:-1.5vh;
	border-radius:50%;
	background:#f9be2d;
}
.common_stakato__e3n3a:first-child{
	margin-left:1vw;
}
.common_white__1Rrwi{
	color:#ffffff;
	font-size:inherit;
	font-family:inherit;
	text-align:inherit;
	letter-spacing:inherit;
}
.common_index_tit__3e1q3{
	font-size:1.45em;
	text-align:center;
	color:#3b3b3b;
	font-family: 'S-CoreDream-8Heavy';
	display:flex;
}
.common_sub_title__hQAyC{
	display:flex;
	font-size:0.9em;
	font-family: 'S-CoreDream-6Bold';
	text-align:center;
	color:#3b3b3b;
}
p.common_txt__3s3ZA{
	font-size:.85em; 
	text-align:center;
}
/*main_banner_area */
.common_main_banner_area__32GkR{
	padding-top:0;
}
.common_main_banner_area__32GkR .common_bn_wrap__N8o-s{
	width:auto;
	height:100%; 
	position:relative; 
	top:0; 
	left:0;
	display:flex;
}
.common_bn__1J_uv{
	width:100%; 
	height:100%;
	position:absolute; 
	top:0; 
	left:0;
	background:#f9be2d;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:Center;
	justify-content:Center;
}

.common_bn_content_wrap__2v3GE{
	width:100%;
	height:100%;
	position:absolute;
	top:0;
	left:0;
	z-index:20;
	box-sizing:border-box;
	padding-top:17.2vh;
	padding-bottom:9.75vh;
}
.common_bn_content_wrap__2v3GE .common_inner90__3qaGh{
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
} 
.common_bn_content_wrap__2v3GE .common_inner90__3qaGh{
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:end;
	display:flex;
	align-items:center;
	justify-content:flex-end;
	flex-direction:column;
}
.common_bn_content_wrap__2v3GE .common_top_area__1Ir0S{
	display:-webkit-box;
	-webkit-box-align:center;
	display:flex;
	align-items:center;
	flex-direction:column;
}
.common_bn_content_wrap__2v3GE .common_bn_tit__3dw1P{
	font-size:2.4em;
	font-family: 'S-CoreDream-9Black';
	text-align:center;
	color:#2e3b65;
	display:flex;
	line-height:1;
}
.common_bn_content_wrap__2v3GE .common_bn_tit__3dw1P .common_stakato__e3n3a{
	color:#ffffff;
	line-height:1;
	margin:0 .1rem;
}
.common_bn_content_wrap__2v3GE .common_bn_tit__3dw1P .common_stakato__e3n3a:first-child{
	margin-left:1vw;
}
.common_bn_content_wrap__2v3GE .common_bn_tit__3dw1P .common_stakato__e3n3a::before{
	width:2vmin;
	height:2vmin;
	top:-3vh;
	background:#ffffff;
}
.common_bn_content_wrap__2v3GE .common_bn_txt__3TdUu{
	font-size:1.01em;
	text-align:center;
	color:#444444;
	margin-top:4.45vh;
	font-family: 'S-CoreDream-7ExtraBold';
	letter-spacing:.07rem;
}
.common_bn_content_wrap__2v3GE .common_bn_txt__3TdUu .common_white__1Rrwi{
	margin-right:.1rem;
}
.common_bn_content_wrap__2v3GE img{
	height:44vmin;
	width:auto;
	margin-top:6vh;
} 

.common_bn_btn_wrap__cunNl{
	width:100%; 
	height:20px; 
	position:absolute; 
	top:93%;
	z-index:30;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
	visibility:hidden;
}
.common_bn_btn__1srRO{
	width:14px; 
	height:14px; 
	background:#ffffff40; 
	border-radius:50%; 
	margin:0 15px; 
	cursor:pointer;
}
.common_bn_btn__1srRO.common_on__2QUU7{
	background:#ffffff; 
}
/*introduce_area*/
.common_introduce_area__2LRd7{
	background:rgba(249,190,45,.1);
	padding-bottom:10.2vh;
}
.common_introduce_area__2LRd7 .common_stakato__e3n3a::before{
	width:.6vh;
	height:.6vh;
	top:-.7vh;
}
.common_introduce_area__2LRd7 .common_inner90__3qaGh{
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:end;
	display:flex;
	flex-direction:column;
	align-items:center;
	justify-content:flex-end;
}
.common_introduce_area__2LRd7 .common_top_area__1Ir0S{
	width:100%;
	height:40.5vmin;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	justify-content:center;
	align-items:center;
	position:relative;
}
.common_introduce_area__2LRd7 .common_top_area__1Ir0S img{
	width:auto;
	height:100%;
	margin-right:4vw;
}
.common_introduce_area__2LRd7 .common_top_area__1Ir0S .common_right_area__3060r{
	height:auto;
}
.common_introduce_area__2LRd7 .common_right_area__3060r p{
	font-size:.85em;
	text-align:left;
	margin-top:2.5vh;
}
.common_introduce_area__2LRd7 .common_right_area__3060r p br{
	display:block;
}
.common_introduce_area__2LRd7 span.common_index_tit__3e1q3{
	color:#f9be2d;
	text-align:left;
	font-family: 'S-CoreDream-6Bold';
}
.common_introduce_area__2LRd7 .common_sub_title__hQAyC{
	color:#3c3c3c;
}
.common_introduce_area__2LRd7 .common_sub_title__hQAyC .common_stakato__e3n3a:nth-child(3){
	margin-left:.5vw;
}
.common_introduce_area__2LRd7 .common_ym_who_popup__3du5G{
	display:none;
}
.common_introduce_area__2LRd7 .common_ym_who_popup__3du5G.common_on__2QUU7{
	display:block;
	position:absolute;
	width:90%;
	height:44vh;
	background:#2e3b65;
	z-index:11;
	border-radius:88px;
	box-sizing:border-box;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
}
.common_introduce_area__2LRd7 .common_ym_who_popup__3du5G.common_on__2QUU7 .common_ym_wyo_box__3T_QO{
	width:23vw;
	height:auto;
	display:-webkit-box;
	-webkit-box-align:center;
	display:flex;
	flex-direction:column;
	align-items:center;
}
.common_ym_who_popup__3du5G.common_on__2QUU7 .common_ym_wyo_box__3T_QO:first-child{
	width:18vw;
}
.common_ym_who_popup__3du5G.common_on__2QUU7 .common_ym_wyo_box__3T_QO:nth-child(2){
	margin:0 3vw;
	width:22vw;
}
.common_ym_who_popup__3du5G.common_on__2QUU7 .common_ym_wyo_box__3T_QO span{
	text-align:center;
	color:#f4c222;
	font-size:1em;
	font-family: 'S-CoreDream-6Bold';
}
.common_ym_who_popup__3du5G.common_on__2QUU7 .common_ym_wyo_box__3T_QO p{
	text-align:center;
	color:#f5f5f5;
	margin-top:3vh;
	font-size:.9em;
}
.common_introduce_area__2LRd7 .common_ym_who_btn_area__vqeJ4{
	width:auto;
	height:auto;
}
.common_introduce_area__2LRd7 .common_ym_who_btn__1oA6w{
	width:27vmax;
	height:6vh;
	border-radius:100px;
	background:#f4c222;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
	position:relative;
	cursor:pointer;
	overflow:hidden;
	margin-top:9vh;
	-webkit-transform:translate3d(0,0,0);
	-webkit-backface-visibility:hidden;
	transform:translate3d(0,0,0);
	backface-visibility:hidden;
	isolation:isolate;
}
.common_introduce_area__2LRd7 .common_ym_who_btn__1oA6w span{
	font-size:.85em;
	color:#ffffff;
	font-family: 'S-CoreDream-6Bold';
	text-align:center;
	position:relative;
	z-index:2;
}
.common_introduce_area__2LRd7 .common_ym_who_btn__1oA6w .common_hover_round__2n9ev{
	border-radius:50%;
	background:#ffffff;
	position:relative;
	margin-left:15px;
	width:24px;
	height:24px;
	transition:all .6s;
}
.common_introduce_area__2LRd7 .common_ym_who_btn__1oA6w.common_on__2QUU7{
	background:#ffffff;
}
.common_introduce_area__2LRd7 .common_ym_who_btn__1oA6w.common_on__2QUU7 span{
	color:#f29500;
}
.common_introduce_area__2LRd7 .common_ym_who_btn__1oA6w.common_on__2QUU7{
	background:#ffffff;
}
.common_introduce_area__2LRd7 .common_ym_who_btn__1oA6w.common_on__2QUU7 .common_hover_round__2n9ev{
	transform:scale(50);
}

/*ym_world_area*/
.common_ym_world_area__3ao46{
	background:#f9be2d;
	padding-top:19.5vh;
	padding-bottom:4.5vh;
}
.common_ym_world_area__3ao46 .common_inner90__3qaGh{
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	flex-direction:column;
	align-items:center;
	justify-content:center;
}
.common_ym_world_area__3ao46 .common_index_tit__3e1q3{
	font-family: 'S-CoreDream-7ExtraBold';
	letter-spacing:-.07rem;
}
.common_ym_world_area__3ao46 .common_index_tit__3e1q3 .common_br__2s69l{
	font-family:inherit;
	font-size:inherit;
	text-align:inherit;
	letter-spacing:inherit;
	color:inherit;
	display:flex;
	margin-right:5px;
}
.common_ym_world_area__3ao46 .common_stakato__e3n3a{
	color:#fff7e8;
}
.common_ym_world_area__3ao46 .common_stakato__e3n3a:first-child{
	margin-left:.5vw;
}
.common_ym_world_area__3ao46 .common_stakato__e3n3a:last-child{
	margin-right:.1vw;
}
.common_ym_world_area__3ao46 .common_stakato__e3n3a::before{
	background:#fff7e8;
	top:-.5vh;
}
.common_ym_world_area__3ao46 .common_top_area__1Ir0S{
	display:-webkit-box;
	-webkit-box-align:center;
	display:flex;
	flex-direction:column;
	align-items:center;
	position:relative;
}
.common_ym_world_area__3ao46 p.common_txt__3s3ZA{
	margin-top:1vh;
}
.common_ym_world_area__3ao46 p.common_txt__3s3ZA br{
	display:block;
}
.common_ym_world_area__3ao46 .common_bottom_area__16Bx2{
	width:auto;
	height:55vh;
	margin-top:3vh;
	display:flex;
}
.common_ym_world_area__3ao46 .common_left_area__2Nwxl{
	height:55vmin;
	width:auto;
	position:relative;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
	margin-left:-5vw;
}
.common_ym_world_area__3ao46 .common_left_area__2Nwxl .common_loby__2dEHE{
	height:70vh;
	width:auto;
	margin-top:-2vh;
}
.common_ym_world_area__3ao46 .common_left_area__2Nwxl .common_plus_wrap__3LtaP{
	height:100%;
	width:100%;
	position:absolute;
	top:0;
	left:0;
	z-index:2;
}
.common_ym_world_area__3ao46 .common_plus_wrap__3LtaP .common_plus__2zQZU{
	width:3.4vh;
	height:3.4vh;
	position:relative;
	cursor:pointer;
	transform:scale(1);
	transition:all .6s;
}
.common_ym_world_area__3ao46 .common_plus_wrap__3LtaP .common_plus__2zQZU:hover{
	transform:scale(1.1);
}
.common_ym_world_area__3ao46 .common_plus_wrap__3LtaP .common_plus__2zQZU.common_on__2QUU7{
	transform:scale(1.1);
}
.common_ym_world_area__3ao46 .common_plus__2zQZU img{
	width:auto;
	height:100%;
	position:absolute;
	cursor:pointer;
	display:block;
}
.common_ym_world_area__3ao46 .common_plus__2zQZU img:first-child{
	display:none;
}
.common_ym_world_area__3ao46 .common_plus__2zQZU.common_on__2QUU7 img:first-child{
	display:block;
}
.common_ym_world_area__3ao46 .common_plus__2zQZU.common_on__2QUU7 img:last-child{
	display:none;
}
.common_ym_world_area__3ao46 .common_plus__2zQZU:nth-child(1){
	top:12%;
	left:24%;
}
.common_ym_world_area__3ao46 .common_plus__2zQZU:nth-child(2){
	top:-5%;
	left:34%;
}
.common_ym_world_area__3ao46 .common_plus__2zQZU:nth-child(3){
	top:-7%;
	left:57%;
}
.common_ym_world_area__3ao46 .common_plus__2zQZU:nth-child(4){
	top:-4%;
	left:67%;
}
.common_ym_world_area__3ao46 .common_plus__2zQZU:nth-child(5){
	top:13%;
	left:39%;
}
.common_ym_world_area__3ao46 .common_plus__2zQZU:nth-child(6){
	top:-3%;
	left:30%;
}
.common_ym_world_area__3ao46 .common_right_area__3060r{
	height:100%;
	width:auto;
	position:relative;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
	margin-left:3vw;
}
.common_ym_world_area__3ao46 .common_building_wrap__3bCH9{
	width:36vh;
	height:36vh;
	border-radius:30%;
	box-sizing:border-box;
	border:4px dashed #ffffff;
	padding:2.8vh;
	background:rgba(255,255,255,.15);
	position:relative;
	box-shadow: 8px 8px 10px 0 rgba(107, 82, 18, .35);
}
.common_ym_world_area__3ao46 .common_building__1tZuc{
	width:100%;
	height:100%;
	display:-webkit-box;
	-webkit-box-align:center;
	display:flex;
	flex-direction:column;
	align-items:center;
	position:absolute;
	top:0;
	left:0;
	visibility:hidden;
	padding:2.8vh;
	box-sizing:border-box;
	transition:none;
}
.common_ym_world_area__3ao46 .common_building__1tZuc.common_on__2QUU7{
	visibility:visible;
}
.common_ym_world_area__3ao46 .common_building__1tZuc span{
	width:auto;
	height:auto;
	border-radius:20px;
	background:#ffffff;
	box-sizing:border-box;
	padding:0 1vw;
	font-family: 'S-CoreDream-7ExtraBold';
	text-align:center;
	display:-webkit-box;
	-webkit-box-align:center;
	display:flex;
	align-items:center;
	font-size:.8em;
	transition:none;
}
.common_ym_world_area__3ao46 .common_building__1tZuc img{
	width:auto;
	height:20vh;
	margin-top:1.5vh;
	margin-bottom:.5vh;
	transition:none;
}
.common_ym_world_area__3ao46 .common_building__1tZuc p{
	text-align:center;
	color:#2e3b65;
	font-size:0.68em;
	transition:none;
}
.common_ym_world_area__3ao46 .common_building__1tZuc p br{
	display:block;
}

/*ym_difference_area*/
.common_ym_difference_area__GVl5X{
	background:#37b3e9;
	padding-top:21.1vh;
	padding-bottom:8.9vh;
}
.common_ym_difference_area__GVl5X .common_inner90__3qaGh{
	display:-webkit-box;
	-webkit-box-align:center;
	display:flex;
	flex-direction:column;
	align-items:center;
}
.common_ym_difference_area__GVl5X .common_index_tit__3e1q3{
	font-family:'S-CoreDream-7ExtraBold';
}
.common_ym_difference_area__GVl5X .common_tabbtn_area__Ixj-V{
	height:auto;
	width:auto;
	display:-webkit-box;
	-webkit-box-align:center;
	display:flex;
	align-items:center;
	margin:3vh 0;
}
.common_ym_difference_area__GVl5X .common_tabbtn_area__Ixj-V .common_btn__3q49s{
	display:-webkit-box;
	-webkit-box-align:center;
	display:flex;
	align-items:center;
	color:#e6e6e6;
	font-family: 'S-CoreDream-3Light';
	cursor:pointer;
}
.common_ym_difference_area__GVl5X .common_tabbtn_area__Ixj-V .common_btn__3q49s:first-child{
	margin-right:2.5vw;
}
.common_ym_difference_area__GVl5X .common_tabbtn_area__Ixj-V .common_btn__3q49s::before{
	content:'';
	display:block;
	width:3.5vh;
	height:3.5vh;
	border-radius:50%;
	background:#e6e6e6;
	margin-right:.3vw;
	box-sizing:border-box;
	transition:all .6s;
}
.common_ym_difference_area__GVl5X .common_tabbtn_area__Ixj-V .common_btn__3q49s:hover::before{
	background:#f4c222;
}
.common_ym_difference_area__GVl5X .common_tabbtn_area__Ixj-V .common_btn__3q49s.common_on__2QUU7::before{
	background:#f4c222;
}
.common_ym_difference_area__GVl5X .common_tabbtn_area__Ixj-V .common_btn__3q49s:hover{
	color:#ffffff;
	font-family: 'S-CoreDream-6Bold';
}
.common_ym_difference_area__GVl5X .common_tabbtn_area__Ixj-V .common_btn__3q49s.common_on__2QUU7{
	color:#ffffff;
	font-family: 'S-CoreDream-6Bold';
}
.common_ym_difference_area__GVl5X .common_middle_area__2Omh4{
	width:100%;
	height:18vh;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
}
.common_ym_difference_area__GVl5X .common_info_txt_wrap__1f4Eh{
	width:auto;
	height:auto;
	box-sizing:border-box;
	padding:2.2vh 2.8vw;
	border-radius:30px;
	border:2px dashed #ffffff;
	background:rgba(255,255,255,.3);
	transition:all .6s;
}
.common_ym_difference_area__GVl5X .common_info_txt_wrap__1f4Eh p{
	display:none;
	width:100%;
	color:#ffffff;
	text-align:center;
	font-size:0.75em;
}
.common_ym_difference_area__GVl5X .common_info_txt_wrap__1f4Eh p.common_on__2QUU7{
	display:block;
}
.common_ym_difference_area__GVl5X .common_img_area__3KniS{
	width:90%;
	height:29vmin;
	display:-webkit-box;
	-webkit-box-align:center;
	display:flex;
	align-items:center;
	position:relative;
	margin-top:5vh;
}
.common_ym_difference_area__GVl5X .common_img_area__3KniS .common_left_img__22jNy{
	width:50%;
	height:100%;
	box-sizing:border-box;
	border-right:2px dashed #ffffff40;
	padding-right:1vw;
	display:-webkit-box;
	-webkit-box-pack:end;
	display:flex;
	justify-content:flex-end;
}
.common_ym_difference_area__GVl5X .common_img_area__3KniS .common_right_img__1ygcb{
	width:50%;
	height:100%;
	box-sizing:border-box;
	padding-left:1vw;
	display:-webkit-box;
	-webkit-box-pack:start;
	display:flex;
	justify-content:flex-start;
}
.common_ym_difference_area__GVl5X .common_img_area__3KniS .common_right_img__1ygcb img{
	display:none;
	height:100%;
	width:auto;
}
.common_ym_difference_area__GVl5X .common_img_area__3KniS .common_left_img__22jNy img{
	display:none;
	height:100%;
	width:auto;
}
.common_ym_difference_area__GVl5X .common_img_area__3KniS .common_left_img__22jNy img.common_on__2QUU7{
	display:block;
}
.common_ym_difference_area__GVl5X .common_img_area__3KniS .common_right_img__1ygcb img.common_on__2QUU7{
	display:block;
}

/*ym_myroom_area*/
.common_ym_myroom_area__1iNyx{
	background:#fff9f0;
	padding-top:20.4vh;
	padding-bottom:12.2vh;
}
.common_ym_myroom_area__1iNyx .common_inner90__3qaGh{
	display:-webkit-box;
	-webkit-box-align:center;
	display:flex;
	flex-direction:column;
	align-items:center;
}
.common_ym_myroom_area__1iNyx .common_index_tit__3e1q3 .common_br__2s69l{
	font-family:inherit;
	font-size:inherit;
	text-align:inherit;
	letter-spacing:inherit;
	color:inherit;
	display:flex;
	margin-right:5px;
}
.common_ym_myroom_area__1iNyx .common_stakato__e3n3a:first-child{
	margin-left:.5vw;
}
.common_ym_myroom_area__1iNyx .common_stakato__e3n3a:last-child{
	margin-right:.1vw;
}
.common_ym_myroom_area__1iNyx .common_stakato__e3n3a::before{
	top:-.5vh;
}
.common_ym_myroom_area__1iNyx p.common_txt__3s3ZA{
	margin-top:1.3vh;
}
.common_ym_myroom_area__1iNyx p.common_txt__3s3ZA br{
	display:block;
}
.common_ym_myroom_area__1iNyx .common_bottom_area__16Bx2{
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
	width:100%;
	height:45vh;
	position:relative;
	margin-top:9vh;
}
.common_ym_myroom_area__1iNyx .common_box__2MqeC{
	width:44.7vh;
	border:3px solid #ffffff;
	box-shadow: 8px 8px 10px 0 rgba(0, 0, 0, .05);
	border-radius:26%;
	box-sizing:border-box;
	position:relative;
	overflow:hidden;
	cursor:pointer;
	transform:scale(.8);
	transition:all .6s;
	top:-2vh;
}
.common_ym_myroom_area__1iNyx .common_box__2MqeC::after{
	content:'';
	display:block;
	padding-bottom:100%;
}
.common_ym_myroom_area__1iNyx .common_box__2MqeC .common_cover__1DbYf{
	visibility:visible;
	background:#fff7eb;
	width:100%;
	height:100%;
	position:absolute;
	top:0;
	left:0;
	z-index:25;
	text-align:center;
	font-size:.85em;
	font-family: 'S-CoreDream-6Bold';
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
	transition:none;
}
.common_ym_myroom_area__1iNyx .common_box__2MqeC.common_on__2QUU7{
	transform:scale(1);
	top:0;
	border:3px solid #ffffff;
	box-shadow: 8px 8px 10px 0 rgba(0, 0, 0, .35);
}
.common_ym_myroom_area__1iNyx .common_box__2MqeC:nth-child(2){
	margin:0 .2vw;
}
.common_ym_myroom_area__1iNyx .common_box__2MqeC:hover{
	transform:scale(.84);
}
.common_ym_myroom_area__1iNyx .common_box__2MqeC.common_on__2QUU7:hover{
	transform:scale(1);
}
.common_ym_myroom_area__1iNyx .common_box__2MqeC.common_on__2QUU7 .common_cover__1DbYf{
	visibility:hidden;
}
.common_ym_myroom_area__1iNyx .common_box__2MqeC .common_content__2kYUC{
	background:#f9be2d;
	width:100%;
	height:100%;
	position:absolute;
	top:0;
	left:0;
	padding:3.8vh;
	box-sizing:border-box;
	display:-webkit-box;
	-webkit-box-align:center;
	display:flex;
	flex-direction:column;
	align-items:center;
}
.common_ym_myroom_area__1iNyx .common_content__2kYUC span{
	font-family: 'S-CoreDream-6Bold';
}
.common_ym_myroom_area__1iNyx .common_content__2kYUC p{
	font-size:.75em;
	text-align:center;
	margin-top:1vh;
}
.common_ym_myroom_area__1iNyx .common_content__2kYUC .common_bottom_area__16Bx2{
	height:70%;
	width:100%;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
	flex-direction:column;
	position:relative;
	margin-top:10%;
}
.common_ym_myroom_area__1iNyx .common_box__2MqeC:first-child .common_content__2kYUC .common_bottom_area__16Bx2{
	margin-top:1vh;
}
.common_ym_myroom_area__1iNyx .common_content__2kYUC .common_graph_area3__22g6W img{
	height:auto;
	width:90%;
}
.common_ym_myroom_area__1iNyx .common_content__2kYUC .common_graph_area3__22g6W .common_count_wrap__1OTx0{
	width:24%;
	height:6vh;
	position:absolute;
	z-index:3;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
}
.common_ym_myroom_area__1iNyx .common_content__2kYUC .common_graph_area3__22g6W .common_counting__1dwuc{
	color:#541da7;
	font-size:1.3em;
	font-family: 'S-CoreDream-9Black';
	position:relative;
}
.common_ym_myroom_area__1iNyx .common_content__2kYUC .common_graph_area3__22g6W .common_count1__UguBq{
	top:58%;
	left:5%;
}
.common_ym_myroom_area__1iNyx .common_content__2kYUC .common_graph_area3__22g6W .common_count2__3ZZTt{
	top:58%;
	left:38%;
}
.common_ym_myroom_area__1iNyx .common_content__2kYUC .common_graph_area3__22g6W .common_count3__3sNcj{
	top:58%;
	left:71%;
}
.common_ym_myroom_area__1iNyx .common_content__2kYUC .common_graph_area1__2BgSb{
	height:40%;
	width:100%;
	display:-webkit-box;
	-webkit-box-align:center;
	display:flex;
	flex-direction:column;
	align-items:center;
	position:relative;
	margin-bottom:3%;
}
.common_ym_myroom_area__1iNyx .common_graph_area1__2BgSb img{
	height:70%;
	width:auto;
}
.common_ym_myroom_area__1iNyx .common_graph_area1__2BgSb .common_wrap__ornby{
	width:80%;
	height:24%;
	position:relative;
	top:-32%;
	border-radius:60px;
	z-index:3;
	overflow:hidden;
	/* isolation:isolate; */
	/* -webkit-transform:translate3d(0,0,0); */
	/* -webkit-backface-visibility:hidden; */
}
.common_ym_myroom_area__1iNyx .common_graph_area1__2BgSb .common_wrap__ornby .common_bar__1ZPwz{
	width:0%;
	min-width:0;
	height:100%;
	background:#00d10c;
	position:relative;
	z-index:3;
	transition:width 1.6s;
}
.common_ym_myroom_area__1iNyx .common_graph_area1__2BgSb .common_wrap__ornby .common_bar__1ZPwz.common_on__2QUU7{
	width:100%;
}
.common_ym_myroom_area__1iNyx .common_content__2kYUC .common_graph_area1_graph__gMu1n{
	height:auto;
	width:90%;
	position:relative;
}
.common_ym_myroom_area__1iNyx .common_content__2kYUC .common_graph_area2__2fmaA{
	height:70%;
	width:100%;
	overflow:hidden;
	display:-webkit-box;
	-webkit-box-align:end;
	-webkit-box-pack:center;
	display:flex;
	align-items:flex-end;
	justify-content:center;
}
.common_ym_myroom_area__1iNyx .common_graph_area2__2fmaA .common_graph__11ynH{
	width:24%;
	height:100%;
	overflow:hidden;
	position:relative;
	display:-webkit-box;
	-webkit-box-align:center;
	display:flex;
	flex-direction:column;
	align-items:center;
}
.common_ym_myroom_area__1iNyx .common_graph_area2__2fmaA .common_graph__11ynH:nth-child(2){
	margin:0 1%;
}
.common_ym_myroom_area__1iNyx .common_graph__11ynH img{
	height:20%;
	width:auto;
	position:absolute;
	bottom:0;
	opacity:0;
	--bottomA:2.5%;
	--animationDelay:.1s;
}
.common_ym_myroom_area__1iNyx .common_graph__11ynH img.common_on__2QUU7{
	animation:common_graphFadeIn__2_GV2 .05s ease-in-out both;
}
@keyframes common_graphFadeIn__2_GV2{
	0%{
		opacity:0;
	}
	100%{
		opacity:1;
	}
}
.common_ym_myroom_area__1iNyx .common_graph__11ynH img.common_head__3zcNA{
	height:49%;
	bottom:39%;
	z-index:20;
	opacity:1;
}
.common_ym_myroom_area__1iNyx .common_graph__11ynH:nth-child(2) img.common_head__3zcNA{
	bottom:24%;
}
.common_ym_myroom_area__1iNyx .common_graph__11ynH:nth-child(3) img.common_head__3zcNA{
	bottom:34%;
}
.common_ym_myroom_area__1iNyx .common_graph__11ynH:nth-child(1) .common_head__3zcNA.common_on__2QUU7{
	animation:common_graphHead1__1b5PE 1.6s ease-in-out both;
	animation-delay:calc(var(--animationDelay) * 1);
}
.common_ym_myroom_area__1iNyx .common_graph__11ynH:nth-child(2) .common_head__3zcNA.common_on__2QUU7{
	animation:common_graphHead2__3cljS 1s ease-in-out both;
	animation-delay:calc(var(--animationDelay) * 1);
}
.common_ym_myroom_area__1iNyx .common_graph__11ynH:nth-child(3) .common_head__3zcNA.common_on__2QUU7{
	animation:common_graphHead3__1UY5s 1.3s ease-in-out both;
	animation-delay:calc(var(--animationDelay) * 1);
}
@keyframes common_graphHead1__1b5PE{
	0%{
		bottom:2.5%;
	}
	100%{
		bottom:39%;
	}
}
@keyframes common_graphHead2__3cljS{
	0%{
		bottom:2.5%;
	}
	100%{
		bottom:24%;
	}
}
@keyframes common_graphHead3__1UY5s{
	0%{
		bottom:2.5%;
	}
	100%{
		bottom:34%;
	}
}
.common_ym_myroom_area__1iNyx .common_graph__11ynH img:nth-child(2){
	bottom:calc(var(--bottomA) * 1);
	z-index:2;
	animation-delay:calc(var(--animationDelay) * 1);
}	
.common_ym_myroom_area__1iNyx .common_graph__11ynH img:nth-child(3){
	bottom:calc(var(--bottomA) * 2);
	z-index:3;
	animation-delay:calc(var(--animationDelay) * 2);
}	
.common_ym_myroom_area__1iNyx .common_graph__11ynH img:nth-child(4){
	bottom:calc(var(--bottomA) * 3);
	z-index:4;
	animation-delay:calc(var(--animationDelay) * 3);
}	
.common_ym_myroom_area__1iNyx .common_graph__11ynH img:nth-child(5){
	bottom:calc(var(--bottomA) * 4);
	z-index:5;
	animation-delay:calc(var(--animationDelay) * 4);
}	
.common_ym_myroom_area__1iNyx .common_graph__11ynH img:nth-child(6){
	bottom:calc(var(--bottomA) * 5);
	z-index:6;
	animation-delay:calc(var(--animationDelay) * 5);
}	
.common_ym_myroom_area__1iNyx .common_graph__11ynH img:nth-child(7){
	bottom:calc(var(--bottomA) * 6);
	z-index:7;
	animation-delay:calc(var(--animationDelay) * 6);
}	
.common_ym_myroom_area__1iNyx .common_graph__11ynH img:nth-child(8){
	bottom:calc(var(--bottomA) * 7);
	z-index:8;
	animation-delay:calc(var(--animationDelay) * 7);
}	
.common_ym_myroom_area__1iNyx .common_graph__11ynH img:nth-child(9){
	bottom:calc(var(--bottomA) * 8);
	z-index:9;
	animation-delay:calc(var(--animationDelay) * 8);
}	
.common_ym_myroom_area__1iNyx .common_graph__11ynH img:nth-child(10){
	bottom:calc(var(--bottomA) * 9);
	z-index:10;
	animation-delay:calc(var(--animationDelay) * 9);
}	
.common_ym_myroom_area__1iNyx .common_graph__11ynH img:nth-child(11){
	bottom:calc(var(--bottomA) * 10);
	z-index:11;
	animation-delay:calc(var(--animationDelay) * 10);
}	
.common_ym_myroom_area__1iNyx .common_graph__11ynH img:nth-child(12){
	bottom:calc(var(--bottomA) * 11);
	z-index:12;
	animation-delay:calc(var(--animationDelay) * 11);
}	
.common_ym_myroom_area__1iNyx .common_graph__11ynH img:nth-child(13){
	bottom:calc(var(--bottomA) * 12);
	z-index:13;
	animation-delay:calc(var(--animationDelay) * 12);
}	
.common_ym_myroom_area__1iNyx .common_graph__11ynH img:nth-child(14){
	bottom:calc(var(--bottomA) * 13);
	z-index:14;
	animation-delay:calc(var(--animationDelay) * 13);
}	
.common_ym_myroom_area__1iNyx .common_graph__11ynH img:nth-child(15){
	bottom:calc(var(--bottomA) * 14);
	z-index:15;
	animation-delay:calc(var(--animationDelay) * 14);
}	

/*brain_music_area*/
.common_brain_music_area__NtTkf{
	background:url(/static/media/뮤직룸.8bcd004e.png) no-repeat center;
	background-size:cover;
	padding-top:35.5vh;
	padding-bottom:24.3vh;
}
.common_brain_music_area__NtTkf .common_inner90__3qaGh{
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:start;
	display:flex;
	align-items:center;
	justify-content:flex-start;
}
.common_brain_music_area__NtTkf .common_index_tit__3e1q3{
	text-align:left;
	padding-left:1vw;
}
.common_brain_music_area__NtTkf .common_sub_title__hQAyC{
	text-align:left;
	padding-left:1vw;
}
.common_brain_music_area__NtTkf .common_wrap__ornby{
	width:auto;
	height:auto;
	display:-webkit-box;
	-webkit-box-pack:center;
	-webkit-box-align:start;
	display:flex;
	justify-content:center;
	align-items:flex-start;
	flex-direction:column;
	margin-left:16.5vw;
	position:relative;
}
.common_brain_music_area__NtTkf .common_wrap__ornby ul{
	height:auto;
	width:auto;
	position:relative;
	margin-top:20px;
}
.common_brain_music_area__NtTkf .common_wrap__ornby li{
	width:auto;
	min-width:390px;
	height:8vh;
	position:relative;
	border-radius:48px;
	padding:2vh 1vw;
	background:#d9fbf1;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:start;
	display:flex;
	align-items:center;
	justify-content:flex-start;
	border:none;
	box-sizing:border-box;
	transition:all .4s;
	box-shadow: 8px 8px 10px 0 rgba(0, 0, 0, .35);
}
.common_brain_music_area__NtTkf .common_wrap__ornby li.common_on__2QUU7{
	border:2px solid #ffffff;
}
.common_brain_music_area__NtTkf .common_wrap__ornby li:hover{
	border:2px solid #ffffff;
}
.common_brain_music_area__NtTkf .common_wrap__ornby li:nth-child(2){
	margin:1.5vh 0;
}
.common_brain_music_area__NtTkf .common_wrap__ornby li .common_play_wrap__3KOSn{
	display:-webkit-box;
	-webkit-box-align:start;
	-webkit-box-pack:center;
	display:flex;
	align-items:flex-start;
	justify-content:center;
	width:4vh;
	height:4vh;
	position:relative;
	margin-right:1vw;
	cursor:pointer;
}
.common_brain_music_area__NtTkf .common_wrap__ornby li .common_play_wrap__3KOSn img{
	height:100%;
	width:auto;
	position:absolute;
	top:0;
	left:0;
	visibility:hidden;
	transition:none;
}
.common_brain_music_area__NtTkf .common_wrap__ornby li .common_play_wrap__3KOSn .common_stop__3ien8, 
.common_brain_music_area__NtTkf .common_wrap__ornby li .common_play_wrap__3KOSn .common_play__2So8w{
	height:100%;
}
.common_brain_music_area__NtTkf .common_wrap__ornby li .common_play_wrap__3KOSn img.common_on__2QUU7{
	visibility:visible;
}
.common_brain_music_area__NtTkf .common_wrap__ornby li .common_right_area__3060r{
	display:-webkit-box;
	-webkit-box-align:start;
	-webkit-box-pack:center;
	display:flex;
	align-items:flex-start;
	justify-content:center;
	flex-direction:column;
	position:relative;
}
.common_brain_music_area__NtTkf .common_wrap__ornby li .common_right_area__3060r span{
	font-family: 'S-CoreDream-6Bold';
}
.common_brain_music_area__NtTkf .common_wrap__ornby li .common_right_area__3060r p{
	font-size:.7em;
}
/*coding_block_area*/
.common_coding_block_area__2gmOW{
	background:#fff9f0;
	padding-top:20.2vh;
	padding-bottom:16.3vh;
}
.common_coding_block_area__2gmOW .common_inner90__3qaGh{
	display:-webkit-box;
	-webkit-box-align:center;
	display:flex;
	align-items:center;
	flex-direction:column;
}
.common_coding_block_area__2gmOW .common_index_tit__3e1q3{
	display:flex;
}
.common_coding_block_area__2gmOW .common_index_tit__3e1q3 .common_br__2s69l{
	font-family:inherit;
	font-size:inherit;
	text-align:inherit;
	letter-spacing:inherit;
	color:inherit;
	display:flex;
	margin-right:5px;
}
.common_coding_block_area__2gmOW .common_stakato__e3n3a:first-child{
	margin-left:.5vw;
}
.common_coding_block_area__2gmOW .common_stakato__e3n3a:last-child{
	margin-right:.1vw;
}
.common_coding_block_area__2gmOW .common_stakato__e3n3a::before{
	top:-.5vh;
}
.common_coding_block_area__2gmOW .common_bottom_area__16Bx2{
	width:auto;
	height:43vh;
	position:relative;
	margin-top:15vh;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
}
.common_coding_block_area__2gmOW .common_cont__1FF3G{
	width:10vw;
	height:auto;
	position:relative;
	margin:0 .5vw;
	display:-webkit-box;
	-webkit-box-align:center;
	display:flex;
	align-items:center;
	flex-direction:column;
	transform:scale(1);
	transition:all .6s;
	cursor:pointer;
	opacity:.5;
	background:none;
	border-radius:36px;
	box-shadow:none;
	padding:3vh .5vw;
	box-sizing:border-box;
}
.common_coding_block_area__2gmOW .common_cont__1FF3G:last-child{
	width:17vw;
}
.common_coding_block_area__2gmOW .common_arrow_wrap__2RrYr{
	width:3vw;
	height:auto;
	position:relative;
	transition:all .6s;
	opacity:.5;
}
.common_coding_block_area__2gmOW .common_arrow1__Xj7Yh{
	height:auto;
}
.common_coding_block_area__2gmOW .common_arrow2__3br4Z{
	height:auto;
}
.common_coding_block_area__2gmOW .common_arrow_wrap__2RrYr img{
	width:50%;
	height:auto;
	display:block;
	margin:0 auto;
}
.common_coding_block_area__2gmOW .common_arrow_wrap__2RrYr.common_on__2QUU7{
	width:6vw;
	opacity:1;
	margin-left:4vw;
}
.common_coding_block_area__2gmOW .common_cont__1FF3G.common_on__2QUU7{
	transform:scale(1.4);
	opacity:1;
	background:#f9be2d;
	box-shadow: 8px 8px 10px 0 rgba(0, 0, 0, .35);
	margin:0 2vw;
}
.common_coding_block_area__2gmOW .common_cont__1FF3G:last-child.common_on__2QUU7{
	margin-left:4vw;
}
.common_coding_block_area__2gmOW .common_cont__1FF3G:last-child br{
	display:block;
}
.common_coding_block_area__2gmOW .common_cont__1FF3G.common_on__2QUU7:hover{
	transform:scale(1.4);
	opacity:1;
}
.common_coding_block_area__2gmOW .common_cont__1FF3G:hover{
	transform:scale(1.1);
	opacity:1;
}
.common_coding_block_area__2gmOW .common_cont__1FF3G .common_num__1L4QU{
	height:3.5vh;
	width:auto;
}
.common_coding_block_area__2gmOW .common_cont__1FF3G .common_title__1txLf{
	font-size:.65em;
	font-family: 'S-CoreDream-7ExtraBold';
	margin-top:.5vh;
}
.common_coding_block_area__2gmOW .common_cont__1FF3G .common_sub_tit__3_GE4{
	font-size:.5em;
	font-family: 'S-CoreDream-6Bold';
}
.common_coding_block_area__2gmOW .common_cont__1FF3G p{
	font-size:.5em;
	text-align:Center;
	margin-top:.5vh;
}
.common_coding_block_area__2gmOW .common_cont__1FF3G img:last-child{
	height:auto;
	width:3.5vw;
	margin-top:2vh;
}
.common_coding_block_area__2gmOW .common_box__2MqeC>img{
	height:auto;
	width:10%;
}

/*yf_area*/
.common_yf_area__4xLfs{
	background:#f9be2d;
	padding-top:21vh;
	padding-bottom:15.3vh;
}
.common_yf_area__4xLfs .common_inner90__3qaGh{
	display:-webkit-box;
	-webkit-box-align:center;
	display:flex;
	align-items:center;
	flex-direction:column;
}
.common_yf_area__4xLfs .common_sub_title__hQAyC{
	display:flex;
}
.common_yf_area__4xLfs .common_stakato__e3n3a{
	color:#fffbf2;
}
.common_yf_area__4xLfs .common_stakato__e3n3a:first-child{
	margin-left:.5vw;
}
.common_yf_area__4xLfs .common_stakato__e3n3a:last-child{
	margin-right:.2vw;
}
.common_yf_area__4xLfs .common_stakato__e3n3a::before{
	background:#fffbf2;
	display:block;
	width:.6vh;
	height:.6vh;
	top:-.5vh;
}
.common_yf_area__4xLfs .common_content_area__2tBPk{
	width:100%;
	display:-webkit-box;
	-webkit-box-pack:center;
	display:flex;
	justify-content:center;
	margin-top:8vh;
	box-sizing:border-box;
	height:46vmin;
}
.common_yf_area__4xLfs .common_content_area__2tBPk .common_btn_area__3WMBi{ 
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:end;
	display:flex;
	align-items:center;
	justify-content:flex-end;
	flex-direction:column;
	position:relative;
	box-sizing:border-box;
	padding-bottom:2%;
	margin-left:2vw;
	margin-right:3.7vw;
	width:auto;
	height:100%;
}
.common_yf_area__4xLfs .common_content_area__2tBPk .common_btn_area__3WMBi li{ 
	width:3.6vw;
	height:8%;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
	cursor:pointer;
	margin-top:26%;
}
.common_yf_area__4xLfs .common_content_area__2tBPk .common_btn_area__3WMBi li img{ 
	width:auto;
	height:100%;
	transition:all .4s;
}
.common_yf_area__4xLfs .common_content_area__2tBPk .common_btn_area__3WMBi li:hover{ 
	height:11%;
}
.common_yf_area__4xLfs .common_content_area__2tBPk .common_btn_area__3WMBi li.common_on__2QUU7:hover{ 
	height:13%;
}
.common_yf_area__4xLfs .common_content_area__2tBPk .common_btn_area__3WMBi li.common_on__2QUU7{ 
	height:13%;
}
.common_yf_area__4xLfs .common_yf_img_area__3eH7p{
	width:auto;
	height:100%;
	position:relative;
	display:-webkit-box;
	-webkit-box-align:end;
	-webkit-box-pack:center;
	display:flex;
	align-items:flex-end;
	justify-content:center;
	margin-right:1vw;
}
.common_yf_area__4xLfs .common_yf_img_area__3eH7p img{
	width:auto;
	height:98%;
	position:relative;
	display:none;
}
.common_yf_area__4xLfs .common_yf_img_area__3eH7p img.common_on__2QUU7{
	display:block;
}
.common_yf_area__4xLfs .common_yf_info_area__3AaKI{
	position:relative;
	display:-webkit-box;
	-webkit-box-pack:center;
	display:flex;
	flex-direction:column;
	justify-content:center;
	width:auto;
	height:100%;
} 
.common_yf_area__4xLfs .common_yf_info_area__3AaKI .common_album_area__2b8yB{
	width:30vmax;
	height:55%;
	margin-bottom:15%;
	position:relative;
}	
.common_yf_area__4xLfs .common_yf_info_area__3AaKI .common_album__3TKmd{
	height:auto;
	position:relative;
	box-sizing:border-box;
	width:25vmin;
	max-width:55%;
	background:#ffffff;
	padding:5px;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:Center;
	transition:all .6s;
}	
.common_yf_area__4xLfs .common_yf_info_area__3AaKI .common_album__3TKmd img{
	width:100%;
	height:auto;
	position:relative;
	display:none;
}	
.common_yf_area__4xLfs .common_yf_info_area__3AaKI .common_album__3TKmd img.common_on__2QUU7{
	display:block;
}	
.common_album1__2rB6V{
	transform:translate(0%,0%) rotate(-16deg) scale(1);
	left:-4%;
	top:0%;
	z-index:3;
}
.common_album1__2rB6V.common_on__2QUU7{
	transform:translate(9%,43%) rotate(9deg) scale(1.1);
	z-index:1;
}
.common_album2__JYhfF{
	transform:translate(0%,0%) rotate(-7deg) scale(1.1);
	top:-21%;
	left:32%;
	z-index:1;
}
.common_album2__JYhfF.common_on__2QUU7{
	transform:translate(10%,-76%) rotate(11deg) scale(1);
	z-index:3;
}
.common_yf_area__4xLfs .common_yf_info_area__3AaKI .common_txt_area__25xQw{
	width:auto;
	height:30%;
	position:relative;
	margin-top:1vh;
}	
.common_yf_area__4xLfs .common_yf_info_area__3AaKI .common_txt_box__3wTLx{
	width:auto;
	height:auto;
	position:absolute;
	top:0;
	display:-webkit-box;
	-webkit-box-align:start;
	-webkit-box-pack:end;
	display:flex;
	align-items:flex-start;
	justify-content:flex-end;
	flex-direction:column;
	visibility:hidden;
	box-sizing:border-box;
	border:2px dashed #fff9f0;
	padding:1vh 2vw;
	border-radius:30px;
	box-shadow:8px 8px 10px 0 rgba(107,82,18,.35);
	transition:none;
}	
.common_yf_area__4xLfs .common_yf_info_area__3AaKI .common_txt_box__3wTLx.common_on__2QUU7{
	visibility:visible;
}	
.common_yf_area__4xLfs .common_yf_info_area__3AaKI .common_txt_box__3wTLx span{
	text-align:left;
	color:#fff9f0;
	font-family: 'S-CoreDream-7ExtraBold';
	font-size:1em;
	transition:none;
}	
.common_yf_area__4xLfs .common_yf_info_area__3AaKI .common_txt_box__3wTLx p{
	text-align:left;
	color:#fff9f0;
	font-size:.75em;
	margin-top:.4vh;
	transition:none;
}	

.common_yf_area__4xLfs .common_inner90__3qaGh{
	justify-content:center;
}
	
/* floating_paging_btn_wrap */
.common_floating_paging_btn_wrap__10eqW{
	width:auto;
	height:100%;
	display:block;
	position:fixed;
	top:0;
	right:.1%;
	z-index:90;
	display:-webkit-box;
	-webkit-box-align:end;
	-webkit-box-pack:center;
	display:flex;
	align-items:flex-end;
	justify-content:center;
	flex-direction:column;
	padding-top:80px;
	box-sizing:border-box;
	visibility:visible;
}
.common_floating_paging_btn_wrap__10eqW li{
	cursor:pointer;
	color:#767676;
	font-size:.6em;
	text-align:right;
	padding:.5vh .3vw;
	border-radius:60px;
	margin:10% 0;
}
.common_floating_paging_btn_wrap__10eqW li.common_on__2QUU7{
	color:#525252;
	background:#f9de8a;
}
.common_floating_paging_btn_wrap__10eqW li:hover{
	color:#525252;
	background:#f9de8a;
}

/*privacy.html , use.html*/
#common_content__2kYUC{
	width:100%; 
	height:auto; 
	background:#fff9f0; 
	position:relative; 
	padding-bottom:80px;
}
#common_content__2kYUC .common_inner__1cU6t{
	display:-webkit-box;
	-webkit-box-align:center;
	display:flex;
	align-items:center;
	flex-direction:column;
}
#common_content__2kYUC span.common_index_title__2iqhX{
	color:#009cc6; 
	margin-top:60px;
	font-size:34px;
}

#common_content__2kYUC .common_wrap__ornby{
	width:80%; 
	height:auto;
	margin:0 auto;
	margin-top:30px;
}
#common_content__2kYUC .common_wrap__ornby .common_privacy_area__37ys7{
	width:100%;
	height:auto;
	position:relative;
	top:0; 
	margin:60px 0;
}
#common_content__2kYUC .common_wrap__ornby .common_privacy_area__37ys7 span.common_tit__PVpaj{
	font-size:24px; 
	text-align:left;
	top:0; 
	color:#333333;
	margin-bottom:20px;
}
#common_content__2kYUC .common_wrap__ornby .common_privacy_area__37ys7 span.common_sub_tit__3_GE4{
	font-weight:500;
	text-align:left;
}
#common_content__2kYUC .common_wrap__ornby p{
	font-size:18px;
	text-align:left;
	padding:10px 20px;
	white-space:normal; 
	word-break:keep-all; 
	word-wrap:break-word;
}
#common_content__2kYUC .common_wrap__ornby .common_privacy_info__2p3oY{
	width:100%; 
	height:auto;
	background:#f7f7f7; 
	margin-bottom:60px;
	border:1px solid #009cc6; 
	box-sizing:border-box;
	position:relative; 
	top:0px;
	border-radius:10px; 
	padding:20px;
}

#common_content__2kYUC .common_wrap__ornby .common_privacy_area__37ys7.common_area1__1KZ6K .common_box_wrap__1S8pe{
	width:100%; 
	height:auto;
	padding:20px; 
	box-sizing:border-box; 
	position:relative; 
	top:0px;
}
#common_content__2kYUC .common_box_wrap__1S8pe .common_title__1txLf{
	width:100%; 
	height:70px;
	background:#009cc6;
	position:relative;
	top:0;
	left:0;
	display:flex;
}
#common_content__2kYUC .common_box_wrap__1S8pe .common_title__1txLf span{
	color:#ffffff;
	box-sizing:border-box;
	text-align:center;
	height:100%;
	width:76%;
	display:-webkit-box;
	-webkit-box-align:Center;
	-webkit-box-pack:Center;
	display:flex;
	align-items:center;
	justify-content:Center;
}
#common_content__2kYUC .common_box_wrap__1S8pe .common_title__1txLf span:first-child{
	border-right:1px solid #ffffff;
	width:24%;
}
#common_content__2kYUC .common_box_wrap__1S8pe .common_list__1inqC{
	width:100%; 
	height:auto;
	background:#ffffff;
	border-bottom:1px solid #eaeaea;
	box-sizing:border-box;
	position:relative;
	top:0;
	display:flex;
}
#common_content__2kYUC .common_box_wrap__1S8pe .common_list__1inqC span{
	box-sizing:border-box; 
	width:76%;
	height:auto;
	padding:30px;
	display:-webkit-box;
	-webkit-box-align:Center;
	-webkit-box-pack:start;
	display:flex;
	align-items:center;
	justify-content:flex-start;
}
#common_content__2kYUC .common_box_wrap__1S8pe .common_list__1inqC span.common_name__3XKX4{
	width:24%;
	height:inherit;
	border-right:1px solid #eaeaea;
	text-align:center; 
	background:#fbfbfb;
	justify-content:center;
	padding:0;
}

/*to_top*/
#common_content__2kYUC .common_to_top__350n_{
	width:40px;
	height:40px; 
	cursor:pointer; 
	margin:0 auto; 
	background:#009cc6;
	border-radius:50px;
	box-sizing:border-box;
	position:relative; 
	top:-20px;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
}
#common_content__2kYUC .common_to_top__350n_ a{
	color:#ffffff;
	font-size:16px;
	text-align:center; 
}
#common_content__2kYUC .common_to_top__350n_ span{
	color:#ffffff;
	font-size:16px;
	text-align:center; 
}


/*mypage_html*/
/*mypage_contents*/
.common_mypage_top_line_banner__2PhSd{
	width:100%;
	height:85px;
	position:relative;
	background:#666666;
}
.common_mypage_top_line_banner__2PhSd .common_inner__1cU6t{
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
}
.common_mypage_top_line_banner__2PhSd span{
	font-size:32px;
	color:#ffffff;
	font-family: 'S-CoreDream-6Bold';
}
/*mypage_tab_btn_area*/
.common_mypage_tab_btn_area__103gK{
	height:62px;
	width:100%;
	position:relative;
	border-bottom:1px solid #e6e6e6;
	box-sizing:border-box;
	background:#ffffff;
}
.common_mypage_tab_btn_area__103gK ul{
	width:100%;
	height:100%;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
	position:relative;
}
.common_mypage_tab_btn_area__103gK li{
	height:40px;
	padding:8px 20px;
	box-sizing:border-box;
	margin:0 8px;
	cursor:pointer;
	border-radius:20px;
	position:relative;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
}
.common_mypage_tab_btn_area__103gK li a{
	font-size:14px;
	color:#666666;
}
.common_mypage_tab_btn_area__103gK li.common_on__2QUU7{
	border:2px solid #f15a24;
}
.common_mypage_tab_btn_area__103gK li.common_on__2QUU7 a{
	color:#f15a24;
}
.common_mypage_tab_btn_area__103gK li.common_hover_bg__1kXv3{
	display:none;
	position:absolute;
	width:116px;
	height:40px;
	box-sizing:border-box;
	border:2px solid #f15a24;
	border-radius:20px;
	left:332px;
}

/*mypage_tab*/
.common_mypage_tab__2c9l1{
	width:100%;
	height:auto;
	background:#ffffff;
}
.common_mypage_tab__2c9l1 .common_inner__1cU6t{
	display:-webkit-box;
	-webkit-box-align:center;
	display:flex;
	flex-direction:column;
	align-items:center;
}
.common_mypage_tab__2c9l1 .common_my_tit__38b1N{
	font-family: 'S-CoreDream-6Bold';
	font-size:32px;
}
/*mypage_report*/
.common_my_report_top_btn_area__2T17n{
	width:1060px;
	height:124px;
	position:relative;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:justify;
	display:flex;
	align-items:center;
	justify-content:space-between;
}
.common_mypage_report__3b5HF #common_report_student_list_select__3uRrL{
	width:208px;
	height:40px;
	position:relative;
	box-sizing:border-box;
	border-radius:10px;
	padding:0 30px 0 20px;
	border:1px solid #e6e6e6;
	-webkit-appearance:none;
	-moz-appearance:none;
	appearance:none; 
	background:#ffffff url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAICAYAAAAiJnXPAAAACXBIWXMAAAsSAAALEgHS3X78AAAAiElEQVQYlY2PgQmDMBAATxfQUTpCF/inG+gIdqN2hP4G3UA3cIQ6wZeXCMGUkoOHkHD8pRGRHpioZ23cHVV9AEOFtgHXNk5mNgKvCmkys7nNLkJc/gh3M4si9rwDVY3/rUB3Ep6pZiffFJmfaE7tB+9cKKQkzik1iNxbERp5v0ZERhG5FG/ufAFPWUpOP5pyIAAAAABJRU5ErkJggg==) no-repeat 90% center;
	font-size:14px;
	color:#666666;
	cursor:pointer;
}
#common_report_student_list_select__3uRrL option{
	font-size:14px;
	color:#666666;
}


.common_my_report_top_btn_area__2T17n button{
	width:130px;
	height:36px;
	background:#ffffff;
	border-radius:10px;
	border:1px solid #e6e6e6;
	box-sizing:border-box;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
	font-size:14px;
	color:#666666;
	cursor:pointer;
}
.common_my_report_top_btn_area__2T17n button img{
	width:auto;
	height:12px;
	margin-left:12px;
}
.common_mypage_report__3b5HF .common_for_freeuser_info__FsOrs{
	text-align:right;
	color:#f15a24;
	font-size:13.5px;
	position:relative;
	margin-top:24px;
	width:1060px;
	display:-webkit-box;
	-webkit-box-pack:end;
	display:flex;
	justify-content:flex-end;
	box-sizing:border-box;
	padding-right:10px;
}
.common_mypage_report__3b5HF .common_for_freeuser_info__FsOrs br{
	display:none;
}
.common_my_report_missonprogress_area__15MF1{
	width:1060px;
	height:442px;
	box-sizing:border-box;
	border:1px solid #e6e6e6;
	border-radius:22px 22px 34px 34px;
	overflow:hidden;
	margin-top:12px;
}
.common_my_report_missonprogress_area__15MF1 .common_head__3zcNA{
	height:52px;
	width:100%;
	border-bottom:1px solid #e6e6e6;
	box-sizing:border-box;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:start;
	display:flex;
	align-items:center;
	justify-content:flex-start;
	padding:0 30px;
	position:relative;
}
.common_my_report_missonprogress_area__15MF1 .common_head__3zcNA span{
	font-size:15px;
	color:#4d4d4d;
	font-family: 'S-CoreDream-6Bold';
	margin-right:12px;
}
.common_my_report_missonprogress_area__15MF1 .common_head__3zcNA p{
	font-size:14px;
	color:#b3b3b3;
}
.common_my_report_missonprogress_area__15MF1 .common_wrap__ornby{
	width:100%;
	height:390px;
	display:-webkit-box;
	-webkit-box-align:center;
	display:flex;
	align-items:center;
	position:relative;
}
.common_my_report_missonprogress_area__15MF1 .common_misson_progress__317wj{
	width:20%;
	height:368px;
	box-sizing:border-box;
	border-right:1px solid #e6e6e6;
	padding:20px;
	padding-left:30px;
	display:-webkit-box;
	-webkit-box-align:center;
	display:flex;
	align-items:center;
	flex-direction:column;
}
.common_my_report_missonprogress_area__15MF1 .common_misson_progress__317wj:last-child{
	border:none;
}
.common_misson_progress__317wj .common_misson_left__186Ms{
	width:100%;
	height:auto;
	display:-webkit-box;
	-webkit-box-align:center;
	display:flex;
	align-items:center;
	flex-direction:column;
}
.common_misson_progress__317wj img{
	width:80px;
	height:80px;
	margin-bottom:36px;
}
.common_misson_progress__317wj .common_progress_wrap__3Aq4A{
	display:-webkit-box;
	-webkit-box-align:end;
	display:flex;
	align-items:flex-end;
	margin-bottom:20px;
}
.common_misson_progress__317wj .common_progress_wrap__3Aq4A span{
	font-family: 'S-CoreDream-6Bold';
	font-size:16px;
	color:#8cc63f;
}
.common_misson_progress__317wj:nth-child(2) .common_progress_wrap__3Aq4A span{
	color:#fbb03b;
}
.common_misson_progress__317wj:nth-child(3) .common_progress_wrap__3Aq4A span{
	color:#29abe2;
}
.common_misson_progress__317wj:nth-child(4) .common_progress_wrap__3Aq4A span{
	color:#d9e021;
}
.common_misson_progress__317wj:nth-child(5) .common_progress_wrap__3Aq4A span{
	color:#00ffff;
}
.common_misson_progress__317wj .common_progress_wrap__3Aq4A .common_now__35CU6{
	font-size:38px;
	color:#22b573;
	margin-right:5px;
	line-height:1;
}
.common_misson_progress__317wj:nth-child(2) .common_progress_wrap__3Aq4A .common_now__35CU6{
	color:#f15a24;
}
.common_misson_progress__317wj:nth-child(3) .common_progress_wrap__3Aq4A .common_now__35CU6{
	color:#0071bc;
}
.common_misson_progress__317wj:nth-child(4) .common_progress_wrap__3Aq4A .common_now__35CU6{
	color:#8cc63f;
}
.common_misson_progress__317wj:nth-child(5) .common_progress_wrap__3Aq4A .common_now__35CU6{
	color:#29abe2;
}
.common_misson_progress__317wj .common_misson_txt__2Cdse{
	width:100%;
	height:auto;
	display:-webkit-box;
	-webkit-box-align:center;
	display:flex;
	align-items:center;
	flex-direction:column;
}
.common_misson_progress__317wj .common_misson_name__23B09{
	font-size:14px;
	font-family: 'S-CoreDream-6Bold';
	color:#22b573;
}
.common_misson_progress__317wj:nth-child(2) .common_misson_name__23B09{
	color:#f15a24;
}
.common_misson_progress__317wj:nth-child(3) .common_misson_name__23B09{
	color:#0071bc;
}
.common_misson_progress__317wj:nth-child(4) .common_misson_name__23B09{
	color:#8cc63f;
}
.common_misson_progress__317wj:nth-child(5) .common_misson_name__23B09{
	color:#29abe2;
}
.common_misson_progress__317wj p{
	font-size:13px;
	color:#b3b3b3;
	text-align:left;
	margin-top:40px;
}
.common_my_report_study_time_area___GX6l{
	width:1060px;
	height:auto;
	box-sizing:border-box;
	border:1px solid #e6e6e6;
	border-radius:22px 22px 34px 34px;
	overflow:hidden;
	margin-top:60px;
}
.common_my_report_study_time_area___GX6l .common_head__3zcNA{
	height:52px;
	width:100%;
	border-bottom:1px solid #e6e6e6;
	box-sizing:border-box;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:start;
	display:flex;
	align-items:center;
	justify-content:flex-start;
	padding:0 30px;
	position:relative;
}
.common_my_report_study_time_area___GX6l .common_head__3zcNA span{
	font-size:15px;
	color:#4d4d4d;
	font-family: 'S-CoreDream-6Bold';
	margin-right:12px;
}
.common_my_report_study_time_area___GX6l .common_head__3zcNA p{
	font-size:14px;
	color:#b3b3b3;
}
.common_my_report_study_time_area___GX6l .common_wrap__ornby{
	width:100%;
	height:auto;
	display:-webkit-box;
	-webkit-box-align:center;
	display:flex;
	align-items:center;
	position:relative;
}
.common_my_report_study_time_area___GX6l .common_wrap__ornby ul{
	width:100%;
	height:auto;
	padding:24px 30px;
	box-sizing:border-box;
}
.common_my_report_study_time_area___GX6l .common_wrap__ornby li{
	width:100%;
	height:auto;
	display:flex;
}
.common_my_report_study_time_area___GX6l .common_wrap__ornby span{
	color:#4d4d4d;
	font-size:15px;
	font-family: 'S-CoreDream-6Bold';
}
.common_my_report_study_time_area___GX6l .common_wrap__ornby .common_name__3XKX4{
	margin-right:10px;
}
.common_my_report_cognitive_ability_area__3j2Ay{
	width:1060px;
	height:auto;
	box-sizing:border-box;
	border:1px solid #e6e6e6;
	border-radius:22px 22px 34px 34px;
	overflow:hidden;
	margin-top:60px;
	margin-bottom:178px;
}
.common_my_report_cognitive_ability_area__3j2Ay .common_head__3zcNA{
	height:52px;
	width:100%;
	border-bottom:1px solid #e6e6e6;
	box-sizing:border-box;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:start;
	display:flex;
	align-items:center;
	justify-content:flex-start;
	padding:0 30px;
	position:relative;
}
.common_my_report_cognitive_ability_area__3j2Ay .common_head__3zcNA span{
	font-size:15px;
	color:#4d4d4d;
	font-family: 'S-CoreDream-6Bold';
	margin-right:12px;
}
.common_my_report_cognitive_ability_area__3j2Ay .common_wrap__ornby{
	width:100%;
	height:394px;
	display:flex;
	position:relative;
}
.common_my_report_cognitive_ability_area__3j2Ay .common_ability_area__6H0Ov{
	width:50%;
	height:100%;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	flex-direction:column;
	align-items:center;
	justify-content:center;
	padding:52px 64px;
	padding-left:77px;
	box-sizing:border-box;
}
.common_my_report_cognitive_ability_area__3j2Ay .common_ability_area__6H0Ov:last-child{
	padding:52px 64px;
	padding-right:77px;
}
.common_my_report_cognitive_ability_area__3j2Ay .common_cognitive_ability__ai_Du{
	width:100%;
	height:52px;
	margin-bottom:68px;
	display:flex;
}
.common_my_report_cognitive_ability_area__3j2Ay .common_cognitive_ability__ai_Du:last-child{
	margin-bottom:0;
}
.common_cognitive_ability__ai_Du .common_left_area__2Nwxl{
	height:100%;
	width:40px;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
}
.common_cognitive_ability__ai_Du img{
	width:100%;
	height:auto;
}
.common_cognitive_ability__ai_Du img.common_creative_icon__1rexS{
	width:114%;
}
.common_cognitive_ability__ai_Du .common_right_area__3060r{
	padding-left:6px;
	box-sizing:border-box;
	height:100%;
}
.common_cognitive_ability__ai_Du .common_right_area__3060r p{
	width:100%;
	height:22px;
	font-size:13px;
	color:#b3b3b3;
	text-align:left;
}
.common_cognitive_ability__ai_Du .common_top_area__1Ir0S{
	width:100%;
	height:30px;
	display:-webkit-box;
	-webkit-box-align:center;
	display:flex;
	align-items:center;
}
.common_cognitive_ability__ai_Du .common_top_area__1Ir0S span{
	font-family: 'S-CoreDream-6Bold';
	font-size:24px;
	color:#f15a24;
}
.common_cognitive_ability__ai_Du:nth-child(2) .common_ability_percent__3ns1G{
	color:#8cc63f;
}
.common_cognitive_ability__ai_Du:nth-child(3) .common_ability_percent__3ns1G{
	color:#00a99d;
}
.common_ability_area__6H0Ov:last-child .common_cognitive_ability__ai_Du:nth-child(1) .common_ability_percent__3ns1G{
	color:#7f65be;
}
.common_ability_area__6H0Ov:last-child .common_cognitive_ability__ai_Du:nth-child(2) .common_ability_percent__3ns1G{
	color:#008bbc;
}
.common_ability_area__6H0Ov:last-child .common_cognitive_ability__ai_Du:nth-child(3) .common_ability_percent__3ns1G{
	color:#ff46c9;
}
.common_cognitive_ability__ai_Du .common_top_area__1Ir0S span.common_name__3XKX4{
	font-size:16px;
	color:#4d4d4d;
	margin-right:14px;
}

/*mypage_stuedent_info*/
.common_translate_popup__2xLhW{
	display:none;
	width:100%;
	height:100%;
	position:fixed;
	top:0;
	left:0;
	z-index:140;
}
.common_translate_popup__2xLhW div{
	width:300px;
	height:230px;
	background:#ffffff;
	display:block;
	position:absolute;
	top:50%;
	left:50%;
	transform:translate(-50%,-50%);
	box-sizing:border-box;
	padding:30px;
	border-radius:30px;
}
.common_translate_popup__2xLhW p{
	font-size:18px;
	text-align:center;
	margin-bottom:20px;
	position:relative;
	z-index:141;
}
.common_translate_popup__2xLhW select#common_student_info_product_select__27oSV{
	width:100%;
	height:50px;
	border:1px solid #e6e6e6;
	border-radius:30px;
	font-size:15px;
	-webkit-appearance:none;
	-moz-appearance:none;
	appearance:none;
	background:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAICAYAAAAiJnXPAAAACXBIWXMAAAsSAAALEgHS3X78AAAAiElEQVQYlY2PgQmDMBAATxfQUTpCF/inG+gIdqN2hP4G3UA3cIQ6wZeXCMGUkoOHkHD8pRGRHpioZ23cHVV9AEOFtgHXNk5mNgKvCmkys7nNLkJc/gh3M4si9rwDVY3/rUB3Ep6pZiffFJmfaE7tB+9cKKQkzik1iNxbERp5v0ZERhG5FG/ufAFPWUpOP5pyIAAAAABJRU5ErkJggg==) no-repeat 90% center;
	box-sizing:border-box;
	padding:10px;
	cursor:pointer;
}
.common_translate_popup__2xLhW select option{
	font-size:16px;
}
.common_translate_popup__2xLhW div.common_btn_area__3WMBi{
	width:100%;
	height:36px;
	position:relative;
	top:0;
	left:0;
	padding:0;
	background:none;
	transform:translate(0,0);
	display:-webkit-box;
	-webkit-box-align:Center;
	-webkit-box-pack:Center;
	display:flex;
	align-items:center;
	justify-content:center;
	margin-top:30px;
}
.common_translate_popup__2xLhW .common_confirm__3QYm7{
	width:40%;
	height:100%;
	position:relative;
	border:none;
	background:#009cc6;
	color:#ffffff;
	border-radius:30px;
	cursor:pointer;
}
.common_translate_popup__2xLhW .common_cancle__H1W7T{
	width:40%;
	height:100%;
	position:relative;
	border:1px solid #009cc6;
	background:#ffffff;
	color:#009cc6;
	border-radius:30px;
	margin-left:20px;
	cursor:pointer;
}

.common_mypage_stuedent_info__2waua .common_my_tit__38b1N{
	margin-top:124px;
}
.common_parents_state_area__14Fnn{
	width:1000px;
	height:144px;
	position:relative;
	margin:0 auto;
	margin-top:66px;
}
.common_parents_state_area__14Fnn>p{
	font-size:13px;
	color:#b3b3b3;
	text-align:left;
	margin-bottom:20px;
	margin-left:40px;
}
.common_mypage_stuedent_info__2waua p.common_ellip__2foBj{
	width:96%;
	display:block;
	overflow:hidden;
	text-overflow:ellipsis;
	white-space:nowrap;
	word-break:normal;
}
.common_mypage_stuedent_info__2waua span.common_ellip__2foBj{
	width:96%;
	display:block;
	overflow:hidden;
	text-overflow:ellipsis;
	white-space:nowrap;
	word-break:normal;
}
/* 학부모 상태가 유료 일때 html에서 147,151번줄의 p태그에 class="pay" 추가 */
.common_parents_state_box__2gFsM{
	width:100%;
	height:110px;
	border-radius:55px;
	box-sizing:border-box;
	border:2px solid #00d5cd;
	position:relative;
	display:-webkit-box;
	-webkit-box-pack:center;
	-webkit-box-align:center;
	display:flex;
	align-items:center;
	justify-content:center;
}
.common_parents_state_box__2gFsM .common_state_head__160ZN{
	height:85px;
	width:222px;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:starat;
	display:flex;
	align-items:center;
	justify-content:flex-starat;
	box-sizing:border-box;
	border-right:1px solid #e6e6e6;
	padding-left:32px;
}
.common_state_head__160ZN .common_profile_wrap__2OQ0C{
	width:50px;
	height:50px;
	border-radius:50%;
	box-sizing:border-box;
	border:1px solid #cccccc;
	overflow:hidden;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
	margin-right:40px; 
}
.common_state_head__160ZN .common_profile_wrap__2OQ0C img{
	height:101%;
	width:auto;
}
.common_parents_state_box__2gFsM .common_state_head__160ZN>span{
	font-family: 'S-CoreDream-6Bold';
	color:#4d4d4d;
	font-size:16px;
}
.common_parents_state_box__2gFsM .common_right_area__3060r{
	position:relative;
	display:-webkit-box;
	-webkit-box-pack:center;
	-webkit-box-align:center;
	display:flex;
	align-items:center;
	justify-content:center;
}
.common_parents_state_box__2gFsM .common_state_list__TV0DT{
	height:85px;
	width:205px;
	display:-webkit-box;
	-webkit-box-align:start;
	-webkit-box-pack:center;
	display:flex;
	flex-direction:column;
	align-items:flex-start;
	justify-content:center;
	padding-left:52px;
	box-sizing:border-box;
}
.common_parents_state_box__2gFsM .common_state_list__TV0DT:nth-child(1){
	padding-left:42px;
	width:140px;
}
.common_parents_state_box__2gFsM .common_state_list__TV0DT:nth-child(2){
	padding-left:20px;
}
.common_parents_state_box__2gFsM .common_state_list__TV0DT:nth-child(3){
	padding-left:10px;
	width:195px;
}
.common_parents_state_box__2gFsM .common_state_list__TV0DT:nth-child(4){
	padding-left:34px;
}
.common_state_list__TV0DT span{
	font-size:13px;
	font-family: 'S-CoreDream-6Bold';
	color:#999999;
	text-align:left;
	margin-bottom:18px;
}
.common_state_list__TV0DT p{
	font-size:15px;
	color:#b3b3b3;
	text-align:left;
}
.common_state_list__TV0DT div{
	display:flex;
	position:relative;
}
.common_state_list__TV0DT .common_info_popup_btn__1REaP{
	border:none;
	background:none;
	height:20px;
	width:auto;
	margin-left:26px;
	cursor:pointer;
	position:relative;
	z-index:5;
}
.common_state_list__TV0DT .common_info_popup_btn__1REaP img{
	height:100%;
	width:auto;
	transform:rotate(90deg);
}
.common_state_list__TV0DT .common_info_popup__25W_w{
	display:none;
	width:86%;
	height:auto;
	padding:20px;
	padding-top:32px;
	background:#ffffff;
	border:1px solid #e6e6e6;
	position:absolute;
	top:-10px;
	z-index:3;
}	
.common_state_list__TV0DT li{
	margin:5px 0;
}
.common_state_list__TV0DT li span{
	margin-bottom:0;
}
.common_parents_state_area__14Fnn .common_state_list__TV0DT p.common_pay__OZNCY{
	color:#f15a24;
}
.common_mypage_stuedent_info__2waua .common_line__RCi7Y{
	background:#e6e6e6;
	height:1px;
	width:1060px;
	margin:0 auto;
	position:relative;
	margin-top:68px;
}
.common_student_state_area__2A2tX{
	width:900px;
	height:auto;
	min-height:500px;
	position:relative;
	margin:0 auto;
	margin-top:68px;
}
.common_student_state_box__Ff_ya{
	width:100%;
	height:152px;
	border-radius:76px;
	box-sizing:border-box;
	border:1px solid #e6e6e6;
	position:relative;
	display:-webkit-box;
	-webkit-box-pack:center;
	-webkit-box-align:center;
	display:flex;
	align-items:center;
	justify-content:center;
	margin-top:48px;
}
.common_student_state_box__Ff_ya.common_make__37z1a{
	border:1px solid #f15a24;
}
.common_student_state_box__Ff_ya.common_make__37z1a::before{
	content:'구매한 학습계정';
	width:136px;
	height:28px;
	background:#f15a24;
	border-radius:14px;
	color:#ffffff;
	font-size:14px;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
	position:absolute;
	top:-14px;
	left:90px;
	z-index:10;
}
.common_student_state_box__Ff_ya .common_state_box_cover__2ms9u{
	display:none;
}
.common_student_state_box__Ff_ya.common_make__37z1a .common_state_box_cover__2ms9u{
	display:block;
	width:100%;
	height:100%;
	position:absolute;
	z-index:5;
	border-radius:76px;
	background:#ffffff;
	overflow:hidden;
}
.common_student_state_box__Ff_ya.common_make__37z1a .common_state_box_cover__2ms9u div{
	width:100%;
	height:100%;
	position:relative;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:Center;
	flex-direction:column;
}
.common_student_state_box__Ff_ya.common_make__37z1a .common_state_box_cover__2ms9u p{
	color:#f15a24;
	font-size:14px;
	text-align:center;
	margin-bottom:15px;
}
.common_student_state_box__Ff_ya.common_make__37z1a .common_state_box_cover__2ms9u a{
	width:180px;
	height:40px;
	border-radius:30px;
	box-sizing:border-box;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
	border:1px solid #f15a24;
	color:#f15a24;
	font-size:13px;
	background:#ffffff;
}
.common_student_state_box__Ff_ya.common_make__37z1a .common_state_box_cover__2ms9u a:hover{
	background:#f15a24;
	color:#ffffff;
}
.common_student_state_box__Ff_ya.common_pay__OZNCY{
	border:1px solid #009cc6;
}
.common_student_state_box__Ff_ya.common_pay__OZNCY .common_state_list__TV0DT p.common_end__jQlbl{
	color:#f15a24;
}
.common_student_state_box__Ff_ya:first-child{
	margin-top:0;
}
/*학습자 정보/관리 페이지에 처음 들어왔을때 새로운 학습자가 생성되었을때 클래스 student_state_box에 클래스 new 추가 */
.common_student_state_box__Ff_ya.common_new__NHR6G{
	border:1px solid #f15a24;
}
.common_student_state_box__Ff_ya.common_new__NHR6G::before{
	content:'만료된 학습계정';
	width:136px;
	height:28px;
	background:#f15a24;
	border-radius:14px;
	color:#ffffff;
	font-size:14px;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
	position:absolute;
	top:-14px;
	left:90px;
}
.common_student_state_box__Ff_ya.common_new__NHR6G::after{
	content:"* 이용권을 변경해 주셔야 플레이가 가능합니다.";
	display:block;
	color:#f15a24;
	font-size:14px;
	text-align:left;
	position:absolute;
	top:153px;
	left:90px;
}
.common_student_state_box__Ff_ya .common_state_head__160ZN{
	height:85px;
	width:204px;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:justify;
	display:flex;
	align-items:center;
	justify-content:space-between;
	box-sizing:border-box;
	border-right:1px solid #e6e6e6;
	padding-left:15px;
	padding-right:15px;
}
.common_student_state_box__Ff_ya .common_state_head__160ZN .common_profile_wrap__2OQ0C{
	width:50px;
	height:50px;
	border-radius:50%;
	box-sizing:border-box;
	border:1px solid #cccccc;
	background:#fafafa;
	overflow:hidden;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
	margin-right:0; 
}
.common_student_state_box__Ff_ya .common_state_head__160ZN .common_profile_wrap__2OQ0C span{
	font-size:22px;
	color:#4d4d4d;
	font-family: 'S-CoreDream-6Bold';
}
.common_student_state_box__Ff_ya .common_state_head__160ZN p{
	font-family: 'S-CoreDream-6Bold';
	color:#4d4d4d;
	font-size:16px;
	text-align:center;
	width:65%;
	white-space:normal;
	display:-webkit-box;
	-webkit-line-clamp:3;
	-webkit-box-orient:vertical;
}
.common_student_state_box__Ff_ya .common_right_area__3060r{
	position:relative;
	display:-webkit-box;
	-webkit-box-pack:center;
	-webkit-box-align:center;
	display:flex;
	align-items:center;
	justify-content:center;
}
.common_student_state_box__Ff_ya .common_state_list__TV0DT{
	height:85px;
	width:172px; 
	display:-webkit-box;
	-webkit-box-align:start;
	-webkit-box-pack:center;
	display:flex;
	flex-direction:column;
	align-items:flex-start;
	justify-content:center;
	padding-left:40px;
	box-sizing:border-box;
}
.common_student_state_box__Ff_ya .common_state_list__TV0DT:nth-child(1){
	width:120px;
}
.common_student_state_box__Ff_ya .common_state_list__TV0DT:nth-child(2){
	width:165px;
}
.common_student_state_box__Ff_ya .common_state_list__TV0DT:last-child{
	width:188px;
	height:100%;
	padding-left:40px;
}
.common_student_state_box__Ff_ya .common_state_list__TV0DT:last-child a{
	width:126px;
	height:50px;
	border-radius:30px;
	box-sizing:border-box;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
	border:1px solid #e6e6e6;
	color:#b3b3b3;
	font-size:13px;
}
/* 정보수정 a태그에 active 클래스 추가시 활성화, active 제거시 비활성화 */
/* 클래스 추가 없는 기본상태: 비활성화 */
.common_student_state_box__Ff_ya .common_state_list__TV0DT:last-child a.common_active__2ks6_{
	pointer-events:auto;
	border:1px solid #e6e6e6;
	color:#b3b3b3;
}
.common_student_state_box__Ff_ya .common_state_list__TV0DT:last-child button{
	width:126px;
	height:50px;
	border-radius:30px;
	box-sizing:border-box;
	border:1px solid #f15a24;
	color:#f15a24;
	font-size:13px;
	margin-top:10px;
	background:#ffffff;
	cursor:pointer;
	display:none;
}
.common_student_state_box__Ff_ya.common_new__NHR6G .common_state_list__TV0DT:last-child button{
	display:block;
}
.common_student_state_box__Ff_ya .common_state_list__TV0DT span{
	font-size:13px;
	font-family: 'S-CoreDream-6Bold';
	color:#999999;
	text-align:left;
	margin-bottom:18px;
}
/* 학습자 상태가 유료 일때 html에서 174번줄의 p태그에 class="pay" 추가 */
.common_student_state_box__Ff_ya .common_state_list__TV0DT p{
	font-size:15px;
	color:#b3b3b3;
	text-align:left;
}
.common_student_state_box__Ff_ya .common_state_list__TV0DT:nth-child(4) p{
	visibility:hidden;
}
.common_student_state_box__Ff_ya .common_state_list__TV0DT:nth-child(4) p.common_pay__OZNCY{
	color:#f15a24;
	visibility:visible;
}
.common_mypage_stuedent_info__2waua .common_go_price__3todw{
	width:300px;
	height:50px;
	background:#f15a24 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACUAAAAkCAYAAAAOwvOmAAAACXBIWXMAAAsSAAALEgHS3X78AAACK0lEQVRYhc2Y7VHDMAyGX3r8JxuQDcgIHaEbkBG6ARmhI2SEMAFhg7BB2SBMIE69Vz1dSGM7TRt0p6OltvxEdvThBxFBomQAttQCQA7gmSZ+AHQAegAttUtdIAVKIUoArwC+ADRcFO5vTi2oOwIeANT8HBaFCmguIq2I9CJS8XvMPNOdm1/GzAkNKGnsICJZIsxQtyJyJOCkrSkjNYHU2DUwXjNnt0iF0ondAt65pPspsLFJhxsDmRrYn3XGDuWkayfOXjsDrB6bN9zvnmCpxvWtlJln7Eivnf+/cTFjz0DXpAa7K6Rn7KsYlE9iUBmhyjsCmVjU3w+hSv5wXAEK9NTZIR6qXgkILk1pKjtB6da93PksjUnjoTRxfkYny9tJ66G2c8qLG8iRDjqfqbW9BDrmST88BgaqFz8SDMcUZ5+2TZckBNXTSEis+owZGzwqIagu9FQUjTNvkWODsqE3iiWMXSn6QN8gVEf3ry25be2G8eHlH4BtLbJbSHhn57Gm7IZQjc/SK0jJ4Nl5KEvGi7w9M6RibwgPBf6wRqVgO3Re20NZB1vdESgf1lIYCZ6lqwRTSpk2YsxQMq5R/5l/oTOZ09HM6WS62L7Pgy3ZHfsOppnqLUO9nF1qLAVUEGbyPiHWSHel1zLXGwYfMtZoRa+19GBsS1/wGsDmRl0jpVya6duiqUDjiuZKrZ38rZ3K8NLM3jANjNEl95zrRQP014uWzBXQg6bX/gB+AdEft+3B/lfjAAAAAElFTkSuQmCC) no-repeat 10px center;
	background-size:34px;
	border-radius:25px;
	box-sizing:border-box;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
	color:#ffffff;
	font-size:16px;
	margin-top:60px;
	margin-bottom:200px;
}

/*mypage_parents_info*/
.common_mypage_parents_info__6vf6J .common_my_tit__38b1N{
	margin-top:124px;
}
.common_mypage_parents_info__6vf6J .common_parents_info_area__3V1oO{
	width:1060px;
	height:auto;
	margin:0 auto;
	box-sizing:border-box;
	border-radius:50px 50px 28px 28px;
	border:1px solid #e6e6e6;
	padding:44px 64px;
	margin-top:16px;
	margin-bottom:160px;
}
.common_parents_info_area__3V1oO ul{
	width:100%;
	height:auto;
	position:relative;
}
.common_parents_info_area__3V1oO li{
	width:100%;
	height:86px;
	box-sizing:border-box;
	border-bottom:1px solid #e6e6e6;
	display:flex;
}
.common_parents_info_area__3V1oO li:last-child{
	border:none;
}
.common_parents_info_area__3V1oO li .common_head__3zcNA{
	height:100%;
	width:200px;
	font-size:15px;
	color:#666666;
	text-align:left;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:start;
	display:flex;
	align-items:center;
	justify-content:flex-start;
}
.common_parents_info_area__3V1oO .common_right_area__3060r{
	height:100%;
	width:840px;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:start;
	display:flex;
	align-items:center;
	justify-content:flex-start;
}
.common_parents_info_area__3V1oO p{
	font-size:15px;
	color:#666666;
	text-align:left;
	padding-left:34px;
}
.common_parents_info_area__3V1oO p.common_info__3UlbF{
	font-size:13px;
	color:#b3b3b3;
	text-align:left;
	font-family: 'S-CoreDream-3Light';
	padding-left:0;
}
.common_parents_info_area__3V1oO input{
	width:257px;
	height:55px;
	border-radius:27px;
	box-sizing:border-box;
	border:1px solid #e6e6e6;
	padding:18px 34px;
	margin-right:32px;
	background:none;
}
.common_parents_info_area__3V1oO input::-webkit-input-placeholder{
	color:#e6e6e6;
}
.common_parents_info_area__3V1oO input:-ms-input-placeholder{
	color:#e6e6e6;
}
.common_parents_info_area__3V1oO input::placeholder{
	color:#e6e6e6;
}
.common_parents_info_area__3V1oO button{
	width:147px;
	height:35px;
	box-sizing:border-box;
	border:1px solid #b3b3b3;
	background:#ffffff;
	border-radius:17px;
	font-size:13px;
	color:#808080;
	cursor:pointer;
}
.common_parents_info_area__3V1oO li.common_type1__s-Qmv{
	height:132px;
}
.common_parents_info_area__3V1oO li.common_type1__s-Qmv .common_right_area__3060r{
	-webkit-box-align:start;
	-webkit-box-pack:center;
	align-items:flex-start;
	justify-content:center;
	flex-direction:column;
}
.common_parents_info_area__3V1oO li.common_type1__s-Qmv p.common_info__3UlbF{
	font-size:11px;
	margin-top:10px;
}
.common_parents_info_area__3V1oO li.common_base__15Urx{
	height:108px;
}
.common_parents_info_area__3V1oO li.common_base__15Urx input{
	margin-right:0;
}
#common_parents_info_phone_number__2WGbh,
#common_parents_info_password__2sEjU,
#common_parents_info_password_confirm__bDRX0,
#common_parents_info_company_name__2MGYb,
#common_parents_info_company_number__7o0rx,
#common_parents_info_company_tel__1bbMR,
#common_parents_info_fax__1EuVN,
#common_parents_info_adress_number__2vrWZ,
#common_parents_info_adress__1U5D9,
#common_parents_info_adress_more__1seaR{
	background:#ffffff;
}
.common_parents_info_area__3V1oO li.common_base__15Urx #common_parents_info_company_type__28Sq_{
	width:190px;
	height:40px;
	border-radius:27px;
	box-sizing:border-box;
	border:1px solid #808080;
	padding:8px 34px;
	-webkit-appearance:none;
	-moz-appearance:none;
	appearance:none; 
	background:#ffffff url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAICAYAAAAiJnXPAAAACXBIWXMAAAsSAAALEgHS3X78AAAAiElEQVQYlY2PgQmDMBAATxfQUTpCF/inG+gIdqN2hP4G3UA3cIQ6wZeXCMGUkoOHkHD8pRGRHpioZ23cHVV9AEOFtgHXNk5mNgKvCmkys7nNLkJc/gh3M4si9rwDVY3/rUB3Ep6pZiffFJmfaE7tB+9cKKQkzik1iNxbERp5v0ZERhG5FG/ufAFPWUpOP5pyIAAAAABJRU5ErkJggg==) no-repeat 90% center;
	font-size:14px;
	color:#666666;
	cursor:pointer;
}
.common_parents_info_area__3V1oO li.common_base__15Urx select option{
	font-size:14px;
	color:#666666;
}
.common_parents_info_area__3V1oO li.common_adress_area__2wW7n{
	height:256px;
}
.common_parents_info_area__3V1oO li.common_adress_area__2wW7n .common_right_area__3060r{
	-webkit-box-align:start;
	-webkit-box-pack:center;
	align-items:flex-start;
	justify-content:center;
	flex-direction:column;
}
.common_parents_info_area__3V1oO li.common_adress_area__2wW7n .common_top_area__1Ir0S{
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:start;
	display:flex;
	align-items:center;
	justify-content:flex-start;
}
.common_parents_info_area__3V1oO li.common_adress_area__2wW7n .common_top_area__1Ir0S input{
	margin-top:0;
	width:257px;
}
.common_parents_info_area__3V1oO li.common_adress_area__2wW7n input{
	margin-top:12px;
	width:644px;
}
.common_parents_info_area__3V1oO li.common_sms_add__3m9BZ .common_top_area__1Ir0S{
	display:-webkit-box;
	-webkit-box-align:start;
	-webkit-box-pack:center;
	display:flex;
	align-items:flex-start;
	justify-content:center;
}
.common_parents_info_area__3V1oO li.common_sms_add__3m9BZ br{
	display:none;
}
.common_parents_info_area__3V1oO .common_radio_btn_area__3nYLt{
	display:-webkit-box;
	-webkit-box-align:center;
	display:flex;
	align-items:center;
	width:auto;
}
.common_parents_info_area__3V1oO .common_radio_btn_area__3nYLt span{
	margin-right:56px;
	font-size:15px;
	color:#666666;
}
.common_parents_info_area__3V1oO .common_radio_btn_area__3nYLt input{
	width:30px;
	height:30px;
	margin-right:8px;
	-webkit-appearance:none;
	-moz-appearance:none;
	     appearance:none;
	border-color:#e6e6e6;
	padding:0;
}
.common_parents_info_area__3V1oO .common_radio_btn_area__3nYLt input:checked{
	-webkit-appearance:none;
	-moz-appearance:none;
	     appearance:none;
	background:#ff6e00;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
}
.common_parents_info_area__3V1oO .common_radio_btn_area__3nYLt input:checked::after{
	content:'';
	display:block;
	width:17px;
	height:17px;
	border-radius:50%;
	background:#ffffff;
}
.common_parents_info_area__3V1oO li.common_withdrawal__VYf3T{
	height:40px;
	justify-content:flex-end;
	padding-right:20px;
	padding-top:12px;
	position:relative;
}
.common_parents_info_area__3V1oO .common_withdrawal__VYf3T button{
	border:none;
	width:auto;
	height:100%;
	text-decoration:underline;
	color:#b3b3b3;
	font-size:15px;
}
.common_parents_info_area__3V1oO .common_btn_area__3WMBi{
	margin-top:90px;
	width:100%;
	height:50px;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
	position:relative;
}
.common_parents_info_area__3V1oO .common_btn_area__3WMBi button{
	width:150px;
	height:100%;
	border-radius:25px;
	background:#cccccc;
	font-size:16px;
	color:#ffffff;
	border:none;
}
.common_parents_info_area__3V1oO .common_btn_area__3WMBi button:last-child{
	background:#f16817;
	margin-left:20px;
}

/*mypage_pay_info*/
.common_mypage_pay_info__Oxy1- .common_my_tit__38b1N{
	margin-top:124px;
}
.common_mypage_pay_info__Oxy1- .common_ellip__2foBj{
	width:90%;
	display:block;
	overflow:hidden;
	text-overflow:ellipsis;
	white-space:nowrap;
	word-break:normal;
}
.common_mypage_pay_info__Oxy1- .common_pay_info_area__2GMDi{
	width:1078px;
	height:auto;
	min-height:432px;
	margin:58px auto;
	margin-bottom:160px;
}
.common_pay_info_area__2GMDi .common_title__1txLf{
	width:100%;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:start;
	display:flex;
	align-items:center;
	justify-content:flex-start;
}
.common_pay_info_area__2GMDi .common_title__1txLf p{
	font-size:16px;
	font-family: 'S-CoreDream-6Bold';
	text-align:left;
}
.common_pay_info_area__2GMDi .common_title__1txLf div{
	width:8px;
	height:8px;
	border-radius:50%;
	background:#333333;
	margin-right:4px;
}
.common_mypage_pay_info__Oxy1- .common_pay_info_list_wrap__35I6x{
	width:100%;
	height:auto;
	margin-top:10px;
}
.common_pay_info_list_wrap__35I6x li{
	width:100%;
	display:flex;
	box-sizing:border-box;
}
.common_pay_info_list_wrap__35I6x .common_list_head__3Dy0F{
	height:80px;
	background:#f8f8f8;
	border:1px solid #e6e6e6;
	border-top:2px solid #333333;
}
.common_pay_info_list_wrap__35I6x .common_list_head__3Dy0F span{
	color:#666666;
	font-size:15px;
	text-align:center;
}
.common_pay_info_list_wrap__35I6x li.common_list_head__3Dy0F div{
	height:100%;
	width:170px;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
}
.common_pay_info_list_wrap__35I6x li.common_list_head__3Dy0F div:nth-child(2){
	width:196px;
}
.common_pay_info_list_wrap__35I6x li.common_list_head__3Dy0F div:nth-child(3){
	width:170px;
}
.common_pay_info_list_wrap__35I6x li.common_list_head__3Dy0F div:nth-child(4){
	width:186px;
}
.common_pay_info_list_wrap__35I6x li.common_list_head__3Dy0F div:nth-child(5){
	width:156px; 
}
.common_pay_info_list_wrap__35I6x li.common_list_head__3Dy0F div:nth-child(6){
	width:200px;
}
.common_pay_info_list_wrap__35I6x .common_list__1inqC{
	border-bottom:1px solid #e6e6e6;
	height:106px;
}
.common_pay_info_list_wrap__35I6x li.common_list__1inqC li{
	height:100%;
	width:170px;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
}
.common_pay_info_list_wrap__35I6x ul.common_top_area__1Ir0S{
	display:flex;
}
.common_pay_info_list_wrap__35I6x ul.common_bottom_area__16Bx2{
	display:flex;
}
.common_pay_info_list_wrap__35I6x li.common_list__1inqC .common_top_area__1Ir0S li:nth-child(2){
	width:196px;
}
.common_pay_info_list_wrap__35I6x li.common_list__1inqC .common_bottom_area__16Bx2 li:nth-child(1){
	width:170px;
}
.common_pay_info_list_wrap__35I6x li.common_list__1inqC .common_bottom_area__16Bx2 li:nth-child(2){
	width:186px;
}
.common_pay_info_list_wrap__35I6x li.common_list__1inqC .common_bottom_area__16Bx2 li:nth-child(3){
	width:156px; 
}
.common_pay_info_list_wrap__35I6x li.common_list__1inqC .common_bottom_area__16Bx2 li:nth-child(4){
	width:200px;
	flex-direction:column;
}
.common_pay_info_list_wrap__35I6x .common_list__1inqC .common_date__U96yZ{
	color:#b3b3b3;
	font-size:15px;
	font-family: 'S-CoreDream-3Light';
	text-align:center;
}
.common_pay_info_list_wrap__35I6x .common_list__1inqC .common_name__3XKX4{
	color:#4d4d4d;
	font-size:15px;
	text-align:center;
	-webkit-line-clamp:2;
	white-space:normal;
	display:-webkit-box;
	-webkit-box-orient:vertical;
}
.common_pay_info_list_wrap__35I6x .common_list__1inqC .common_student_name__2I8pt{
	color:#b3b3b3;
	font-size:15px;
	font-family: 'S-CoreDream-3Light';
	text-align:center;
}
.common_pay_info_list_wrap__35I6x .common_list__1inqC .common_fee__NdZlb{
	color:#b3b3b3;
	font-size:15px;
	font-family: 'S-CoreDream-3Light';
	text-align:center;
}
.common_pay_info_list_wrap__35I6x .common_list__1inqC .common_pay_condition__2EJZF{
	color:#f15a24;
	font-size:15px;
	text-align:center;
}
.common_pay_info_list_wrap__35I6x .common_list__1inqC a{
	color:#808080;
	font-size:13px;
	width:147px;
	height:35px;
	border-radius:17px;
	box-sizing:border-box;
	border:1px solid #b3b3b3;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
	text-align:center;
	margin:4px 0;
}
.common_mypage_pay_info__Oxy1- .common_pay_info_list_pagebtn_area__bifGV{
	width:100%;
	height:34px;
	margin-top:40px;
	display:-webkit-box;
	-webkit-box-pack:center;
	display:flex;
	justify-content:center;
}
.common_pay_info_list_pagebtn_area__bifGV li{
	height:100%;
	width:auto;
	margin:0 10px;
}
.common_pay_info_list_pagebtn_area__bifGV .common_first__2Q_TQ{
	width:auto;
}
.common_pay_info_list_pagebtn_area__bifGV .common_prev__1Myue{
	width:auto;
}
.common_pay_info_list_pagebtn_area__bifGV .common_next__3pQ4G{
	width:auto;
}
.common_pay_info_list_pagebtn_area__bifGV .common_last__2RzKs{
	width:auto;
}
.common_pay_info_list_pagebtn_area__bifGV li.common_arrow_btn__2SjZb img{
	height:15px;
	width:auto;
}
.common_pay_info_list_pagebtn_area__bifGV li a{
	height:100%;
	width:auto;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	justify-content:center;
	align-items:center;
	font-size:15px;
	font-family: 'S-CoreDream-3Light';
	color:#808080;
}
.common_pay_info_list_pagebtn_area__bifGV li a:hover{
	color:#009cc6;
}
.common_pay_info_list_pagebtn_area__bifGV li.common_on__2QUU7 a{
	color:#009cc6;
	font-family: 'S-CoreDream-6Bold';
}

/*mypage_pay_info_more*/
.common_mypage_pay_info_more__JCcY_ .common_my_tit__38b1N{
	margin-top:124px;
}
.common_mypage_pay_info_more__JCcY_ .common_ellip__2foBj{
	width:90%;
	display:block;
	overflow:hidden;
	text-overflow:ellipsis;
	white-space:nowrap;
	word-break:normal;
}
.common_mypage_pay_info_more__JCcY_ .common_pay_info_more_area__2JMyP{
	width:1078px;
	height:auto;
	margin:86px auto;
	margin-bottom:160px;
	position:relative;
	box-sizing:border-box;
	border-top:2px solid #333333;
	display:-webkit-box;
	-webkit-box-pack:center;
	display:flex;
	flex-direction:column;
	align-items:center;
}
.common_pay_info_more_area__2JMyP ul{
	width:810px;
	height:auto;
	position:relative;
}
.common_pay_info_more_area__2JMyP li{
	width:100%;
	height:100px;
	display:flex;
	box-sizing:border-box;
	border-bottom:1px solid #e6e6e6;
}
.common_pay_info_more_area__2JMyP li:nth-child(1){
	height:82px;
	border:none;
}
.common_pay_info_more_area__2JMyP li:nth-child(2){
	height:86px;
}
.common_pay_info_more_area__2JMyP .common_head__3zcNA, 
.common_pay_info_more_area__2JMyP p{
	height:100%;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:start;
	display:flex;
	align-items:center;
	justify-content:flex-start;
	font-size:15px;
	text-align:left;
	box-sizing:border-box;
}
.common_pay_info_more_area__2JMyP .common_head__3zcNA{
	width:326px;
	color:#666666;
	padding-left:149px;
}
.common_pay_info_more_area__2JMyP p{
	width:484px;
	color:#b3b3b3;
}
.common_pay_info_more_area__2JMyP li:nth-child(1) .common_head__3zcNA, 
.common_pay_info_more_area__2JMyP li:nth-child(1) p{
	align-items:flex-end;
	padding-bottom:15px;
}
.common_pay_info_more_area__2JMyP li:nth-child(2) .common_head__3zcNA, 
.common_pay_info_more_area__2JMyP li:nth-child(2) p{
	align-items:flex-start;
	padding-top:26px;
}
.common_pay_info_more_area__2JMyP li:nth-child(2) .common_head__3zcNA{
	color:#ff931e;
}
.common_pay_info_more_area__2JMyP li:nth-child(2) p{
	color:#ff931e;
}
.common_pay_info_more_area__2JMyP li.common_pay_more_product_info__3nJqF{
	height:auto;
	min-height:100px;
	padding:10px 0;
}
.common_pay_info_more_area__2JMyP li.common_pay_more_product_info__3nJqF span{
	height:inherit;
}
.common_pay_info_more_area__2JMyP li.common_pay_more_product_info__3nJqF p{
	height:auto;
}
.common_pay_info_more_area__2JMyP li.common_pay_more_total__318ju p{
	color:#4d4d4d;
	font-size:16px;
	font-family: 'S-CoreDream-6Bold';
}
.common_pay_info_more_area__2JMyP li.common_pay_more_refund__YnLs9 p{
	color:#f16817;
	font-size:16px;
	font-family: 'S-CoreDream-6Bold';
}
.common_pay_info_more_area__2JMyP a{
	width:150px;
	height:50px;
	border-radius:25px;
	background:#f16817;
	font-size:16px;
	color:#ffffff;
	margin-top:100px;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
}
.common_pay_info_more_area__2JMyP li a{
	width:100%;
	height:auto;
	background:none;
	color:#009cc6;
	font-size:15px;
	margin-top:0;
	justify-content:flex-start;
}

/*mypage_student_register*/
.common_mypage_student_register__28b__ .common_my_tit__38b1N{
	margin-top:124px;
}
.common_mypage_student_register__28b__ .common_ellip__2foBj{
	width:90%;
	display:block;
	overflow:hidden;
	text-overflow:ellipsis;
	white-space:nowrap;
	word-break:normal;
}
.common_mypage_student_register__28b__ .common_student_register_area__2_Hpz{
	width:1060px;
	height:auto;
	margin:86px auto;
	margin-bottom:160px;
	position:relative;
	box-sizing:border-box;
	border-top:2px solid #333333;
	display:-webkit-box;
	-webkit-box-pack:center;
	display:flex;
	flex-direction:column;
	align-items:center;
}
.common_student_register_area__2_Hpz ul{
	width:100%;
	height:auto;
	position:relative;
}
.common_student_register_area__2_Hpz li{
	width:100%;
	height:128px;
	box-sizing:border-box;
	display:flex;
}
.common_student_register_area__2_Hpz .common_gender__3ZpN_{
	width:100%;
}
.common_student_register_area__2_Hpz li.common_product__1NS8n{
	height:205px;
	border-bottom:1px solid #E6E6E6;
}
.common_student_register_area__2_Hpz li.common_overlap__1sBm2{
	height:144px;
}
.common_student_register_area__2_Hpz li:last-child{
	height:170px;
	border-bottom:1px solid #E6E6E6;
}
.common_student_register_area__2_Hpz li .common_head__3zcNA{
	height:100%;
	width:396px;
	font-size:15px;
	color:#666666;
	text-align:left;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:start;
	display:flex;
	align-items:center;
	justify-content:flex-start;
	box-sizing:border-box;
	padding-left:218px;
}
.common_student_register_area__2_Hpz .common_right_area__3060r{
	height:100%;
	width:664px;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:start;
	display:flex;
	align-items:center;
	justify-content:flex-start;
	position:relative;
}
.common_student_register_area__2_Hpz li.common_overlap__1sBm2 .common_right_area__3060r::before{
	content:'중복된 아이디입니다';
	display:block;
	color:#F15A24;
	font-size:13px;
	font-family:'S-CoreDream-3Light';
	position:absolute;
	top:75%;
	left:10px;
}
.common_student_register_area__2_Hpz li.common_overlapno__35iFp .common_right_area__3060r::before{
	content:'사용 가능한 아이디입니다';
	display:block;
	color:#009cc6;
	font-size:13px;
	font-family:'S-CoreDream-3Light';
	position:absolute;
	top:75%;
	left:10px;
}
.common_student_register_area__2_Hpz li.common_product__1NS8n .common_right_area__3060r{
	-webkit-box-align:start;
	-webkit-box-pack:center;
	flex-direction:column;
	align-items:flex-start;
	justify-content:center;
}
.common_student_register_area__2_Hpz li.common_type1__s-Qmv .common_right_area__3060r{
	-webkit-box-align:start;
	-webkit-box-pack:center;
	flex-direction:column;
	align-items:flex-start;
	justify-content:center;
}
.common_student_register_area__2_Hpz p.common_info__3UlbF{
	font-size:12px;
	color:#b3b3b3;
	text-align:left;
	font-family: 'S-CoreDream-3Light';
	margin-top:12px;
}
.common_student_register_area__2_Hpz select{
	width:203px;
	height:55px;
	border-radius:27px;
	box-sizing:border-box;
	border:1px solid #e6e6e6;
	padding:15px 34px;
	-webkit-appearance:none;
	-moz-appearance:none;
	appearance:none; 
	background:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAICAYAAAAiJnXPAAAACXBIWXMAAAsSAAALEgHS3X78AAAAiElEQVQYlY2PgQmDMBAATxfQUTpCF/inG+gIdqN2hP4G3UA3cIQ6wZeXCMGUkoOHkHD8pRGRHpioZ23cHVV9AEOFtgHXNk5mNgKvCmkys7nNLkJc/gh3M4si9rwDVY3/rUB3Ep6pZiffFJmfaE7tB+9cKKQkzik1iNxbERp5v0ZERhG5FG/ufAFPWUpOP5pyIAAAAABJRU5ErkJggg==) no-repeat 90% center;
	color:#B3B3B3;
	margin-right:30px;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:start;
	display:flex;
	align-items:center;
	justify-content:flex-start;
}
/*셀렉트박스(제작)*/
.common_student_register_area__2_Hpz .common_product_list_select_area__fxs_A{
	width:295px;
	height:52px;
	position:relative;
	display:-webkit-box;
	-webkit-box-align:center;
	display:flex;
	flex-direction:column;
	align-items:center;
}
.common_product_list_select_area__fxs_A .common_select_head__39mjD{
	width:100%;
	height:100%;
	border-radius:27px;
	box-sizing:border-box;
	border:1px solid #e6e6e6;
	padding:17px 40px;
	background:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAICAYAAAAiJnXPAAAACXBIWXMAAAsSAAALEgHS3X78AAAAiElEQVQYlY2PgQmDMBAATxfQUTpCF/inG+gIdqN2hP4G3UA3cIQ6wZeXCMGUkoOHkHD8pRGRHpioZ23cHVV9AEOFtgHXNk5mNgKvCmkys7nNLkJc/gh3M4si9rwDVY3/rUB3Ep6pZiffFJmfaE7tB+9cKKQkzik1iNxbERp5v0ZERhG5FG/ufAFPWUpOP5pyIAAAAABJRU5ErkJggg==) no-repeat 93% center;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:start;
	display:flex;
	align-items:center;
	justify-content:flex-start;
	cursor:pointer;
}
.common_product_list_select_area__fxs_A .common_selected_option__3uzwW{
	display:block;
	display:flex;
	white-space:nowrap;
	overflow:hidden;
}
.common_product_list_select_area__fxs_A .common_selected_option__3uzwW .common_p_name__sX0-u{
	display:block;
	font-size:16px;
	color:#F15A24;
	white-space:nowrap;
	overflow:hidden;
}
.common_product_list_select_area__fxs_A .common_selected_option__3uzwW .common_name__3XKX4{
	margin-right:4px;
	font-size:16px;
	color:#666666;
	display:none;
}
.common_product_list_select_area__fxs_A ul{
	width:auto;
	min-width:100%;
	height:auto;
	background:#ffffff;
	box-sizing:border-box;
	border:1px solid #e6e6e6;
	position:absolute;
	top:52px;
	z-index:10;
	display:none;
	overflow:hidden;
	box-shadow: 0px 0px 15px -3px rgba(0, 0, 0, .5);
}
.common_product_list_select_area__fxs_A li.common_select_list__1_Qkr{
	width:auto;
	height:40px;
	box-sizing:border-box;
	font-size:16px;
	text-align:center;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
	cursor:pointer;
	white-space:nowrap;
	padding:0 10px;
	color:#666666;
	margin-right:4px;
}
.common_product_list_select_area__fxs_A li.common_select_list__1_Qkr:first-child{
	border-bottom:none;
}

.common_selectbox_wrap__LrSmg li.common_select_list__1_Qkr .common_name__3XKX4{
	font-size:16px;
	color:#F15A24;
}
.common_selectbox_wrap__LrSmg li.common_select_list__1_Qkr.common_on__2QUU7{
	background:#1c85ff;
	color:#ffffff;
}
.common_selectbox_wrap__LrSmg li.common_select_list__1_Qkr.common_on__2QUU7 .common_name__3XKX4{
	color:#ffffff;
}
.common_selectbox_wrap__LrSmg li.common_select_list__1_Qkr:hover{
	background:#1c85ff;
	color:#ffffff;
}
.common_selectbox_wrap__LrSmg li.common_select_list__1_Qkr:hover .common_name__3XKX4{
	color:#ffffff;
}


.common_student_register_area__2_Hpz input{
	width:295px;
	height:55px;
	border-radius:27px;
	box-sizing:border-box;
	border:1px solid #e6e6e6;
	padding:18px 34px;
	background:none;
}
.common_student_register_area__2_Hpz li.common_overlap__1sBm2 .common_right_area__3060r input{
	border:1px solid #F15A24;
}
.common_student_register_area__2_Hpz input::-webkit-input-placeholder{
	color:#e6e6e6;
}
.common_student_register_area__2_Hpz input:-ms-input-placeholder{
	color:#e6e6e6;
}
.common_student_register_area__2_Hpz input::placeholder{
	color:#e6e6e6;
}
.common_student_register_area__2_Hpz li button{
	width:104px;
	height:35px;
	box-sizing:border-box;
	border:1px solid #b3b3b3;
	background:#ffffff;
	border-radius:17px;
	font-size:13px;
	color:#808080;
	cursor:pointer;
	margin-left:10px;
}
.common_student_register_area__2_Hpz .common_radio_btn_area__3nYLt{
	display:-webkit-box;
	-webkit-box-align:center;
	display:flex;
	align-items:center;
	width:auto;
}
.common_student_register_area__2_Hpz .common_radio_btn_area__3nYLt span{
	margin-right:56px;
	font-size:15px;
	color:#666666;
}
.common_student_register_area__2_Hpz .common_radio_btn_area__3nYLt input{
	width:30px;
	height:30px;
	margin-right:8px;
	-webkit-appearance:none;
	-moz-appearance:none;
	     appearance:none;
	border-color:#e6e6e6;
	padding:0;
}
.common_student_register_area__2_Hpz .common_radio_btn_area__3nYLt input:checked{
	-webkit-appearance:none;
	-moz-appearance:none;
	     appearance:none;
	background:#ff6e00;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
}
.common_student_register_area__2_Hpz .common_radio_btn_area__3nYLt input:checked::after{
	content:'';
	display:block;
	width:17px;
	height:17px;
	border-radius:50%;
	background:#ffffff;
}
.common_student_register_area__2_Hpz .common_btn_area__3WMBi{
	margin-top:90px;
	width:100%;
	height:50px;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
	position:relative;
}
.common_student_register_area__2_Hpz .common_btn_area__3WMBi a{
	width:150px;
	height:100%;
	border-radius:25px;
	background:#cccccc;
	font-size:16px;
	color:#ffffff;
	border:none;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
}
.common_student_register_area__2_Hpz .common_btn_area__3WMBi button{
	background:#f16817;
	margin-left:20px;
	width:150px;
	height:100%;
	border-radius:25px;
	font-size:16px;
	color:#ffffff;
	border:none;
	cursor:pointer;
}
.common_student_register_area__2_Hpz select{
	width:203px;
	height:55px;
	border-radius:27px;
	box-sizing:border-box;
	border:1px solid #e6e6e6;
	padding:15px 34px;
	-webkit-appearance:none;
	-moz-appearance:none;
	appearance:none; 
	background:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAICAYAAAAiJnXPAAAACXBIWXMAAAsSAAALEgHS3X78AAAAiElEQVQYlY2PgQmDMBAATxfQUTpCF/inG+gIdqN2hP4G3UA3cIQ6wZeXCMGUkoOHkHD8pRGRHpioZ23cHVV9AEOFtgHXNk5mNgKvCmkys7nNLkJc/gh3M4si9rwDVY3/rUB3Ep6pZiffFJmfaE7tB+9cKKQkzik1iNxbERp5v0ZERhG5FG/ufAFPWUpOP5pyIAAAAABJRU5ErkJggg==) no-repeat 90% center;
	color:#B3B3B3;
	margin-right:30px;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:start;
	display:flex;
	align-items:center;
	justify-content:flex-start;
	font-size:16px;
}

/* 간편 로그인시 학습자 등록하는 페이지 */
.common_add_student_area__8MqHN .common_student_register_area__2_Hpz li:first-child{
	border-bottom:none;
}
.common_add_student_area__8MqHN .common_student_register_area__2_Hpz .common_btn_area__3WMBi button{
	width:150px;
	height:100%;
	border-radius:25px;
	background:#cccccc;
	font-size:16px;
	color:#ffffff;
	border:none;
	cursor:pointer;
}
.common_add_student_area__8MqHN .common_student_register_area__2_Hpz .common_btn_area__3WMBi button:last-child{
	background:#f16817;
	margin-left:20px;
	width:150px;
	height:100%;
	border-radius:25px;
	font-size:16px;
	color:#ffffff;
	border:none;
}

/*mypage_student_infofix*/
.common_mypage_student_infofix__1H8xR .common_student_register_area__2_Hpz ul{
	border-bottom:1px solid #e6e6e6;
	box-sizing:border-box;
}
.common_mypage_student_infofix__1H8xR .common_student_register_area__2_Hpz li{
	height:128px;
}
.common_mypage_student_infofix__1H8xR .common_student_register_area__2_Hpz li:last-child{
	border:none;
	margin-bottom:20px;
}
.common_mypage_student_infofix__1H8xR .common_student_register_area__2_Hpz li.common_infofix_vertical__3wIUi .common_right_area__3060r{
	-webkit-box-align:start;
	-webkit-box-pack:center;
	flex-direction:column;
	align-items:flex-start;
	justify-content:center;
}
.common_mypage_student_infofix__1H8xR .common_student_register_area__2_Hpz li.common_infofix_normal__3eooc .common_right_area__3060r span{
	color:#666666;
	font-size:15px;
	padding-left:34px;
}
.common_mypage_student_infofix__1H8xR .common_student_register_area__2_Hpz li.common_infofix_normal__3eooc.common_infofix_vertical__3wIUi .common_right_area__3060r span:last-child{
	color:#b3b3b3;
	margin-top:6px;
}
.common_mypage_student_infofix__1H8xR .common_student_register_area__2_Hpz li.common_type1__s-Qmv .common_right_area__3060r{
	-webkit-box-align:start;
	-webkit-box-pack:center;
	flex-direction:column;
	align-items:flex-start;
	justify-content:center;
}

.common_student_register_area__2_Hpz .common_month_list_select_area__2y-Kx{
	width:203px;
	height:55px;
	position:relative;
	display:-webkit-box;
	-webkit-box-align:center;
	display:flex;
	flex-direction:column;
	align-items:center;
}
.common_month_list_select_area__2y-Kx .common_select_head__39mjD{
	width:100%;
	height:100%;
	border-radius:27px;
	box-sizing:border-box;
	border:1px solid #e6e6e6;
	padding:15px 34px;
	background:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAICAYAAAAiJnXPAAAACXBIWXMAAAsSAAALEgHS3X78AAAAiElEQVQYlY2PgQmDMBAATxfQUTpCF/inG+gIdqN2hP4G3UA3cIQ6wZeXCMGUkoOHkHD8pRGRHpioZ23cHVV9AEOFtgHXNk5mNgKvCmkys7nNLkJc/gh3M4si9rwDVY3/rUB3Ep6pZiffFJmfaE7tB+9cKKQkzik1iNxbERp5v0ZERhG5FG/ufAFPWUpOP5pyIAAAAABJRU5ErkJggg==) no-repeat 93% center;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:start;
	display:flex;
	align-items:center;
	justify-content:flex-start;
	cursor:pointer;
}
.common_month_list_select_area__2y-Kx .common_selected_option__3uzwW{
	display:block;
	font-size:16px;
	color:#B3B3B3;
	margin-right:4px;
	display:flex;
}
.common_month_list_select_area__2y-Kx ul{
	width:auto;
	min-width:100%;
	height:auto;
	background:#ffffff;
	box-sizing:border-box;
	border:1px solid #e6e6e6;
	position:absolute;
	top:52px;
	z-index:10;
	display:none;
	overflow:hidden;
	box-shadow: 0px 0px 15px -3px rgba(0, 0, 0, .5);
}
.common_month_list_select_area__2y-Kx li.common_select_list__1_Qkr{
	width:auto;
	height:40px;
	box-sizing:border-box;
	font-size:16px;
	text-align:center;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
	cursor:pointer;
	white-space:nowrap;
	padding:0 10px;
	color:#666666;
}
.common_month_list_select_area__2y-Kx li.common_select_list__1_Qkr:first-child{
	margin-top:0;
	border-bottom:none;
}
.common_month_list_select_area__2y-Kx li.common_select_list__1_Qkr:last-child{
	margin-bottom:0;
}

/*download.html*/
.common_download_area__1X_we{
	width:100%;
	height:auto;
	position:relative;
	background:#fff9f0;
	padding-bottom:95px;
}
.common_download_top_line_banner__2DVEy{
	width:100%;
	height:85px;
	position:relative;
	background:#1E83D6;
}
.common_download_top_line_banner__2DVEy .common_inner__1cU6t{
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
}
.common_download_top_line_banner__2DVEy span{
	font-size:32px;
	color:#ffffff;
	font-family: 'S-CoreDream-6Bold';
}
.common_download_area__1X_we .common_download_wrap__3LJhK{
	width:1112px; 
	height:auto;
	position:relative;
	box-sizing:border-box;
	margin:95px auto;
	border:2px solid #edd0a6;
	padding:56px 65px;
	padding-top:70px;
	border-radius:94px;
}
.common_download_wrap__3LJhK .common_top_top_area__3ryUB{
	width:100%;
	height:auto;
	display:-webkit-box;
	-webkit-box-pack:justify;
	-webkit-box-align:start;
	display:flex;
	justify-content:space-between;
	align-items:flex-start;
	position:relative;
}
.common_download_wrap__3LJhK .common_left_area__2Nwxl{
	width:500px;
	height:auto;
	display:-webkit-box;
	-webkit-box-pack:start;
	display:flex;
	flex-direction:column;
	justify-content:flex-start;
}
.common_download_wrap__3LJhK .common_left_area__2Nwxl img{
	width:323px;
	height:auto;
}
.common_download_wrap__3LJhK .common_left_area__2Nwxl .common_download_title_wrap__2VDLX{
	width:384px;
	position:relative;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	flex-direction:column;
	align-items:center;
	justify-content:center;
}
.common_download_wrap__3LJhK .common_download_title_wrap__2VDLX span{
	color:#f9be2d;
	font-family:'S-CoreDream-6Bold';
	font-size:17px;
}
.common_download_wrap__3LJhK .common_download_title_wrap__2VDLX img{
	width:100%;
	margin-top:8px;
}
.common_download_wrap__3LJhK .common_left_area__2Nwxl p{
	font-size:36px;
	text-align:left;
	margin-top:35px;
}
.common_download_wrap__3LJhK .common_left_area__2Nwxl p.common_sub_title__hQAyC{
	color:#666666;
	font-size:24px;
	font-family: 'S-CoreDream-6Bold';
}
.common_download_wrap__3LJhK .common_down_link_area__FxAux{
	width:400px;
	height:38px;
	margin-top:47px;
	display:-webkit-box;
	-webkit-box-pack:center;
	display:flex;
	justify-content:center;
}
.common_download_wrap__3LJhK .common_down_link_area__FxAux a{
	width:180px;
}
.common_download_wrap__3LJhK .common_down_link_area__FxAux a:first-child{
	margin-right:25px;
}
.common_download_wrap__3LJhK .common_down_link_area__FxAux img{
	width:100%;
}	
.common_download_wrap__3LJhK .common_right_area__3060r{
	width:465px;
	height:auto;
	display:-webkit-box;
	-webkit-box-pack:center;
	display:flex;
	flex-direction:column;
	justify-content:center;
}
.common_download_wrap__3LJhK .common_right_area__3060r .common_top_area__1Ir0S{
	width:100%;
	height:322px;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
}
.common_download_wrap__3LJhK .common_right_area__3060r .common_top_area__1Ir0S img{
	height:100%;
	width:auto;
}
.common_download_wrap__3LJhK .common_right_area__3060r .common_bottom_area__16Bx2{
	width:100%;
	height:auto;
	margin-top:18px;
	display:-webkit-box;
	-webkit-box-align:center;
	display:flex;
	align-items:center;
	flex-wrap:wrap;
}
.common_download_wrap__3LJhK .common_right_area__3060r .common_bottom_area__16Bx2 img{
	height:101px;
	width:auto;
	margin-top:22px;
}
.common_download_wrap__3LJhK .common_right_area__3060r .common_bottom_area__16Bx2 img:nth-child(2){
	margin:0 13px;
	margin-top:22px;
}
.common_download_wrap__3LJhK .common_right_area__3060r .common_bottom_area__16Bx2 img:nth-child(5){
	margin:0 13px;
	margin-top:22px;
}
.common_download_wrap__3LJhK .common_btn_area__3WMBi{
	width:100%;
	height:133px;
	position:relative;
	margin-top:160px;
	margin-bottom:37px;
}
.common_download_wrap__3LJhK .common_btn_area__3WMBi button{
	height:100%;
	width:688px;
	border-radius:38px;
	box-sizing:border-box;
	margin:0 auto;
	background:#007cff;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
	font-size:42px;
	color:#ffffff;
	text-align:center;
	border:none;
	cursor:pointer;
}

/*pay.html*/
.common_pay_add_info_popup__EoO4H{
	display:none;
	width:100%;
	height:100%;
	position:fixed;
	top:0;
	left:0;
	z-index:140;
}
.common_pay_add_info_popup__EoO4H div{
	width:300px;
	height:230px;
	background:#ffffff;
	display:block;
	position:absolute;
	top:50%;
	left:50%;
	transform:translate(-50%,-50%);
	box-sizing:border-box;
	padding:30px;
	border-radius:30px;
}
.common_pay_add_info_popup__EoO4H p{
	font-size:18px;
	text-align:center;
	margin-bottom:20px;
	position:relative;
	z-index:141;
}
.common_pay_add_info_popup__EoO4H #common_pay_add_phonenumber__2Bzon{
	width:100%;
	height:50px;
	border:1px solid #e6e6e6;
	border-radius:30px;
	font-size:15px;
	box-sizing:border-box;
	padding:10px;
}
.common_pay_add_info_popup__EoO4H div.common_btn_area__3WMBi{
	width:100%;
	height:36px;
	position:relative;
	top:0;
	left:0;
	padding:0;
	background:none;
	transform:translate(0,0);
	display:-webkit-box;
	-webkit-box-align:Center;
	-webkit-box-pack:Center;
	display:flex;
	align-items:center;
	justify-content:center;
	margin-top:30px;
}
.common_pay_add_info_popup__EoO4H .common_confirm__3QYm7{
	width:40%;
	height:100%;
	position:relative;
	border:none;
	background:#009cc6;
	color:#ffffff;
	border-radius:30px;
	cursor:pointer;
}
.common_pay_add_info_popup__EoO4H .common_cancle__H1W7T{
	width:40%;
	height:100%;
	position:relative;
	border:1px solid #009cc6;
	background:#ffffff;
	color:#009cc6;
	border-radius:30px;
	margin-left:20px;
	cursor:pointer;
}

.common_pay_area__2p8aD{
	width:100%;
	height:auto;
	min-height:1000px;
	position:relative;
	background:#fff9f0;
}
.common_pay_top_line_banner__1FbHr{
	width:100%;
	height:85px;
	position:relative;
	background:#1E83D6;
}
.common_pay_top_line_banner__1FbHr .common_inner__1cU6t{
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
}
.common_pay_top_line_banner__1FbHr span{
	font-size:32px;
	color:#ffffff;
	font-family: 'S-CoreDream-6Bold';
}
.common_pay_area__2p8aD .common_pay_wrap__3jp2v{
	width:100%; 
	height:auto;
	position:relative;
	background:#fff9f0;
}
.common_pay_wrap__3jp2v .common_product_area__19QlX{
	width:1023px;
	height:auto;
	position:relative;
	box-sizing:border-box;
	border-radius:50px;
	padding:38px 60px;
	padding-top:30px;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	flex-direction:column;
	align-items:center;
	justify-content:center;
	border:2px solid #edd0a6;
	margin:0 auto;
	margin-top:83px;
} 
.common_pay_wrap__3jp2v .common_product_area__19QlX .common_top_top_area__3ryUB{
	width:100%;
	height:84px;
	display:-webkit-box;
	-webkit-box-pack:center;
	-webkit-box-align:center;
	display:flex;
	justify-content:center;
	align-items:center;
	box-sizing:border-box;
	border-bottom:1px solid #edd0a6;
	padding-bottom:30px;
}
.common_product_area__19QlX .common_top_top_area__3ryUB img{
	height:47px;
	width:auto;
	margin-right:12px;
}
.common_product_area__19QlX .common_top_top_area__3ryUB p{
	color:#4D4D4D;
	font-size:16px;
	font-family: 'S-CoreDream-3Light';
}
.common_pay_wrap__3jp2v .common_product_area__19QlX .common_title__1txLf{
	text-align:center;
	color:#F15A24;
	font-size:28px;
	font-family: 'S-CoreDream-6Bold';
	margin:26px 0;
}
.common_pay_wrap__3jp2v .common_product_area__19QlX .common_title__1txLf br{
	display:none;
}
.common_pay_wrap__3jp2v .common_product_area__19QlX ul{
	width:100%;
	height:auto;
	position:relative;
	display:-webkit-box;
	-webkit-box-pack:center;
	display:flex;
	justify-content:center;
}
.common_pay_wrap__3jp2v .common_product_area__19QlX li{
	width:286px;
	height:auto;
	box-sizing:border-box;
	padding:22px 20px;
	padding-top:12px;
	margin:0 15px;
	border-radius:22px;
	border:2px solid #999999;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
	flex-direction:column;
	cursor:pointer;
}
.common_pay_wrap__3jp2v .common_product_area__19QlX li .common_scratch__1OsJ7{
	width:189px;
	height:15px;
	border-radius:7px;
	background:#999999;
}
.common_pay_wrap__3jp2v .common_product_area__19QlX li.common_on__2QUU7{
	border:2px solid #0060ff;
}
.common_pay_wrap__3jp2v .common_product_area__19QlX li.common_on__2QUU7 .common_scratch__1OsJ7{
	background:#0060FF;
}
.common_pay_wrap__3jp2v .common_product_name__c4yj2{
	width:100%;
	height:auto;
	margin-top:32px;
	padding-bottom:29px;
	display:-webkit-box;
	-webkit-box-pack:center;
	-webkit-box-align:end;
	display:flex;
	justify-content:center;
	align-items:flex-end;
	box-sizing:border-box;
	border-bottom:1px solid #cccccc;
}
.common_pay_wrap__3jp2v .common_product_name__c4yj2 p{
	color:#999999;
	font-size:16px;
	font-family: 'S-CoreDream-6Bold';
	text-align:left;
	padding-bottom:2px;
	line-height:1;
}
.common_pay_wrap__3jp2v .common_product_name__c4yj2 .common_period__ef7CG{
	font-size:26px;
	font-family: 'S-CoreDream-6Bold';
	text-align:left;
	margin-right:10px;
	line-height:1;
}
.common_pay_wrap__3jp2v .common_product_price__18u_E{
	width:100%;
	height:auto;
	box-sizing:border-box;
	border-bottom:1px solid #cccccc;
	padding-top:40px;
	padding-bottom:27px;
	display:-webkit-box;
	-webkit-box-pack:center;
	-webkit-box-align:end;
	display:flex;
	justify-content:center;
	align-items:flex-end;
}
.common_pay_wrap__3jp2v .common_product_price__18u_E .common_left_area__2Nwxl{
	width:auto;
	height:auto;
	display:-webkit-box;
	-webkit-box-align:start;
	display:flex;
	align-items:flex-start;
	flex-direction:column;
	margin-right:24px;
}
.common_pay_wrap__3jp2v .common_product_price__18u_E p{
	font-size:20px;
	text-align:left;
	color:#999999;
	position:relative;
	margin-bottom:12px;
	line-height:1;
}
.common_pay_wrap__3jp2v .common_product_price__18u_E p::after{
	content:'';
	display:block;
	width:100%;
	height:1px;
	background:#999999;
	position:absolute;
	top:50%;
}
.common_pay_wrap__3jp2v .common_product_price__18u_E .common_price__208iG{
	font-size:28px;
	font-family: 'S-CoreDream-6Bold';
	text-align:left;
	color:#39b54a;
	line-height:1;
}
.common_pay_wrap__3jp2v .common_discount_area__383hr{
	width:62px;
	height:36px;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
	box-sizing:border-box;
	border-radius:6px;
	background:#00FF00;
	color:#1A1A1A;
	text-align:center;
}
.common_pay_wrap__3jp2v .common_student_count_area__38JPj{
	width:100%;
	height:auto;
	box-sizing:border-box;
	padding:37px 0;
	display:-webkit-box;
	-webkit-box-align:center;
	display:flex;
	flex-direction:column;
	align-items:center;
	border-bottom:1px solid #cccccc;
}
.common_pay_wrap__3jp2v .common_student_count_area__38JPj .common_count_area__QVG2P{
	width:174px;
	height:37px;
	box-sizing:border-box;
	border-radius:17px;
	padding:5px 18px;
	border:1px solid #999999;
	display:flex;
	margin-bottom:30px;
}
.common_pay_wrap__3jp2v .common_count_area__QVG2P input{
	width:50%;
	height:100%;
	box-sizing:border-box;
	border:none;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:Center;
	justify-content:Center;
	font-size:24px;
	color:#f15a24;
	background:none;
	text-align:center;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.common_pay_wrap__3jp2v .common_count_area__QVG2P input:focus{
	outline:none;
}
.common_pay_wrap__3jp2v .common_count_area__QVG2P span{
	width:50%;
	height:100%;
	box-sizing:border-box;
	border-left:1px solid #cccccc;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:Center;
	justify-content:Center;
	font-size:14px;
	color:#666666;
}
.common_pay_wrap__3jp2v .common_student_count_area__38JPj .common_count_btn_area__2E1BV{
	width:auto;
	height:52px;
	box-sizing:border-box;
	display:flex;
}
.common_pay_wrap__3jp2v .common_count_btn_area__2E1BV button{
	width:auto;
	height:100%;
	border-radius:50%;
	background:none;
	border:none;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
	cursor:pointer;
}
.common_pay_wrap__3jp2v .common_count_btn_area__2E1BV button:last-child{
	margin-left:48px;
}
.common_pay_wrap__3jp2v .common_count_btn_area__2E1BV button img{
	width:auto;
	height:100%;
}
.common_pay_wrap__3jp2v .common_product_total_price_area__3WcMj{
	width:100%;
	height:75px;
	display:-webkit-box;
	-webkit-box-align:Center;
	-webkit-box-pack:Center;
	display:flex;
	align-items:center;
	justify-content:center;
}
.common_pay_wrap__3jp2v .common_product_total_price_area__3WcMj span{ 
	margin-right:12px;
	line-height:1;
}
.common_pay_wrap__3jp2v .common_product_total_price_area__3WcMj .common_product_total_price__2vXW6{ 
	font-family: 'S-CoreDream-6Bold';
	margin-right:0;
	font-size:22px;
	display:-webkit-box;
	-webkit-box-align:end;
	display:flex;
	align-items:flex-end;
}
.common_pay_wrap__3jp2v .common_product_total_price_area__3WcMj .common_product_total_price__2vXW6::before{ 
	content:'₩';
	display:block;
	font-size:22px;
	font-family: 'S-CoreDream-6Bold';
	margin-right:4px;
}
.common_pay_wrap__3jp2v .common_btn_area__3WMBi{
	width:221px;
	height:65px;
	position:relative;
	margin:0 auto;
}
.common_pay_wrap__3jp2v .common_btn_area__3WMBi button{
	width:100%;
	height:100%;
	border-radius:16px;
	background:#007cff;
	color:#ffffff;
	font-size:22px;
	font-family: 'S-CoreDream-6Bold';
	border:none;
	cursor:pointer;
}

.common_pay_wrap__3jp2v .common_pay_guide__1EGI8{
	width:1060px;
	height:127px;
	position:relative;
	margin:0 auto;
	box-sizing:border-box;
	margin-top:23px;
	padding:0 63px;
	border-bottom:2px solid #edd0a6;
	display:-webkit-box;
	-webkit-align-content:start;
	display:flex;
	align-content:flex-start;
	flex-wrap:wrap;
}
.common_pay_wrap__3jp2v .common_pay_guide__1EGI8 li{
	color:#000000;
	font-size:12px;
	margin-right:30px;
	margin-top:10px;
	display:-webkit-box;
	-webkit-box-align:center;
	display:flex;
	align-items:center;
}
.common_pay_wrap__3jp2v .common_pay_guide__1EGI8 li br{
	display:none;
}
.common_pay_wrap__3jp2v .common_pay_guide__1EGI8 li::before{
	content:'';
	display:block;
	background:#000000;
	width:6px;
	height:6px;
	margin-right:4px;
	border-radius:50%;
}
.common_pay_wrap__3jp2v .common_pay_guide__1EGI8 li:nth-child(1) a{
	display:-webkit-box;
	-webkit-box-align:center;
	display:flex;
	align-items:center;
	position:relative;
}
.common_pay_wrap__3jp2v .common_pay_guide__1EGI8 li a::after{
	content:'';
	display:block;
	width:16px;
	height:16px;
	background:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAAqCAYAAADFw8lbAAAACXBIWXMAABYlAAAWJQFJUiTwAAABsElEQVRYhe2Z0U3DQAyGf6O8k0cekRiAbkBG6AawAYxQNqAbtBPQDQgbhFeeugBSNzCy5EOlyiXp+c4Fqb90atrE8ZfLne/sEjPDQ0RUA5glutp6g7YAbhPMny8KMPWKmXcAGgAfKfZuoDDCuoLCAOsOikTYH1AiaohoQ0R8ROt0klhgP6dcXynkHMBrikOjrgFcTX0y+djKIYCNGMtvpZvG1J36HWuLYMBqVJ8Y8isCvJAxGsZYp+OmqIhopoH/8sDPGsBNbIK5zvohSGZ+GIoGbqBjkOFLDNYFdCpkUB9scdBjIWOw1V+EDBJYWYjC9rDRENA6haBVyv2KvHprT/YpO2gJSOQGLQWJnKAlIZELtDQkcoB6QMIK6gUJC6gnJIw9OveCRObwZIKUN0REL7HzlrVe0pd3PZZN95PhXiKBvJMEk5nbw5PJoMy8knXbCDdZJ8nrU3QGza0zaG6dQXPrX4ImlQ8zatR/vZchNh5Fsp6Mtdlj6C3UVZo7LwE8AngjorWu416SGum9+lpGC3X6RLXWRqfUKku1wXz/1/9MuhmWfaanpAel+NFFnQL4Bh8pe9lhbRDOAAAAAElFTkSuQmCC) no-repeat center;
	background-size:cover;
	margin-left:8px;
}
.common_pay_wrap__3jp2v .common_pay_guide__1EGI8 li a::before{
	content:'';
	display:block;
	width:287px;
	height:1px;
	background:#333333;
	position:absolute;
	bottom:0;
}
.common_pay_wrap__3jp2v .common_pay_guide__1EGI8 li a{
	color:#000000;
	font-size:12px;
	text-align:left;
	text-decoration:none;
}
.common_pay_wrap__3jp2v .common_pay_product_info_top_line__1xvcW{
	width:100%;
	height:2px;
	background:#808080;
	position:relative;
	margin-top:160px;
	z-index:3;
}
.common_pay_wrap__3jp2v .common_pay_product_info_top_line__1xvcW::after{
	content:'야미코딩 상세 설명';
	width:334px;
	height:55px;
	box-sizing:border-box;
	background:#fff4e1;
	color:#666666;
	border-radius:27px;
	border:2px solid #808080;
	font-size:15px;
	text-align:center;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
	position:absolute;
	top:50%;
	left:50%;
	transform:translate(-50%,-50%);
}
.common_pay_wrap__3jp2v .common_pay_product_info_area__1w_Gx{
	width:100%;
	height:auto;
	background:#fff9f0 url(/static/media/pay_product_bg@2x.51d17efa.png) no-repeat top center;
	background-size:100% auto;
	position:relative;
	box-sizing:border-box;
	padding-top:41%;
	padding-bottom:200px;
}
.common_pay_wrap__3jp2v .common_pay_product_info_area__1w_Gx .common_inner__1cU6t{
	display:-webkit-box;
	-webkit-box-align:center;
	display:flex;
	flex-direction:column;
	align-items:center;
}
.common_pay_wrap__3jp2v .common_pay_product_title__3E5aW{
	text-align:center;
	font-size:30px;
	font-family: 'S-CoreDream-6Bold';
}
.common_pay_wrap__3jp2v .common_pay_product_3d_area__2Yc3K{
	width:100%;
	height:706px;
	position:relative;
	margin-top:28px;
	background:url(/static/media/pay_product_box_bg1.1862e0af.png) no-repeat center;
	background-size:auto 100%;
	padding-top:108px;
	padding-bottom:122px;
	box-sizing:border-box;
}
.common_pay_wrap__3jp2v .common_pay_product_top_area__1GTNJ{
	width:956px;
	height:auto;
	box-sizing:border-box;
	padding-left:104px;
	padding-right:33px;
	margin:0 auto;
}
.common_pay_wrap__3jp2v .common_pay_product_top_area__1GTNJ span{
	font-size:25px;
	color:#0457a2;
	font-family: 'S-CoreDream-7ExtraBold';
	text-align:left;
	position:relative;
	display:-webkit-box;
	-webkit-box-align:center;
	display:flex;
	align-items:center;
}
.common_pay_wrap__3jp2v .common_pay_product_top_area__1GTNJ span::before{
	content:'';
	display:block;
	width:12px;
	height:12px;
	background:#0457a2;
	border-radius:50%;
	margin-right:4px;
}
.common_pay_wrap__3jp2v .common_pay_product_top_area__1GTNJ p{
	color:#1f1715;
	text-align:left;
	padding-left:16px;
	box-sizing:border-box;
	margin-top:9px;
}
.common_pay_wrap__3jp2v .common_pay_product_3d_bottom_area__3nDMn{
	width:956px;
	height:auto;
	margin:0 auto;
	margin-top:26px;
	position:relative;
	display:-webkit-box;
	-webkit-box-align:start;
	display:flex;
	align-items:flex-start;
}
.common_pay_wrap__3jp2v .common_pay_product_3d_bottom_area__3nDMn .common_img_area__3KniS{
	width:266px;
	height:auto;
	position:relative;
}
.common_pay_wrap__3jp2v .common_pay_product_3d_bottom_area__3nDMn img{
	width:100%;
	height:auto;
}
.common_pay_wrap__3jp2v .common_pay_product_3d_bottom_area__3nDMn p{
	width:100%;
	height:auto;
	margin-top:12px;
	font-size:15px;
	color:#1f1715;
	font-family: 'S-CoreDream-3Light';
	text-align:left;
	position:relative;
	display:-webkit-box;
	-webkit-box-align:start;
	display:flex;
	align-items:flex-start;
}
.common_pay_wrap__3jp2v .common_pay_product_3d_bottom_area__3nDMn p::before{
	content:'';
	display:block;
	width:12px;
	height:12px;
	background:#999999;
	border-radius:50%;
	margin-right:8px;
	margin-top:7px;
}
.common_pay_wrap__3jp2v .common_pay_product_3d_bottom_area__3nDMn .common_img_area__3KniS:first-child{
	width:359px;
}
.common_pay_wrap__3jp2v .common_pay_product_3d_bottom_area__3nDMn .common_img_area__3KniS:first-child p{
	width:359px;
	margin-top:16px;
	padding-left:21px;
	box-sizing:border-box;
}
.common_pay_wrap__3jp2v .common_pay_product_3d_bottom_area__3nDMn .common_img_area__3KniS:nth-child(2){
	margin-left:37px; 
	margin-right:24px; 
}
.common_pay_wrap__3jp2v .common_pay_product_contents_area__1BR0F{
	width:100%;
	height:1116px;
	position:relative;
	top:-40px;
	background:url(/static/media/pay_product_box_bg2.10f1355a.png) no-repeat center;
	background-size:auto 100%;
	padding-top:156px;
	padding-bottom:82px;
	box-sizing:border-box;
}
.common_pay_wrap__3jp2v .common_pay_product_contents_bottom_area__vR26S{
	width:956px;
	height:auto;
	margin:0 auto;
	margin-top:19px;
	position:relative;
}
.common_pay_wrap__3jp2v .common_pay_product_contents_bottom_area__vR26S .common_top_area__1Ir0S{
	width:100%;
	height:auto;
	position:relative;
	display:-webkit-box;
	-webkit-box-align:start;
	display:flex;
	align-items:flex-start;
}
.common_pay_product_contents_bottom_area__vR26S .common_top_area__1Ir0S .common_img_area__3KniS{
	width:auto;
	height:auto;
	position:relative;
}
.common_pay_wrap__3jp2v .common_pay_product_contents_bottom_area__vR26S p br.common_mobile__3lWUV{
	display:none;
}
.common_pay_wrap__3jp2v .common_pay_product_contents_bottom_area__vR26S .common_top_area__1Ir0S .common_img_area__3KniS div{
	display:-webkit-box;
	-webkit-box-align:center;
	display:flex;
	align-items:center;
}
.common_pay_wrap__3jp2v .common_pay_product_contents_bottom_area__vR26S .common_top_area__1Ir0S img{
	width:360px;
	height:auto;
}
.common_pay_wrap__3jp2v .common_pay_product_contents_bottom_area__vR26S .common_top_area__1Ir0S img:nth-child(2){
	width:570px;
	margin-left:20px;
}
.common_pay_wrap__3jp2v .common_pay_product_contents_bottom_area__vR26S p{
	width:100%;
	height:auto;
	margin-top:12px;
	font-size:15px;
	color:#1f1715;
	font-family: 'S-CoreDream-3Light';
	text-align:left;
	position:relative;
	display:-webkit-box;
	-webkit-box-align:start;
	display:flex;
	align-items:flex-start;
	box-sizing:border-box;
	padding-left:21px;
}
.common_pay_wrap__3jp2v .common_pay_product_contents_bottom_area__vR26S p::before{
	content:'';
	display:block;
	width:12px;
	height:12px;
	background:#999999;
	border-radius:50%;
	margin-right:8px;
	margin-top:7px;
}
.common_pay_wrap__3jp2v .common_pay_product_contents_bottom_area__vR26S .common_bottom_area__16Bx2{
	width:100%;
	height:auto;
	position:relative;
	display:-webkit-box;
	-webkit-box-align:start;
	display:flex;
	align-items:flex-start;
	margin-top:32px;
}
.common_pay_product_contents_bottom_area__vR26S .common_bottom_area__16Bx2 .common_img_area__3KniS{
	width:318px;
	height:auto;
	position:relative;
}
.common_pay_product_contents_bottom_area__vR26S .common_bottom_area__16Bx2 .common_img_area__3KniS img{
	width:318px;
	height:auto;
	position:relative;
}
.common_pay_product_contents_bottom_area__vR26S .common_bottom_area__16Bx2 .common_img_area__3KniS:last-child{
	width:642px;
	margin-left:6px;
}
.common_pay_product_contents_bottom_area__vR26S .common_bottom_area__16Bx2 .common_img_area__3KniS:last-child div{
	width:100%;
	height:auto;
	display:-webkit-box;
	-webkit-box-align:start;
	display:flex;
	align-items:flex-start;
}
.common_pay_product_contents_bottom_area__vR26S .common_bottom_area__16Bx2 .common_img_area__3KniS:last-child img:last-child{
	margin-left:6px;
}
.common_pay_wrap__3jp2v .common_pay_product_etc_area__3BH1H{
	width:100%;
	height:742px;
	position:relative;
	top:-80px;
	background:url(/static/media/pay_product_box_bg3.975e6343.png) no-repeat center;
	background-size:auto 100%;
	padding-top:115px;
	padding-bottom:33px;
	box-sizing:border-box;
}
.common_pay_wrap__3jp2v .common_pay_product_etc_area__3BH1H .common_pay_product_top_area__1GTNJ{
	width:1052px;
	padding-left:78px;
}
.common_pay_wrap__3jp2v .common_pay_product_etc_area__3BH1H .common_pay_product_top_area__1GTNJ span.common_second__31pGC{
	margin-top:42px;
}
.common_pay_product_etc_area__3BH1H img{
	width:1052px;
	height:auto;
	margin:0 auto;
	display:block;
	position:relative;
	margin-top:31px;
}
.common_pay_wrap__3jp2v .common_pay_bottom_btn_area__312Ua{
	width:100%;
	height:77px;
	background:#edd0a6;
	position:relative;
}
.common_pay_wrap__3jp2v .common_pay_bottom_btn_area__312Ua a{
	width:374px;
	height:90px;
	background:#007cff;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
	font-size:22px;
	color:#ffffff;
	font-family: 'S-CoreDream-6Bold';
	position:absolute;
	top:-45px;
	left:58%;
}


/* 자주 묻는 질문 */
.common_faq_top_line_banner__2sSR-{
	width:100%;
	height:85px;
	position:relative;
	background:#06667c;
}
.common_faq_top_line_banner__2sSR- .common_inner__1cU6t{
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
}
.common_faq_top_line_banner__2sSR- span{
	font-size:32px;
	color:#ffffff;
	font-family: 'S-CoreDream-6Bold';
}

/*faq_tab*/
.common_faq_tab__2Ju4A{
	width:100%;
	height:auto;
	background:#ffffff;
}
.common_faq_tab__2Ju4A .common_inner__1cU6t{
	display:-webkit-box;
	-webkit-box-align:center;
	display:flex;
	flex-direction:column;
	align-items:center;
}
.common_faq_tab__2Ju4A .common_faq_tit__Wx2hK{
	font-family: 'S-CoreDream-6Bold';
	font-size:32px;
	margin-top:102px;
}
.common_faq_tab__2Ju4A .common_faq_txt__1j9bD{
	font-family: 'S-CoreDream-3Light';
	font-size:16px;
	margin-top:16px;
}
.common_faq_tab__2Ju4A .common_ellip__2foBj{
	width:90%;
	display:block;
	overflow:hidden;
	text-overflow:ellipsis;
	white-space:nowrap;
	word-break:normal;
}
.common_faq_tab__2Ju4A .common_faq_srch_area__ULz-k{
	width:100%;
	height:42px;
	position:relative;
	margin-top:58px;
	display:-webkit-box;
	-webkit-box-pack:center;
	display:flex;
	justify-content:center;
}
#common_faq_srch_select__WSoQ9{
	width:90px;
	height:100%;
	padding:0 8px;
	border:1px solid #e6e6e6;
	border-radius:10px;
	-webkit-appearance:none;
	-moz-appearance:none;
	appearance:none; 
	background:#ffffff url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAICAYAAAAiJnXPAAAACXBIWXMAAAsSAAALEgHS3X78AAAAiElEQVQYlY2PgQmDMBAATxfQUTpCF/inG+gIdqN2hP4G3UA3cIQ6wZeXCMGUkoOHkHD8pRGRHpioZ23cHVV9AEOFtgHXNk5mNgKvCmkys7nNLkJc/gh3M4si9rwDVY3/rUB3Ep6pZiffFJmfaE7tB+9cKKQkzik1iNxbERp5v0ZERhG5FG/ufAFPWUpOP5pyIAAAAABJRU5ErkJggg==) no-repeat 90% center;
	cursor:pointer;
	font-size:16px;
	margin-right:10px;
}
#common_faq_srch_select__WSoQ9 option{
	font-size:14px;
}
.common_faq_srch_area__ULz-k #common_faq_input_id__2Yevp{
	width:376px;
	height:100%;
	box-sizing:border-box;
	border:1px solid #e6e6e6;
	border-radius:30px;
	overflow:hidden;
	padding:10px 15px;
	margin-right:12px;
	font-size:16px;
}
.common_faq_srch_area__ULz-k button{
	width:96px;
	height:100%;
	border-radius:30px;
	background:#009cc6;
	color:#ffffff;
	font-size:14px;
	cursor:pointer;
	border:none;
}
.common_faq_tab__2Ju4A .common_faq_area__3FH6w{
	width:1078px;
	height:auto;
	min-height:432px;
	margin:58px auto;
	margin-bottom:160px;
}
.common_faq_tab__2Ju4A .common_faq_categori_area__153vP{
	width:100%;
	height:32px;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:end;
	display:flex;
	justify-content:flex-end;
	align-items:center;
	position:relative;
	margin-bottom:16px;
}
.common_faq_categori_area__153vP #common_faq_categori_select__o9_Kc{
	width:120px;
	height:100%;
	padding:0 8px;
	border:1px solid #e6e6e6;
	border-radius:10px;
	-webkit-appearance:none;
	-moz-appearance:none;
	appearance:none; 
	background:#ffffff url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAICAYAAAAiJnXPAAAACXBIWXMAAAsSAAALEgHS3X78AAAAiElEQVQYlY2PgQmDMBAATxfQUTpCF/inG+gIdqN2hP4G3UA3cIQ6wZeXCMGUkoOHkHD8pRGRHpioZ23cHVV9AEOFtgHXNk5mNgKvCmkys7nNLkJc/gh3M4si9rwDVY3/rUB3Ep6pZiffFJmfaE7tB+9cKKQkzik1iNxbERp5v0ZERhG5FG/ufAFPWUpOP5pyIAAAAABJRU5ErkJggg==) no-repeat 90% center;
	cursor:pointer;
	font-size:16px;
}
#common_faq_categori_select__o9_Kc option{
	font-size:14px;
}
.common_faq_tab__2Ju4A .common_faq_list_wrap__1wdVE{
	width:100%;
	height:auto;
	border-top:2px solid #009cc6;
	position:relative;
}
.common_faq_list_wrap__1wdVE .common_no_result__1eYFk{
	width:100%;
	height:100px;
	position:relative;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
}
.common_faq_list_wrap__1wdVE .common_list__1inqC{
	width:100%;
	height:auto;
	border-bottom:1px solid #e6e6e6;
	position:relative;
}
.common_faq_list_wrap__1wdVE .common_question_area__XhvBu{
	width:100%;
	height:auto;
	min-height:70px;
	box-sizing:border-box;
	display:-webkit-box;
	-webkit-box-align:center;
	display:flex;
	align-items:center;
	padding:20px 56px;
	position:relative;
	cursor:pointer;
	border-bottom:1px solid #e6e6e6;
}
.common_faq_list_wrap__1wdVE .common_question_area__XhvBu span{
	font-size:26px;
	color:#009cc6;
	font-family: 'S-CoreDream-6Bold';
	margin-right:56px;
}
.common_faq_list_wrap__1wdVE .common_question_area__XhvBu p{
	font-size:15px;
	color:#575757;
	font-family: 'S-CoreDream-6Bold';
	width:84%;
	margin-right:60px;
}
.common_faq_list_wrap__1wdVE .common_question_area__XhvBu img{
	height:12px;
	transform:rotate(0deg);
}
.common_faq_list_wrap__1wdVE .common_answer_area__oGPX5{
	width:100%;
	height:auto;
	box-sizing:border-box;
	padding:30px 56px;
	background:#f9f9f9;
	display:none;
}
.common_faq_list_wrap__1wdVE .common_answer_area__oGPX5 p{
	font-size:15px;
	color:#575757;
}
.common_faq_list_wrap__1wdVE .common_list__1inqC.common_on__2QUU7 .common_answer_area__oGPX5{
	display:block;
}
.common_faq_list_wrap__1wdVE .common_list__1inqC.common_on__2QUU7 .common_question_area__XhvBu img{
	transform:rotate(180deg);
}
.common_faq_tab__2Ju4A .common_faq_list_pagebtn_area__YFWlL{
	width:100%;
	height:34px;
	margin-top:40px;
	display:-webkit-box;
	-webkit-box-pack:center;
	display:flex;
	justify-content:center;
}
.common_faq_list_pagebtn_area__YFWlL li{
	height:100%;
	width:auto;
	margin:0 10px;
}
.common_faq_list_pagebtn_area__YFWlL .common_first__2Q_TQ{
	width:auto;
}
.common_faq_list_pagebtn_area__YFWlL .common_prev__1Myue{
	width:auto;
}
.common_faq_list_pagebtn_area__YFWlL .common_next__3pQ4G{
	width:auto;
}
.common_faq_list_pagebtn_area__YFWlL .common_last__2RzKs{
	width:auto;
}
.common_faq_list_pagebtn_area__YFWlL li.common_arrow_btn__2SjZb img{
	height:15px;
	width:auto;
}
.common_faq_list_pagebtn_area__YFWlL li a{
	height:100%;
	width:auto;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	justify-content:center;
	align-items:center;
	font-size:15px;
	font-family: 'S-CoreDream-3Light';
	color:#808080;
}
.common_faq_list_pagebtn_area__YFWlL li a:hover{
	color:#009cc6;
}
.common_faq_list_pagebtn_area__YFWlL li.common_on__2QUU7 a{
	color:#009cc6;
	font-family: 'S-CoreDream-6Bold';
}

/* 404 not found */
.common_not_found_area__1RQ0b{
	background:#ffffff;
	width:100%;
	height:100%;
	overflow:hidden;
	position:fixed;
	top:0;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
	flex-direction:column;
}
.common_not_found_area__1RQ0b img{
	width:400px;
}
.common_not_found_area__1RQ0b p{
	color:#666666;
	font-size:36px;
	font-family: 'S-CoreDream-6Bold';
	line-height:1;
	margin-top:-20px;
}
.common_not_found_area__1RQ0b a{
	color:#f15a24;
	font-size:14px;
	font-family: 'S-CoreDream-3Light';
	width:220px;
	height:36px;
	border-radius:30px;
	border:1px solid #f15a24;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
	margin-top:50px;
}

.common_not_found_area2__1_eSp{
	background:#ffffff;
	width:100%;
	height:100%;
	overflow:hidden;
	position:fixed;
	top:0;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
}
.common_not_found_area2__1_eSp .common_left_area__2Nwxl{
	position:relative;
}
.common_not_found_area2__1_eSp span{
	color:#f15a24;
	font-size:150px;
	font-family: 'S-CoreDream-8Heavy';
	line-height:1;
}
.common_not_found_area2__1_eSp p{
	color:#666666;
	font-size:36px;
	font-family: 'S-CoreDream-6Bold';
	line-height:1;
	margin-top:20px;
}
.common_not_found_area2__1_eSp a{
	color:#f15a24;
	font-size:14px;
	font-family: 'S-CoreDream-3Light';
	width:220px;
	height:36px;
	border-radius:30px;
	border:1px solid #f15a24;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
	margin-top:36px;
}
.common_not_found_area2__1_eSp img{
	height:280px;
	margin-left:24px;
}

/* order.html */
.common_pay_area__2p8aD.common_order_area__1tnKS{
	background:#ffffff;
}
.common_order_wrap__3_JTa{
	width:100%;
	height:auto;
	background:#ffffff;
	position:relative;
	display:-webkit-box;
	-webkit-box-align:center;
	display:flex;
	flex-direction:column;
	align-items:center;
}
.common_order_wrap__3_JTa .common_title__1txLf{
	margin-top:124px;
	font-size:28px;
	font-family:'S-CoreDream-6Bold';
}
.common_order_wrap__3_JTa ul{
	width:100%;
	max-width:880px;
	height:auto;
	border-top:2px solid #333333;
	position:relative;
	margin-top:62px;
}
.common_order_wrap__3_JTa li{
	width:100%;
	height:70px;
	border-bottom:1px solid #e6e6e6;
	box-sizing:border-box;
	display:flex;
}
.common_order_wrap__3_JTa li.common_order_total_price_wrap__258tH{
	height:85px;
}
.common_order_wrap__3_JTa li.common_how_to_pay_wrap__21JeV{
	height:106px;
}
.common_order_wrap__3_JTa li .common_left_area__2Nwxl{
	width:20%;
	min-width:224px;
	background:#f8f8f8;
	height:100%;
	position:relative;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:start;
	display:flex;
	align-items:center;
	justify-content:flex-start;
	box-sizing:border-box;
	padding-left:64px;
}
.common_order_wrap__3_JTa li .common_left_area__2Nwxl span{
	font-size:15px;
	color:#666666;
	text-align:left;
}
.common_order_wrap__3_JTa li .common_right_area__3060r{
	width:80%;
	height:100%;
	position:relative;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:start;
	display:flex;
	align-items:center;
	justify-content:flex-start;
	box-sizing:border-box;
	padding-left:64px;
}
.common_order_wrap__3_JTa li .common_right_area__3060r span{
	font-size:15px;
	color:#808080;
	text-align:left;
}
.common_order_wrap__3_JTa li .common_right_area__3060r span.common_order_price__2QyYr{
	color:#4d4d4d;
	display:-webkit-box;
	-webkit-box-align:center;
	display:flex;
	align-items:center;
}
.common_order_wrap__3_JTa li .common_right_area__3060r span.common_order_price__2QyYr::after{
	content:'원';
	display:block;
	color:#4d4d4d;
	font-size:15px;
	text-align:left;
	margin-left:2px;
}
.common_order_wrap__3_JTa li .common_right_area__3060r span.common_order_total_price__3CxY3{
	color:#f15a24;
	font-size:22px;
	font-family:'S-CoreDream-6Bold';
	display:-webkit-box;
	-webkit-box-align:center;
	display:flex;
	align-items:center;
	line-height:1;
}
.common_order_wrap__3_JTa li .common_right_area__3060r span.common_order_total_price__3CxY3::after{
	content:'원';
	display:block;
	color:#f15a24;
	font-size:15px;
	line-height:1;
	margin-left:3px;
}
.common_how_to_pay_wrap__21JeV .common_radio_btn_area__3nYLt{
	display:-webkit-box;
	-webkit-box-align:center;
	display:flex;
	align-items:center;
	width:auto;
	margin-right:30px;
	font-size:15px;
	color:#b3b3b3;
}
.common_how_to_pay_wrap__21JeV .common_radio_btn_area__3nYLt input{
	width:15px;
	height:15px;
	margin-right:4px;
	-webkit-appearance:none;
	-moz-appearance:none;
	     appearance:none;
	border:1px solid #b3b3b3;
	padding:0;
	box-sizing:border-box;
	border-radius:50%;
}
.common_how_to_pay_wrap__21JeV .common_radio_btn_area__3nYLt input:checked{
	-webkit-appearance:none;
	-moz-appearance:none;
	     appearance:none;
	background:#ff6e00;
	border:none;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
}
.common_how_to_pay_wrap__21JeV .common_radio_btn_area__3nYLt input:checked::after{
	content:'';
	display:block;
	width:8px;
	height:8px;
	border-radius:50%;
	background:#ffffff;
}
.common_order_wrap__3_JTa .common_customer_info_title__2fm0d{
	width:100%;
	max-width:880px;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:start;
	display:flex;
	align-items:center;
	justify-content:flex-start;
	margin-top:50px;
}
.common_order_wrap__3_JTa .common_customer_info_title__2fm0d p{
	font-size:16px;
	font-family: 'S-CoreDream-6Bold';
	text-align:left;
}
.common_order_wrap__3_JTa .common_customer_info_title__2fm0d div{
	width:8px;
	height:8px;
	border-radius:50%;
	background:#333333;
	margin-right:4px;
}
.common_order_wrap__3_JTa ul.common_customer_info_wrap__1IEbX{
	margin-top:10px;
}

.common_order_wrap__3_JTa .common_btn_area__3WMBi{
	margin-top:62px;
	margin-bottom:100px;
	width:100%;
	height:50px;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
	position:relative;
}
.common_order_wrap__3_JTa .common_btn_area__3WMBi button{
	width:150px;
	height:100%;
	border-radius:25px;
	background:#cccccc;
	font-size:16px;
	color:#ffffff;
	border:none;
	cursor:pointer;
}
.common_order_wrap__3_JTa .common_btn_area__3WMBi button:last-child{
	background:#009cc6;
	margin-left:20px;
}


/*반응형*/
@media screen and (min-width:1200px) and (hover:hover) {
	.common_introduce_area__2LRd7 .common_ym_who_btn__1oA6w:hover{
		background:#ffffff;
	}
	.common_introduce_area__2LRd7 .common_ym_who_btn__1oA6w:hover span{
		color:#f29500;
	}
	.common_introduce_area__2LRd7 .common_ym_who_btn__1oA6w:hover .common_hover_round__2n9ev{
		transform:scale(50);
	}
}
@media screen and (min-width:2560px){
	/* index.html */
	.common_index_tit__3e1q3{
		font-size:2em;
	}
	.common_sub_title__hQAyC{
		font-size:1.4em;
	}
	p.common_txt__3s3ZA{
		font-size:1em;
	}
	.common_stakato__e3n3a:first-child{
		margin-left:.5vw;
	}
	/* main_banner_area */
	.common_bn_content_wrap__2v3GE .common_bn_tit__3dw1P .common_stakato__e3n3a::before{
		max-width:20px;
		max-height:20px;
	}
	.common_bn_content_wrap__2v3GE .common_bn_tit__3dw1P{
		font-size:3.4em;
	}
	.common_bn_content_wrap__2v3GE .common_bn_txt__3TdUu{
		font-size:1.6em;
	}
	/* introduce_area */
	.common_introduce_area__2LRd7 .common_right_area__3060r p{
		font-size:1em;
	}
	.common_introduce_area__2LRd7 .common_ym_who_btn__1oA6w span{
		font-size:1.1em;
	}
	/* ym_world_area */
	.common_ym_world_area__3ao46 .common_building__1tZuc span{
		font-size:1.2em;
	}
	.common_ym_world_area__3ao46 .common_building__1tZuc p{
		font-size:1em;
	}
	/* ym_difference_area */
	.common_ym_difference_area__GVl5X .common_info_txt_wrap__1f4Eh p{
		font-size:1em;
	}
	/* ym_myroom_area */
	.common_ym_myroom_area__1iNyx .common_content__2kYUC span{
		font-size:1.2em;
	}
	.common_ym_myroom_area__1iNyx .common_content__2kYUC p{
		font-size:1em;
	}
	.common_ym_myroom_area__1iNyx .common_box__2MqeC .common_cover__1DbYf{
		font-size:1em;
	}
	.common_ym_myroom_area__1iNyx .common_content__2kYUC .common_graph_area3__22g6W .common_counting__1dwuc{
		font-size:2em;
	}
	/* brain_music_area */
	.common_brain_music_area__NtTkf .common_wrap__ornby li .common_right_area__3060r span{
		font-size:1.2em;
	}
	.common_brain_music_area__NtTkf .common_wrap__ornby li .common_right_area__3060r p{
		font-size:1em;
	}
	/* coding_block_area */
	.common_coding_block_area__2gmOW .common_cont__1FF3G .common_title__1txLf{
		font-size:1em;
	}
	.common_coding_block_area__2gmOW .common_cont__1FF3G .common_sub_tit__3_GE4{
		font-size:.7em;
	}
	.common_coding_block_area__2gmOW .common_cont__1FF3G p{
		font-size:.8em;
	}
	/* yf_area */
	.common_yf_area__4xLfs .common_yf_info_area__3AaKI .common_txt_box__3wTLx span{
		font-size:1.4em;
	}
	.common_yf_area__4xLfs .common_yf_info_area__3AaKI .common_txt_box__3wTLx p{
		font-size:1em;
	}
	
}
@media screen and (min-width:1200px) and (max-width:1359px) {
	

	/* main */
	.common_bn_content_wrap__2v3GE{
		padding-top:12.2vh;
		padding-bottom:8vh;
	}
	.common_bn_content_wrap__2v3GE .common_inner90__3qaGh{
		justify-content:center;
	}
	.common_bn_content_wrap__2v3GE img{
		height:42vmin;
	}
	/* introduce_area */
	.common_introduce_area__2LRd7{
		padding-bottom:8vh;
	}
	.common_introduce_area__2LRd7 .common_inner90__3qaGh{
		justify-content:center;
	}
	/* .ym_world_area */
	.common_ym_world_area__3ao46{
		padding-bottom:8vh;
		padding-top:80px;
	}
	.common_ym_world_area__3ao46 .common_inner90__3qaGh{
		justify-content:center;
	}
	/* .ym_difference_area */
	.common_ym_difference_area__GVl5X{
		padding-bottom:9vh;
		padding-top:80px;
	}
	.common_ym_difference_area__GVl5X .common_inner90__3qaGh{
		justify-content:center;
	}
	/* .ym_myroom_area */
	.common_ym_myroom_area__1iNyx{
		padding-bottom:10vh;
		padding-top:80px;
	}
	.common_ym_myroom_area__1iNyx .common_inner90__3qaGh{
		justify-content:center;
	}
	/* brain_music_area */
	.common_brain_music_area__NtTkf{
		padding-bottom:10vh;
		padding-top:80px;
	}
	.common_brain_music_area__NtTkf .common_wrap__ornby{
		margin-left:1.5vw;
	}
	/* coding_block_area */
	.common_coding_block_area__2gmOW{
		padding-bottom:10vh;
		padding-top:80px;
	}
	.common_coding_block_area__2gmOW .common_inner90__3qaGh{
		justify-content:center;
	}
	.common_coding_block_area__2gmOW .common_bottom_area__16Bx2{
		margin-top:7vh;
	}
	.common_coding_block_area__2gmOW .common_cont__1FF3G:last-child br{
		display:none;
	}
	/* .yf_area */
	.common_yf_area__4xLfs{
		padding-bottom:10vh;
		padding-top:80px;
	}
	.common_yf_area__4xLfs .common_inner90__3qaGh{
		justify-content:center;
	}
	/* floating_paging_btn_wrap */
	.common_floating_paging_btn_wrap__10eqW{
		display:none;
	}
	
	
}
/*타블렛*/
@media screen and (min-width:768px) and (max-width:1199px) {
	
	.common_inner__1cU6t{
		width:90%;
	}
	
	/*header*/
	.common_header__3rYGR .common_inner__1cU6t{
		width:90%;
	}
	.common_header__3rYGR .common_logo__32lKi{
		margin:0 auto;
		margin-top:12px;
		height:44px;
	}
	.common_menu_wrapper__3UAYG{
		display:none;
	}
	/*login&mypage*/
	.common_header__3rYGR .common_button__2VIg2{
		display:none; 
	}
	/*mobile_menu_btn_area*/
	.common_mobile_menu_btn_area__u63Re{
		display:block; 
	}
	.common_mobile_menu_area__2aife{
		width:50%;
	}
	
	/*footer*/
	section.common_footer_section__23XeD{
		height:auto;
		background:#1a1a1a;
		padding-top:0;
		padding-bottom:0;
		top:0;
		position:relative;
	}
	.common_footer__18aab{
		height:auto;
	}
	.common_footer__18aab .common_inner90__3qaGh{
		-webkit-box-pack:center;
		flex-direction:column-reverse;
		justify-content:center;
	}
	.common_f_left__1epgt{
		width:100%;
		height:auto;
		margin:20px 0;
	}
	.common_f_left__1epgt ul{
		align-items:center;
	}
	.common_f_left__1epgt li{
		text-align:center;
	}
	.common_f_right__-R8OE{
		width:100%;
		height:auto;
	}
	.common_f_right__-R8OE ul{
		align-items:center;
		margin-top:40px;
	}
	
	/*로그인 login.html*/
	.common_login_area__3MH8G{
		width:98%; 
		max-width:420px;
	}
	.common_login_area__3MH8G a.common_google__3cloo{
		width:100%; 
		max-width:348px;
	}
	
	/*password_reset_area*/
	.common_password_reset_area__3lxFX{
		width:98%; 
		max-width:500px;
	}

	/*join_area*/
	.common_join_area__YlYC1{
		width:98%; 
		max-width:420px;
	}
	
	/*join_group_area*/
	.common_join_group_area__TDhWT{
		width:98%; 
		max-width:420px;
	}
	.common_join_group_area__TDhWT .common_join_group_wrap__3fqH5 .common_select_area__2EsHG span{
		font-size:15px;
	}
	
	/*join_agree*/
	.common_join_agree_area__3wfyj{
		width:98%;
	}
	.common_join_agree_area__3wfyj a.common_agree_logo__cZRcY img{
		margin-top:20px;
	}
	
	/*stuedent_make_area*/
	.common_stuedent_make_area__17zR_{
		width:98%; max-width:420px;
	}
	
	.common_agree_privacy_wrap__rTGa2 .common_wrap__ornby .common_privacy_area__37ys7.common_area1__1KZ6K .common_box_wrap__1S8pe img{
		width:80%;
	}
	
	/*index.html*/
	.common_stakato__e3n3a::before{
		width:.5vh;
		height:.5vh;
		top:-.7vh;
	}
	p.common_txt__3s3ZA{
		font-size:.8em;
	}
	section{
		height:100vh;
		position:fixed;
		top:100vh;
	}
	/*main_banner_area */
	.common_bn_content_wrap__2v3GE .common_inner90__3qaGh{
		justify-content:center;
	}
	.common_bn_content_wrap__2v3GE img{
		height:30vmin;
	}

	/*introduce_area*/
	.common_introduce_area__2LRd7{
		padding-top:calc(80px + 3vh);
		padding-bottom:3vh;
	}
	.common_introduce_area__2LRd7 .common_inner90__3qaGh{
		justify-content:center;
	}
	.common_introduce_area__2LRd7 .common_top_area__1Ir0S{
		height:auto;
		flex-direction:column-reverse;
	}
	.common_introduce_area__2LRd7 .common_top_area__1Ir0S img{
		height:30vh;
		margin-right:0;
	}
	.common_introduce_area__2LRd7 .common_top_area__1Ir0S .common_right_area__3060r{
		display:-webkit-box;
		-webkit-box-align:Center;
		display:flex;
		flex-direction:column;
		align-items:center;
		margin-bottom:3vh;
	}
	.common_introduce_area__2LRd7 .common_right_area__3060r p{
		text-align:center;
		font-size:.75em;
	}
	.common_introduce_area__2LRd7 span.common_index_tit__3e1q3{
		text-align:center;
	}
	.common_introduce_area__2LRd7 .common_ym_who_popup__3du5G.common_on__2QUU7{
		height:104%;
		padding:6vmin;
		flex-direction:column;
	}
	.common_introduce_area__2LRd7 .common_ym_who_popup__3du5G.common_on__2QUU7 .common_ym_wyo_box__3T_QO{
		width:100%;
		margin-top:3vh;
	}
	.common_ym_who_popup__3du5G.common_on__2QUU7 .common_ym_wyo_box__3T_QO:first-child{
		width:100%;
		margin:0;
	}
	.common_ym_who_popup__3du5G.common_on__2QUU7 .common_ym_wyo_box__3T_QO:nth-child(2){
		width:100%;
		margin:0;
		margin-top:3vh;
	}
	.common_ym_who_popup__3du5G.common_on__2QUU7 .common_ym_wyo_box__3T_QO p{
		margin-top:1vh;
		font-size:.8em;
	}
	.common_introduce_area__2LRd7 .common_ym_who_btn__1oA6w{
		width:auto;
		box-sizing:border-box;
		padding:0 15vmin;
		margin-top:4vh;
		height:34px;
	}
	.common_introduce_area__2LRd7 .common_ym_who_btn__1oA6w .common_hover_round__2n9ev{
		width:20px;
		height:20px;
	}

	/*ym_world_area*/
	.common_ym_world_area__3ao46{
		padding-top:calc(80px + 3vh);
		padding-bottom:4vh;
	}
	.common_ym_world_area__3ao46 .common_bottom_area__16Bx2{
		height:auto;
		margin-top:3vh;
		flex-direction:column-reverse;
	}
	.common_ym_world_area__3ao46 .common_left_area__2Nwxl{
		height:30vh;
		margin-left:0;
	}
	.common_ym_world_area__3ao46 .common_left_area__2Nwxl .common_loby__2dEHE{
		height:132%;
		margin-top:0;
	}
	.common_ym_world_area__3ao46 .common_plus_wrap__3LtaP .common_plus__2zQZU{
		width:2.5vh;
		height:2.5vh;
	}
	.common_ym_world_area__3ao46 .common_plus__2zQZU:nth-child(1){
		top:15%;
		left:28%;
	}
	.common_ym_world_area__3ao46 .common_plus__2zQZU:nth-child(2){
		top:-8%;
		left:33%;
	}
	.common_ym_world_area__3ao46 .common_plus__2zQZU:nth-child(3){
		top:-6%;
		left:59%;
	}
	.common_ym_world_area__3ao46 .common_plus__2zQZU:nth-child(4){
		top:-3%;
		left:69%;
	}
	.common_ym_world_area__3ao46 .common_plus__2zQZU:nth-child(5){
		top:17%;
		left:45%;
	}
	.common_ym_world_area__3ao46 .common_plus__2zQZU:nth-child(6){
		top:-6%;
		left:30%;
	}
	.common_ym_world_area__3ao46 .common_right_area__3060r{
		height:auto;
		margin-left:0;
		margin-bottom:3vh;
	}
	.common_ym_world_area__3ao46 .common_building_wrap__3bCH9{
		width:100%;
		height:14vh;
		padding:2vh;
		border-radius:30px;
	}
	.common_ym_world_area__3ao46 .common_building__1tZuc{
		padding:1.5vh;
		justify-content:center;
	}
	.common_ym_world_area__3ao46 .common_building__1tZuc img{
		display:none;
	}
	.common_ym_world_area__3ao46 .common_building__1tZuc span{
		font-size:.6em;
		margin-bottom:1vh;
	}
	.common_ym_world_area__3ao46 .common_building__1tZuc p br{
		display:none;
	}

	/*ym_difference_area*/
	.common_ym_difference_area__GVl5X .common_middle_area__2Omh4{
		align-items:flex-start;
	}
	.common_ym_difference_area__GVl5X .common_img_area__3KniS{
		height:27vmin;
	}

	/*ym_myroom_area*/
	.common_ym_myroom_area__1iNyx{
		padding-top:calc(80px + 3vh);
		padding-bottom:5vh;
	}
	.common_ym_myroom_area__1iNyx .common_inner90__3qaGh{
		justify-content:Center;
	}
	.common_ym_myroom_area__1iNyx .common_bottom_area__16Bx2{
		margin-top:2vh;
		flex-direction:column;
		width:100%;
		height:auto;
	}
	.common_ym_myroom_area__1iNyx .common_box__2MqeC{
		transform:scale(1);
		top:0;
		width:35vmax;
		height:4vh;
		box-shadow:0 0 10px 0 rgb(0,0,0,.35);
		transition:all .3s;
		border-radius:30px;
		margin:.5vh 0;
	}
	.common_ym_myroom_area__1iNyx .common_box__2MqeC.common_on__2QUU7{
		transform:scale(1);
		top:0;
		height:auto;
		box-shadow:0 0 10px 0 rgb(0,0,0,.35);
		margin:1vh 0;
	}
	.common_ym_myroom_area__1iNyx .common_box__2MqeC:hover{
		transform:scale(1);
	}
	.common_ym_myroom_area__1iNyx .common_box__2MqeC.common_on__2QUU7:hover{
		transform:scale(1);
	}
	.common_ym_myroom_area__1iNyx .common_box__2MqeC .common_cover__1DbYf{
		font-size:.6em;
	}
	.common_ym_myroom_area__1iNyx .common_box__2MqeC .common_content__2kYUC{
		padding:1.5vh;
	}
	.common_ym_myroom_area__1iNyx .common_content__2kYUC span{
		font-size:.8em;
	}
	.common_ym_myroom_area__1iNyx .common_content__2kYUC p{
		font-size:.65em;
	}
	.common_ym_myroom_area__1iNyx .common_box__2MqeC .common_content__2kYUC .common_bottom_area__16Bx2{
		margin-top:0;
	}
	.common_ym_myroom_area__1iNyx .common_content__2kYUC .common_graph_area3__22g6W .common_count_wrap__1OTx0{
		height:29%;
	}
	.common_ym_myroom_area__1iNyx .common_content__2kYUC .common_graph_area3__22g6W .common_count1__UguBq{
		top:55%;
	}
	.common_ym_myroom_area__1iNyx .common_content__2kYUC .common_graph_area3__22g6W .common_count2__3ZZTt{
		top:55%;
	}
	.common_ym_myroom_area__1iNyx .common_content__2kYUC .common_graph_area3__22g6W .common_count3__3sNcj{
		top:55%;
	}
	.common_ym_myroom_area__1iNyx .common_content__2kYUC .common_graph_area3__22g6W .common_counting__1dwuc{
		font-size:1.2em;
	}
	.common_ym_myroom_area__1iNyx .common_content__2kYUC .common_graph_area1_graph__gMu1n{
		width:80%;
	}

	/*brain_music_area*/
	.common_brain_music_area__NtTkf .common_wrap__ornby{
		margin-left:0;
		top:-5vh;
	}
	.common_brain_music_area__NtTkf .common_wrap__ornby li{
		padding:2vh 2vw;
	}

	/*coding_block_area*/
	.common_coding_block_area__2gmOW .common_inner90__3qaGh{
		justify-content:center;
	}
	.common_coding_block_area__2gmOW .common_bottom_area__16Bx2{
		margin-top:10vh;
	}
	.common_coding_block_area__2gmOW .common_cont__1FF3G{
		width:18vw;
	}
	.common_coding_block_area__2gmOW .common_cont__1FF3G:last-child{
		width:17vw;
	}
	.common_coding_block_area__2gmOW .common_cont__1FF3G:last-child br{
		display:none;
	}
	.common_coding_block_area__2gmOW .common_cont__1FF3G img:last-child{
		width:7.5vw;
	}

	/*yf_area*/
	.common_yf_area__4xLfs{
		padding-top:80px;
		padding-bottom:10vh;
	}
	.common_yf_area__4xLfs .common_content_area__2tBPk{
		height:auto;
		width:100%;
		-webkit-box-align:Center;
		flex-direction:column;
		align-items:center;
		margin-top:2vh;
	}
	.common_yf_area__4xLfs .common_content_area__2tBPk .common_btn_area__3WMBi{ 
		width:100%;
		height:36px;
		padding-bottom:0;
		margin-right:0;
		margin-left:0;
		margin-bottom:1vh;
		-webkit-box-pack:center;
		flex-direction:row;
		justify-content:center;
	}
	.common_yf_area__4xLfs .common_content_area__2tBPk .common_btn_area__3WMBi li{
		width:auto;
		height:60%;
		margin:0 3vw;
	}
	.common_yf_area__4xLfs .common_content_area__2tBPk .common_btn_area__3WMBi li.common_on__2QUU7{
		height:100%;
	}
	.common_yf_area__4xLfs .common_content_area__2tBPk .common_btn_area__3WMBi li.common_on__2QUU7:hover{
		height:100%;
	}
	.common_yf_area__4xLfs .common_content_area__2tBPk .common_btn_area__3WMBi li:hover{
		height:80%;
	}
	.common_yf_area__4xLfs .common_yf_img_area__3eH7p{
		height:34vh;
		max-height:300px;
		margin-right:0;
	}
	.common_yf_area__4xLfs .common_yf_img_area__3eH7p img{
		height:100%;
	}
	.common_yf_area__4xLfs .common_yf_info_area__3AaKI{
		width:100%;
		max-width:400px;
		height:90px;
		margin-top:15px;
		justify-content:flex-start;
	} 
	.common_yf_area__4xLfs .common_yf_info_area__3AaKI .common_album_area__2b8yB{
		display:none;
	}	
	.common_yf_area__4xLfs .common_yf_info_area__3AaKI .common_txt_area__25xQw{
		width:100%;
		height:auto;
	}	
	.common_yf_area__4xLfs .common_yf_info_area__3AaKI .common_txt_box__3wTLx{
		width:100%;
		height:auto;
		padding:3vmin;
		-webkit-box-align:center;
		-webkit-box-pack:center;
		align-items:center;
		justify-content:center;
	}	
	.common_yf_area__4xLfs .common_yf_info_area__3AaKI .common_txt_box__3wTLx span{
		font-size:14px;
	}
	.common_yf_area__4xLfs .common_yf_info_area__3AaKI .common_txt_box__3wTLx p{
		font-size:12px;
		text-align:center;
	}

	/* floating_paging_btn_wrap */
	.common_floating_paging_btn_wrap__10eqW{
		display:none;
	}
	
	/*use.html*/
	#common_content__2kYUC .common_wrap__ornby{width:100%;}
	
	/*pay.html*/
	.common_pay_tab_btn_area__L-aIZ li.common_on__2QUU7{
		border:none;
	}
	.common_pay_wrap__3jp2v .common_product_area__19QlX{
		width:100%;
		padding:30px;
	} 
	.common_pay_wrap__3jp2v .common_product_area__19QlX ul{
		width:100%;
		height:auto;
		position:relative;
		display:-webkit-box;
		-webkit-box-pack:center;
		display:flex;
		justify-content:center;
	}
	.common_pay_wrap__3jp2v .common_product_area__19QlX li{
		width:31%;
		padding:20px;
		padding-top:12px;
		margin:0 1%;
	}
	.common_pay_wrap__3jp2v .common_product_area__19QlX li .common_scratch__1OsJ7{
		width:90%;
	}
	.common_pay_wrap__3jp2v .common_product_name__c4yj2{
		margin-top:30px;
		padding-bottom:20px;
		align-items:center;
		flex-direction:column;
	}
	.common_pay_wrap__3jp2v .common_product_name__c4yj2 p{
		padding-bottom:0;
	}
	.common_pay_wrap__3jp2v .common_product_name__c4yj2 .common_period__ef7CG{
		margin-right:0;
	}
	.common_pay_wrap__3jp2v .common_product_price__18u_E{
		padding-top:30px;
		padding-bottom:20px;
		-webkit-box-align:center;
		align-items:center;
		flex-direction:column;
	}
	.common_pay_wrap__3jp2v .common_product_price__18u_E .common_left_area__2Nwxl{
		align-items:center;
		margin-right:0;
		margin-bottom:20px;
	}
	.common_pay_wrap__3jp2v .common_product_total_price_area__3WcMj{
		flex-direction:column;
		height:auto;
		padding:20px;
	}
	.common_pay_wrap__3jp2v .common_product_total_price_area__3WcMj span{
		margin-right:0;
		margin-bottom:10px;
	}
	.common_pay_wrap__3jp2v .common_btn_area__3WMBi{
		width:100%;
	}
	.common_pay_wrap__3jp2v .common_pay_guide__1EGI8{
		width:100%;
		height:auto;
		padding:0;
		padding-bottom:60px;
		-webkit-box-align:center;
		-webkit-box-pack:center;
		align-content:center;
		justify-content:center;
		flex-direction:column;
		flex-wrap:no-wrap;
	}
	.common_pay_wrap__3jp2v .common_pay_product_info_area__1w_Gx{
		padding-bottom:120px;
	}
	.common_pay_wrap__3jp2v .common_pay_product_3d_area__2Yc3K{
		height:auto;
		background:#ffffff;
		border:20px solid #bc9d70;
		padding-top:80px;
		padding-bottom:80px;
		margin-top:70px;
	}
	.common_pay_wrap__3jp2v .common_pay_product_3d_area__2Yc3K::before{
		content:'';
		display:block;
		width:10%;
		height:125px;
		background:url(/static/media/pay_product_box_bg1_top.a74e719b.png) no-repeat center;
		background-size:100% auto;
		position:absolute;
		top:-85px;
		left:50%;
		transform:translateX(-50%);
	}
	.common_pay_wrap__3jp2v .common_pay_product_top_area__1GTNJ{
		width:100%;
		padding-left:40px;
		padding-right:22px;
	}
	.common_pay_wrap__3jp2v .common_pay_product_3d_bottom_area__3nDMn{
		width:100%;
		-webkit-box-pack:center;
		align-items:center;
		justify-content:center;
		flex-direction:column;
	}
	.common_pay_wrap__3jp2v .common_pay_product_3d_bottom_area__3nDMn .common_img_area__3KniS{
		width:60%;
		margin-top:20px;
	}
	.common_pay_wrap__3jp2v .common_pay_product_3d_bottom_area__3nDMn img{
		width:100%;
		height:auto;
	}
	.common_pay_wrap__3jp2v .common_pay_product_3d_bottom_area__3nDMn .common_img_area__3KniS:first-child{
		width:60%;
	}
	.common_pay_wrap__3jp2v .common_pay_product_3d_bottom_area__3nDMn .common_img_area__3KniS:first-child p{
		width:100%;
	}
	.common_pay_wrap__3jp2v .common_pay_product_3d_bottom_area__3nDMn .common_img_area__3KniS:nth-child(2){
		margin-left:0; 
		margin-right:0; 
	}
	.common_pay_wrap__3jp2v .common_pay_product_contents_area__1BR0F{
		height:auto;
		background:#ffffff;
		border:20px solid #bc9d70;
		padding-top:80px;
		padding-bottom:80px;
		margin-top:70px;
	}
	.common_pay_wrap__3jp2v .common_pay_product_contents_area__1BR0F::before{
		content:'';
		display:block;
		width:12%;
		height:136px;
		background:url(/static/media/pay_product_box_bg2_top.755d0a6c.png) no-repeat center;
		background-size:100% auto;
		position:absolute;
		top:-123px;
		left:50%;
		transform:translateX(-50%);
	}
	.common_pay_wrap__3jp2v .common_pay_product_contents_bottom_area__vR26S{
		width:100%;
	}
	.common_pay_wrap__3jp2v .common_pay_product_contents_bottom_area__vR26S .common_top_area__1Ir0S .common_img_area__3KniS div{
		-webkit-box-pack:center;
		flex-direction:column;
		justify-content:center;
	}
	.common_pay_wrap__3jp2v .common_pay_product_contents_bottom_area__vR26S .common_top_area__1Ir0S img{
		width:60%;
	}
	.common_pay_wrap__3jp2v .common_pay_product_contents_bottom_area__vR26S .common_top_area__1Ir0S img:nth-child(2){
		width:70%;
		margin-left:0;
		margin-top:20px;
	}
	.common_pay_wrap__3jp2v .common_pay_product_contents_bottom_area__vR26S p{
		width:60%;
		margin:0 auto;
		margin-top:12px;
	}
	.common_pay_wrap__3jp2v .common_pay_product_contents_bottom_area__vR26S .common_bottom_area__16Bx2{
		-webkit-box-align:Center;
		flex-direction:column;
		align-items:center;
	}
	.common_pay_product_contents_bottom_area__vR26S .common_bottom_area__16Bx2 .common_img_area__3KniS{
		width:60%;
	}
	.common_pay_product_contents_bottom_area__vR26S .common_bottom_area__16Bx2 .common_img_area__3KniS p{
		width:100%;
	}
	.common_pay_product_contents_bottom_area__vR26S .common_bottom_area__16Bx2 .common_img_area__3KniS img{
		width:100%;
	}
	.common_pay_product_contents_bottom_area__vR26S .common_bottom_area__16Bx2 .common_img_area__3KniS:last-child{
		width:100%;
		margin-left:0;
		margin-top:20px;
	}
	.common_pay_product_contents_bottom_area__vR26S .common_bottom_area__16Bx2 .common_img_area__3KniS:last-child p{
		width:90%;
	}
	.common_pay_product_contents_bottom_area__vR26S .common_bottom_area__16Bx2 .common_img_area__3KniS:last-child div{
		justify-content:Center;
	}
	.common_pay_product_contents_bottom_area__vR26S .common_bottom_area__16Bx2 .common_img_area__3KniS:last-child div img{
		width:44%;
	}
	.common_pay_wrap__3jp2v .common_pay_product_etc_area__3BH1H{
		height:auto;
		background:#bc9d70;
		padding-top:80px;
		padding-bottom:50px;
		margin-top:70px;
	}
	.common_pay_wrap__3jp2v .common_pay_product_etc_area__3BH1H::before{
		content:'';
		display:block;
		width:12%;
		height:144px;
		background:url(/static/media/pay_product_box_bg3_top.cc6c8754.png) no-repeat center;
		background-size:100% auto;
		position:absolute;
		top:-90px;
		left:50%;
		transform:translateX(-50%);
	}
	.common_pay_wrap__3jp2v .common_pay_product_etc_area__3BH1H .common_pay_product_top_area__1GTNJ{
		width:100%;
		padding-left:56px;
	}
	.common_pay_product_etc_area__3BH1H img{
		width:90%;
	}
	.common_pay_wrap__3jp2v .common_pay_bottom_btn_area__312Ua a{
		left:42%;
	}
	
	/* order.html */
	.common_order_wrap__3_JTa li .common_right_area__3060r{
		flex-wrap:wrap;
		padding:20px 0;
		padding-left:64px;
	}
	.common_how_to_pay_wrap__21JeV .common_radio_btn_area__3nYLt{
		margin-right:0;
		width:50%;
	}


	/*download.html*/
	.common_download_area__1X_we{
		padding-bottom:80px;
	}
	.common_download_area__1X_we .common_download_wrap__3LJhK{
		width:90%; 
	}
	.common_download_wrap__3LJhK .common_top_top_area__3ryUB{
		-webkit-box-pack:center;
		-webkit-box-align:center;
		justify-content:center;
		align-items:center;
		flex-direction:column;
	}
	.common_download_wrap__3LJhK .common_left_area__2Nwxl{
		width:100%;
		-webkit-box-pack:center;
		-webkit-box-align:center;
		justify-content:center;
		align-items:center;
	}
	.common_download_wrap__3LJhK .common_left_area__2Nwxl p{
		text-align:center;
	}
	.common_download_wrap__3LJhK .common_right_area__3060r{
		width:100%;
		margin-top:60px;
	}
	.common_download_wrap__3LJhK .common_right_area__3060r .common_bottom_area__16Bx2{
		justify-content:center;
		max-width:605px;
		margin:0 auto;
		margin-top:18px;
	}
	.common_download_wrap__3LJhK .common_btn_area__3WMBi{
		margin-top:100px;
	}
	.common_download_wrap__3LJhK .common_btn_area__3WMBi button{
		width:100%;
	}
	
	/*mypage_html*/
	/*mypage_report*/
	.common_my_report_top_btn_area__2T17n{
		width:80%;
	}
	.common_mypage_report__3b5HF .common_for_freeuser_info__FsOrs{
		width:80%;
	}
	.common_my_report_missonprogress_area__15MF1{
		height:auto;
		width:80%;
	}
	.common_my_report_missonprogress_area__15MF1 .common_wrap__ornby{
		height:auto;
		flex-direction:column;
	}
	.common_my_report_missonprogress_area__15MF1 .common_misson_progress__317wj{
		width:100%;
		height:auto;
		border-right:none;
		border-bottom:1px solid #e6e6e6;
		padding:30px;
		-webkit-box-align:center;
		-webkit-box-pack:center;
		align-items:center;
		justify-content:center;
		flex-direction:row;
	}
	.common_my_report_missonprogress_area__15MF1 .common_misson_progress__317wj:last-child{
		border:none;
	}
	.common_misson_progress__317wj .common_misson_left__186Ms{
		width:30%;
		height:100%;
		-webkit-box-align:center;
		-webkit-box-pack:center;
		align-items:center;
		justify-content:center;
		margin-right:40px;
	}
	.common_misson_progress__317wj img{
		margin-bottom:30px;
	}
	.common_misson_progress__317wj .common_progress_wrap__3Aq4A{
		margin-bottom:0;
	}
	.common_misson_progress__317wj .common_misson_txt__2Cdse{
		width:50%;
		height:100%;
		-webkit-box-align:start;
		-webkit-box-pack:center;
		align-items:flex-start;
		justify-content:center;
	}
	.common_misson_progress__317wj p{
		margin-top:20px;
	}
	.common_my_report_study_time_area___GX6l{
		width:80%;
	}
	.common_my_report_cognitive_ability_area__3j2Ay{
		width:80%;
		margin-bottom:100px;
	}
	.common_my_report_cognitive_ability_area__3j2Ay .common_wrap__ornby{
		height:auto;
		-webkit-box-pack:center;
		-webkit-box-align:center;
		flex-direction:column;
		align-items:center;
		justify-content:center;
		padding:20px;
		box-sizing:border-box;
	}
	.common_my_report_cognitive_ability_area__3j2Ay .common_ability_area__6H0Ov{
		width:100%;
		height:auto;
		padding:30px;
	}
	.common_my_report_cognitive_ability_area__3j2Ay .common_ability_area__6H0Ov:last-child{
		padding:30px;
	}
	.common_my_report_cognitive_ability_area__3j2Ay .common_cognitive_ability__ai_Du{
		width:360px;
		margin-bottom:60px;
	}

	/*mypage_stuedent_info*/
	.common_mypage_stuedent_info__2waua .common_my_tit__38b1N{
		margin-top:60px;
	}
	.common_parents_state_area__14Fnn{
		width:80%;
		height:auto;
		margin-top:50px;
	}
	/* 학부모 상태가 유료 일때 html에서 147,151번줄의 p태그에 class="pay" 추가 */
	.common_parents_state_box__2gFsM{
		width:100%;
		height:auto;
		padding:20px;
	}
	.common_parents_state_box__2gFsM .common_state_head__160ZN{
		height:inherit;
		width:40%;
		-webkit-box-pack:center;
		justify-content:center;
		flex-direction:column;
		padding:30px;
		border:none;
	}
	.common_state_head__160ZN .common_profile_wrap__2OQ0C{
		margin-right:0;
	}
	.common_parents_state_box__2gFsM .common_state_head__160ZN>span{
		margin-top:20px;
	}
	.common_parents_state_box__2gFsM .common_right_area__3060r{
		width:60%;
		height:auto;
		flex-direction:column;
		border-left:1px solid #e6e6e6;
	}
	.common_parents_state_box__2gFsM .common_state_list__TV0DT{
		height:auto;
		width:100%;
		-webkit-box-align:center;
		-webkit-box-pack:start;
		flex-direction:row;
		align-items:center;
		justify-content:flex-start;
		padding:20px 50px;
		position:relative;
		padding-right:30px;
	}
	.common_parents_state_box__2gFsM .common_state_list__TV0DT:nth-child(1){
		padding-left:50px;
		width:100%;
	}
	.common_parents_state_box__2gFsM .common_state_list__TV0DT:nth-child(2){
		padding-left:50px;
	}
	.common_parents_state_box__2gFsM .common_state_list__TV0DT:nth-child(3){
		padding-left:50px;
		width:100%;
	}
	.common_parents_state_box__2gFsM .common_state_list__TV0DT:nth-child(4){
		padding-left:50px;
	}
	
	.common_state_list__TV0DT .common_info_popup__25W_w{
		width:100%;
	}
	.common_state_list__TV0DT span{
		margin-bottom:0;
		width:30%;
	}
	.common_state_list__TV0DT p{
		width:70%;
	}
	.common_state_list__TV0DT p.common_ellip__2foBj{
		width:70%;
	}
	.common_mypage_stuedent_info__2waua .common_line__RCi7Y{
		width:80%;
	}
	.common_student_state_area__2A2tX{
		width:70%;
	}
	.common_student_state_box__Ff_ya{
		width:100%;
		height:auto;
		display:-webkit-box;
		-webkit-box-pack:center;
		-webkit-box-align:center;
		display:flex;
		align-items:center;
		justify-content:center;
		padding:20px;
		box-sizing:border-box;
		margin-top:60px;
	}
	/*학습자 정보/관리 페이지에 처음 들어왔을때 새로운 학습자가 생성되었을때 클래스 student_state_box에 클래스 new 추가 */
	.common_student_state_box__Ff_ya.common_new__NHR6G::after{
		top:330px;
	}
	.common_student_state_box__Ff_ya .common_state_head__160ZN{
		height:inherit;
		width:40%;
		-webkit-box-pack:center;
		justify-content:center;
		flex-direction:column;
		border:none;
		padding-left:0;
	}
	.common_student_state_box__Ff_ya .common_state_head__160ZN .common_profile_wrap__2OQ0C{
		margin-right:0; 
	}
	.common_student_state_box__Ff_ya .common_state_head__160ZN p{
		width:80%;
		margin-top:10px;
	}
	.common_student_state_box__Ff_ya .common_right_area__3060r{
		width:60%;
		height:auto;
		flex-direction:column;
		border-left:1px solid #e6e6e6;
	}
	.common_student_state_box__Ff_ya .common_state_list__TV0DT{
		height:auto;
		width:100%;
		-webkit-box-align:center;
		-webkit-box-pack:start;
		flex-direction:row;
		align-items:center;
		justify-content:flex-start;
		padding:20px 40px;
		position:relative;
	}
	.common_student_state_box__Ff_ya .common_state_list__TV0DT:nth-child(1){
		width:100%;
	}
	.common_student_state_box__Ff_ya .common_state_list__TV0DT:nth-child(2){
		width:100%;
	}
	.common_student_state_box__Ff_ya .common_state_list__TV0DT span{
		margin:0;
	}
	.common_student_state_box__Ff_ya .common_state_list__TV0DT:last-child{
		width:100%;
		height:auto;
		justify-content:center;
	}
	.common_student_state_box__Ff_ya .common_state_list__TV0DT:last-child button{
		margin-top:0;
		margin-left:10px;
	}

	/*mypage_parents_info*/
	.common_mypage_parents_info__6vf6J .common_my_tit__38b1N{
		margin-top:60px;
	}
	.common_mypage_parents_info__6vf6J .common_parents_info_area__3V1oO{
		width:80%;
		height:auto;
		padding:30px 40px;
	}
	.common_parents_info_area__3V1oO ul{
		height:auto;
	}
	.common_parents_info_area__3V1oO li .common_head__3zcNA{
		width:35%;
	}
	.common_parents_info_area__3V1oO .common_right_area__3060r{
		width:65%;
	}
	.common_parents_info_area__3V1oO p{
		padding-left:30px;
	}
	.common_parents_info_area__3V1oO p.common_info__3UlbF{
		font-size:12px;
		line-height:1.5;
		margin-top:10px;
	}
	.common_parents_info_area__3V1oO input{
		width:182px;
		height:40px;
		padding:10px 30px;
		margin-right:20px;
		font-size:14px;
	}
	.common_parents_info_area__3V1oO input::-webkit-input-placeholder{
		font-size:12px;
	}
	.common_parents_info_area__3V1oO input:-ms-input-placeholder{
		font-size:12px;
	}
	.common_parents_info_area__3V1oO input::placeholder{
		font-size:12px;
	}
	.common_parents_info_area__3V1oO button{
		width:100px;
		height:35px;
		font-size:12px;
	}
	.common_parents_info_area__3V1oO li.common_adress_area__2wW7n{
		height:214px;
	}
	.common_parents_info_area__3V1oO li.common_adress_area__2wW7n .common_top_area__1Ir0S input{
		width:176px;
	}
	.common_parents_info_area__3V1oO li.common_adress_area__2wW7n input{
		width:315px;
	}
	.common_parents_info_area__3V1oO li.common_sms_add__3m9BZ{
		height:114px;
	}
	.common_parents_info_area__3V1oO li.common_sms_add__3m9BZ .common_right_area__3060r{
		flex-direction:column;
		box-sizing:border-box;
		padding:20px 0;
	}
	.common_parents_info_area__3V1oO li.common_sms_add__3m9BZ .common_top_area__1Ir0S{
		display:-webkit-box;
		-webkit-box-align:center;
		-webkit-box-pack:start;
		display:flex;
		align-items:center;
		justify-content:flex-start;
		width:100%;
	}
	.common_parents_info_area__3V1oO .common_radio_btn_area__3nYLt span{
		margin-right:40px;
	}

	/*mypage_pay_info*/
	.common_mypage_pay_info__Oxy1- .common_my_tit__38b1N{
		margin-top:60px;
	}
	.common_mypage_pay_info__Oxy1- .common_pay_info_area__2GMDi{
		width:80%;
		margin-bottom:100px;
	}
	.common_pay_info_list_wrap__35I6x li.common_list_head__3Dy0F div{
		width:19%;
	}
	.common_pay_info_list_wrap__35I6x li.common_list_head__3Dy0F div:nth-child(2){
		width:19%;
	}
	.common_pay_info_list_wrap__35I6x li.common_list_head__3Dy0F div:nth-child(3){
		width:12%;
	}
	.common_pay_info_list_wrap__35I6x li.common_list_head__3Dy0F div:nth-child(4){
		width:14.5%;
	}
	.common_pay_info_list_wrap__35I6x li.common_list_head__3Dy0F div:nth-child(5){
		width:14.5%; 
	}
	.common_pay_info_list_wrap__35I6x li.common_list_head__3Dy0F div:nth-child(6){
		width:21%;
	}
	.common_pay_info_list_wrap__35I6x ul.common_top_area__1Ir0S{
		width:38%;
		height:100%;
		position:relative;
	}
	.common_pay_info_list_wrap__35I6x ul.common_bottom_area__16Bx2{
		width:62%;
		height:100%;
		position:relative;
	}
	.common_pay_info_list_wrap__35I6x li.common_list__1inqC li{
		width:50%;
	}
	.common_pay_info_list_wrap__35I6x li.common_list__1inqC .common_top_area__1Ir0S li:nth-child(2){
		width:50%;
	}
	.common_pay_info_list_wrap__35I6x li.common_list__1inqC .common_bottom_area__16Bx2 li:nth-child(1){
		width:19%;
	}
	.common_pay_info_list_wrap__35I6x li.common_list__1inqC .common_bottom_area__16Bx2 li:nth-child(2){
		width:23.5%;
	}
	.common_pay_info_list_wrap__35I6x li.common_list__1inqC .common_bottom_area__16Bx2 li:nth-child(3){
		width:23.5%; 
	}
	.common_pay_info_list_wrap__35I6x li.common_list__1inqC .common_bottom_area__16Bx2 li:nth-child(4){
		width:34%;
	}
	.common_pay_info_list_wrap__35I6x .common_list__1inqC .common_date__U96yZ{
		font-size:14px;
	}
	.common_pay_info_list_wrap__35I6x .common_list__1inqC .common_name__3XKX4{
		font-size:14px;
	}
	.common_pay_info_list_wrap__35I6x .common_list__1inqC a{
		width:100px;
	}

	/*mypage_pay_info_more*/
	.common_mypage_pay_info_more__JCcY_ .common_my_tit__38b1N{
		margin-top:60px;
	}
	.common_mypage_pay_info_more__JCcY_ .common_pay_info_more_area__2JMyP{
		width:80%;
		margin-bottom:100px;
	}
	.common_pay_info_more_area__2JMyP ul{
		width:100%;
	}

	/*mypage_student_register*/
	.common_mypage_student_register__28b__ .common_my_tit__38b1N{
		margin-top:60px;
	}
	.common_mypage_student_register__28b__ .common_student_register_area__2_Hpz{
		width:80%;
		height:auto;
		padding:0 40px;
	}
	.common_student_register_area__2_Hpz ul{
		padding:0 20px;
		box-sizing:border-box;
	}
	.common_student_register_area__2_Hpz li:first-child{
		height:146px;
	}
	.common_student_register_area__2_Hpz li .common_head__3zcNA{
		height:100%;
		width:35%;
		padding-left:0;
	}
	.common_student_register_area__2_Hpz .common_right_area__3060r{
		width:65%;
	}
	.common_student_register_area__2_Hpz p.common_info__3UlbF{
		margin-top:10px;
	}
	.common_student_register_area__2_Hpz input{
		width:190px;
	}

	/*mypage_student_infofix*/
	.common_mypage_student_infofix__1H8xR .common_student_register_area__2_Hpz li.common_infofix_vertical__3wIUi{
		height:128px;
	}
	.common_mypage_student_infofix__1H8xR .common_student_register_area__2_Hpz li:last-child{
		margin-bottom:0px;
	}
	.common_student_register_area__2_Hpz li.common_select_list__1_Qkr:first-child{
		height:40px;
	}
	
	/* 자주묻는질문 */
	.common_faq_tab__2Ju4A .common_faq_area__3FH6w{
		width:100%;
	}
	
}
/*모바일 세로*/
@media screen and (min-width:320px) and (max-width:767px){
	.common_inner__1cU6t{
		width:90%;
	}
	
	/*header*/
	.common_header__3rYGR .common_inner__1cU6t{
		width:90%;
	}
	.common_header__3rYGR .common_logo__32lKi{
		margin:0 auto;
		margin-top:12px;
		height:44px;
	}
	.common_menu_wrapper__3UAYG{
		display:none;
	}
	/*login&mypage*/
	.common_header__3rYGR .common_button__2VIg2{
		display:none; 
	}
	/*mobile_menu_btn_area*/
	.common_mobile_menu_btn_area__u63Re{
		display:block; 
	}
	.common_mobile_menu_area__2aife{
		width:70%;
	}
	
	.common_login_bg_wrapper__3YAS5{
		background:#ffffff; 
		height:auto;
	}
	.common_login_title__2TZdC{
		font-size:26px;
	}
	/*로그인 login.html*/
	.common_login_area__3MH8G{
		width:98%; 
		max-width:420px;
	}
	.common_login_area__3MH8G a.common_google__3cloo{
		width:100%; 
		max-width:348px;
	}
	
	/*password_reset_area*/
	.common_password_reset_area__3lxFX{
		width:98%; 
		max-width:500px;
	}
	.common_password_reset_area__3lxFX .common_password_reset_wrap__2oZax{
		height:auto;
	}

	/*join_area*/
	.common_join_area__YlYC1{
		width:98%; 
		max-width:420px;
	}
	.common_join_area__YlYC1 .common_input_area__2Lws9.common_email__1aJmm .common_input_area__2Lws9{
		width:70%;
	}
	
	/*join_group_area*/
	.common_join_group_area__TDhWT{
		width:98%; 
		max-width:420px;
	}
	.common_join_group_area__TDhWT .common_join_group_wrap__3fqH5 .common_select_area__2EsHG span{
		font-size:15px;
	}
	
	/*stuedent_make_area*/
	.common_stuedent_make_area__17zR_{
		width:98%; 
		max-width:420px;
	}
	.common_stuedent_make_area__17zR_ .common_input_area__2Lws9.common_nikname__iD7Wu .common_input_area__2Lws9{
		width:70%;
	}
	
	/*join_agree*/
	.common_login_bg_wrapper__3YAS5.common_join_agree_bg_wrapper__1XWjI{
		min-height:800px;
	}
	.common_join_agree_area__3wfyj{
		width:98%; 
		max-width:1000px; 
		min-height:790px;
	}
	.common_join_agree_area__3wfyj a.common_agree_logo__cZRcY img{
		margin-top:20px;
	}
	.common_join_agree_area__3wfyj .common_join_agree_wrap__2Nr7l .common_agree_use_wrap__39peX{
		height:160px;
	}
	.common_agree_privacy_wrap__rTGa2 .common_box_wrap__1S8pe .common_title__1txLf span{
		font-size:16px;
		width:70%;
	}
	.common_agree_privacy_wrap__rTGa2 .common_box_wrap__1S8pe .common_title__1txLf span:first-child{
		width:30%;
	}
	.common_agree_privacy_wrap__rTGa2 .common_box_wrap__1S8pe .common_list__1inqC span{
		width:70%;
		font-size:14px;
		padding:20px;
	}
	.common_agree_privacy_wrap__rTGa2 .common_box_wrap__1S8pe .common_list__1inqC span.common_name__3XKX4{
		width:30%;
		font-size:15px;
		padding:0;
	}
	
	/*footer*/
	section.common_footer_section__23XeD{
		height:auto;
		background:#1a1a1a;
		padding-top:0;
		padding-bottom:0;
		top:0;
		position:relative;
	}
	.common_footer__18aab{
		height:auto;
	}
	.common_footer__18aab .common_inner90__3qaGh{
		flex-direction:column-reverse;
	}
	.common_f_left__1epgt{
		width:100%;
		height:auto;
		margin:20px 0;
	}
	.common_f_left__1epgt ul{
		align-items:center;
	}
	.common_f_left__1epgt li{
		text-align:center;
	}
	.common_f_right__-R8OE{
		width:100%;
		height:auto;
	}
	.common_f_right__-R8OE ul{
		align-items:center;
		margin-top:40px;
	}
	
	/*index.html*/
	.common_stakato__e3n3a::before{
		width:.5vh;
		height:.5vh;
		top:-.7vh;
	}
	.common_index_tit__3e1q3 .common_stakato__e3n3a::before{
		width:.8vh;
		height:.8vh;
		top:-.3vh;
	}
	.common_sub_title__hQAyC{
		font-size:.8em;
	}
	p.common_txt__3s3ZA{
		font-size:12px;
	}
	section{
		height:100vh;
		position:fixed;
		top:100vh;
	}
	/*main_banner_area */
	.common_bn_content_wrap__2v3GE{
		padding-top:80px;
		padding-bottom:20vh;
	}
	.common_bn_content_wrap__2v3GE .common_bn_tit__3dw1P{
		font-size:1.9em;
	}
	.common_bn_content_wrap__2v3GE .common_bn_txt__3TdUu{
		font-size:.7em;
	}
	.common_bn_content_wrap__2v3GE .common_inner90__3qaGh{
		-webkit-box-pack:start;
		flex-direction:column-reverse;
		justify-content:flex-start;
	}
	.common_bn_content_wrap__2v3GE img{
		height:35vmin;
		margin-top:0;
		margin-bottom:12vh;
	}

	/*introduce_area*/
	.common_introduce_area__2LRd7{
		padding-top:80px;
		padding-bottom:10vh;
	}
	.common_introduce_area__2LRd7 .common_inner90__3qaGh{
		justify-content:center;
	}
	.common_introduce_area__2LRd7 .common_top_area__1Ir0S{
		height:auto;
		flex-direction:column-reverse;
	}
	.common_introduce_area__2LRd7 .common_top_area__1Ir0S img{
		height:30vh;
		margin-right:0;
	}
	.common_introduce_area__2LRd7 .common_top_area__1Ir0S .common_right_area__3060r{
		display:-webkit-box;
		-webkit-box-align:Center;
		display:flex;
		flex-direction:column;
		align-items:center;
		margin-bottom:2.5vh;
	}
	.common_introduce_area__2LRd7 .common_right_area__3060r p{
		font-size:12px;
		text-align:center;
		margin-top:1.5vh;
	}
	.common_introduce_area__2LRd7 .common_right_area__3060r p br{
		display:none;
	}
	.common_introduce_area__2LRd7 span.common_index_tit__3e1q3{
		text-align:center;
		margin-top:1vh;
	}
	.common_introduce_area__2LRd7 .common_ym_who_popup__3du5G.common_on__2QUU7{
		width:100%;
		height:104%;
		padding:4vmin;
		flex-direction:column;
		border-radius:30px;
	}
	.common_introduce_area__2LRd7 .common_ym_who_popup__3du5G.common_on__2QUU7 .common_ym_wyo_box__3T_QO{
		width:100%;
		margin-top:3vh;
	}
	.common_ym_who_popup__3du5G.common_on__2QUU7 .common_ym_wyo_box__3T_QO:first-child{
		width:100%;
		margin:0;
	}
	.common_ym_who_popup__3du5G.common_on__2QUU7 .common_ym_wyo_box__3T_QO:nth-child(2){
		width:100%;
		margin:0;
		margin-top:3vh;
	}
	.common_ym_who_popup__3du5G.common_on__2QUU7 .common_ym_wyo_box__3T_QO span{
		font-size:16px;
	}
	.common_ym_who_popup__3du5G.common_on__2QUU7 .common_ym_wyo_box__3T_QO p{
		margin-top:1vh;
		font-size:12px;
	}
	.common_introduce_area__2LRd7 .common_ym_who_btn__1oA6w{
		width:auto;
		height:auto;
		box-sizing:border-box;
		padding:1vh 15vmin;
		margin-top:6vh;
	}
	.common_introduce_area__2LRd7 .common_ym_who_btn__1oA6w span{
		font-size:14px;
	}

	/*ym_world_area*/
	.common_ym_world_area__3ao46{
		padding-top:80px;
		padding-bottom:10vh;
	}
	.common_ym_world_area__3ao46 .common_inner90__3qaGh{
		justify-content:center;
	}
	.common_ym_world_area__3ao46 .common_index_tit__3e1q3{
		-webkit-box-align:center;
		flex-direction:column;
		align-items:center;	
	}
	.common_ym_world_area__3ao46 .common_index_tit__3e1q3 .common_br__2s69l{
		margin-right:0;
	}
	.common_ym_world_area__3ao46 p.common_txt__3s3ZA{
		margin-top:1vh;
	}
	.common_ym_world_area__3ao46 p.common_txt__3s3ZA br{
		display:none;
	}
	.common_ym_world_area__3ao46 .common_bottom_area__16Bx2{
		height:auto;
		width:100%;
		margin-top:1.5vh;
		flex-direction:column-reverse;
	}
	.common_ym_world_area__3ao46 .common_left_area__2Nwxl{
		height:30vh;
		margin-left:0;
		margin-top:3.5vh;
	}
	.common_ym_world_area__3ao46 .common_left_area__2Nwxl .common_loby__2dEHE{
		height:135%;
		margin-top:0;
	}
	.common_ym_world_area__3ao46 .common_plus_wrap__3LtaP .common_plus__2zQZU{
		width:3vh;
		height:3vh;
	}
	.common_ym_world_area__3ao46 .common_plus__2zQZU:nth-child(1){
		top:16%;
		left:34%;
	}
	.common_ym_world_area__3ao46 .common_plus__2zQZU:nth-child(2){
		top:-10%;
		left:37%;
	}
	.common_ym_world_area__3ao46 .common_plus__2zQZU:nth-child(3){
		top:-16%;
		left:58%;
	}
	.common_ym_world_area__3ao46 .common_plus__2zQZU:nth-child(4){
		top:-15%;
		left:69%;
	}
	.common_ym_world_area__3ao46 .common_plus__2zQZU:nth-child(5){
		top:11%;
		left:46%;
	}
	.common_ym_world_area__3ao46 .common_plus__2zQZU:nth-child(6){
		top:-11%;
		left:35%;
	}
	.common_ym_world_area__3ao46 .common_right_area__3060r{
		height:auto;
		margin-left:0;
		top:1.5vh;
	}
	.common_ym_world_area__3ao46 .common_building_wrap__3bCH9{
		width:80%;
		height:14vmax;
		padding:0;
		border:2px dashed #ffffff;
		border-radius:30px;
	}
	.common_ym_world_area__3ao46 .common_building__1tZuc{
		padding:1.5vh;
		justify-content:center;
	}
	.common_ym_world_area__3ao46 .common_building__1tZuc img{
		display:none;
	}
	.common_ym_world_area__3ao46 .common_building__1tZuc span{
		font-size:13px;
		margin-bottom:1vh;
		padding:0 2vw;
	}
	.common_ym_world_area__3ao46 .common_building__1tZuc p{
		font-size:12px;
	}
	.common_ym_world_area__3ao46 .common_building__1tZuc p br{
		display:none;
	}

	/*ym_difference_area*/
	.common_ym_difference_area__GVl5X{
		padding-top:80px;
		padding-bottom:10vh;
	}
	.common_ym_difference_area__GVl5X .common_inner90__3qaGh{
		justify-content:center;
	}
	.common_ym_difference_area__GVl5X .common_tabbtn_area__Ixj-V .common_btn__3q49s:first-child{
		margin-right:4.5vw;
	}
	.common_ym_difference_area__GVl5X .common_tabbtn_area__Ixj-V .common_btn__3q49s{
		font-size:14px;
	}
	.common_ym_difference_area__GVl5X .common_tabbtn_area__Ixj-V .common_btn__3q49s::before{
		margin-right:1vw;
	}
	.common_ym_difference_area__GVl5X .common_middle_area__2Omh4 p{
		font-size:12px;
	}
	.common_ym_difference_area__GVl5X .common_img_area__3KniS{
		height:27vmin;
		margin-top:6vh;
	}

	/*ym_myroom_area*/
	.common_ym_myroom_area__1iNyx{
		padding-top:80px;
		padding-bottom:10vh;
	}
	.common_ym_myroom_area__1iNyx .common_inner90__3qaGh{
		justify-content:center;
	}
	.common_ym_myroom_area__1iNyx .common_index_tit__3e1q3{
		flex-direction:column;
	}
	.common_ym_myroom_area__1iNyx .common_index_tit__3e1q3 .common_br__2s69l{
		margin-right:0;
	}
	.common_ym_myroom_area__1iNyx p.common_txt__3s3ZA br{
		display:none;
	}
	.common_ym_myroom_area__1iNyx .common_bottom_area__16Bx2{
		margin-top:2vh;
		flex-direction:column;
		width:100%;
		height:auto;
	}
	.common_ym_myroom_area__1iNyx .common_box__2MqeC{
		transform:scale(1);
		top:0;
		width:65%;
		height:5vh;
		box-shadow:0 0 10px 0 rgb(0,0,0,.35);
		transition:all .3s;
		border-radius:30px;
		margin:3px 0;
	}
	.common_ym_myroom_area__1iNyx .common_box__2MqeC.common_on__2QUU7{
		transform:scale(1);
		top:0;
		height:auto;
		box-shadow:0 0 10px 0 rgb(0,0,0,.35);
		margin:5px 0;
	}
	.common_ym_myroom_area__1iNyx .common_box__2MqeC:hover{
		transform:scale(1);
	}
	.common_ym_myroom_area__1iNyx .common_box__2MqeC.common_on__2QUU7:hover{
		transform:scale(1);
	}
	.common_ym_myroom_area__1iNyx .common_box__2MqeC .common_cover__1DbYf{
		font-size:10px;
	}
	.common_ym_myroom_area__1iNyx .common_box__2MqeC .common_content__2kYUC{
		padding:1.5vh;
	}
	.common_ym_myroom_area__1iNyx .common_content__2kYUC span{
		font-size:14px;
	}
	.common_ym_myroom_area__1iNyx .common_content__2kYUC p{
		font-size:12px;
	}
	.common_ym_myroom_area__1iNyx .common_box__2MqeC .common_content__2kYUC .common_bottom_area__16Bx2{
		margin-top:0;
	}
	.common_ym_myroom_area__1iNyx .common_content__2kYUC .common_graph_area3__22g6W .common_count_wrap__1OTx0{
		height:29%;
	}
	.common_ym_myroom_area__1iNyx .common_content__2kYUC .common_graph_area3__22g6W .common_count1__UguBq{
		top:58%;
	}
	.common_ym_myroom_area__1iNyx .common_content__2kYUC .common_graph_area3__22g6W .common_count2__3ZZTt{
		top:58%;
	}
	.common_ym_myroom_area__1iNyx .common_content__2kYUC .common_graph_area3__22g6W .common_count3__3sNcj{
		top:58%;
	}
	.common_ym_myroom_area__1iNyx .common_content__2kYUC .common_graph_area3__22g6W .common_counting__1dwuc{
		font-size:1em;
	}
	.common_ym_myroom_area__1iNyx .common_content__2kYUC .common_graph_area1_graph__gMu1n{
		width:80%;
	}

	/*brain_music_area*/
	.common_brain_music_area__NtTkf{
		padding-top:80px;
		padding-bottom:10vh;
		background:url(/static/media/musicroom_mobile.5dd819f9.png) no-repeat center;
		background-size:cover;
	}
	.common_brain_music_area__NtTkf .common_inner90__3qaGh{
		justify-content:center;
	}
	.common_brain_music_area__NtTkf .common_index_tit__3e1q3{
		width:100%;
		text-align:center;
		padding-left:0;
	}
	.common_brain_music_area__NtTkf .common_sub_title__hQAyC{
		text-align:center;
		padding-left:0;
	}
	.common_brain_music_area__NtTkf .common_wrap__ornby{
		margin-left:0;
		top:0;
		align-items:center;
	}
	.common_brain_music_area__NtTkf .common_wrap__ornby ul{
		display:-webkit-box;
		-webkit-box-align:center;
		display:flex;
		flex-direction:column;
		align-items:center;
		margin-top:4vh;
	}
	.common_brain_music_area__NtTkf .common_wrap__ornby li{
		padding:1vh 4vw;
		width:100%;
		height:auto;
		min-width:auto;
	}
	.common_brain_music_area__NtTkf .common_wrap__ornby li:nth-child(2){
		margin:2vh 0;
	}
	.common_brain_music_area__NtTkf .common_wrap__ornby li .common_play_wrap__3KOSn{
		width:8vh;
		height:8vh;
		margin-right:4vw;
	}
	.common_brain_music_area__NtTkf .common_wrap__ornby li .common_right_area__3060r{
		width:70%;
	}
	.common_brain_music_area__NtTkf .common_wrap__ornby li .common_right_area__3060r span{
		font-size:14px;
	}
	.common_brain_music_area__NtTkf .common_wrap__ornby li .common_right_area__3060r p{
		font-size:12px;
	}

	/*coding_block_area*/
	.common_coding_block_area__2gmOW{
		padding-top:80px;
		padding-bottom:10vh;
	}
	.common_coding_block_area__2gmOW .common_inner90__3qaGh{
		justify-content:center;
	}
	.common_coding_block_area__2gmOW .common_index_tit__3e1q3{
		flex-direction:column;
	}
	.common_coding_block_area__2gmOW .common_bottom_area__16Bx2{
		margin-top:2vh;
		flex-direction:column;
		height:auto;
	}
	.common_coding_block_area__2gmOW .common_cont__1FF3G{
		width:90%;
		height:6vh;
		overflow:hidden;
		padding:2vmin;
	}
	.common_coding_block_area__2gmOW .common_cont__1FF3G:last-child{
		width:100%;
	}
	.common_coding_block_area__2gmOW .common_cont__1FF3G:last-child br{
		display:none;
	}
	.common_coding_block_area__2gmOW .common_cont__1FF3G .common_num__1L4QU{
		height:4.5vh;
	}
	.common_coding_block_area__2gmOW .common_cont__1FF3G .common_title__1txLf{
		font-size:14px;
		visibility:hidden;
	}
	.common_coding_block_area__2gmOW .common_cont__1FF3G .common_sub_tit__3_GE4{
		font-size:11px;
		visibility:hidden;
	}
	.common_coding_block_area__2gmOW .common_cont__1FF3G p{
		font-size:12px;
		visibility:hidden;
	}
	.common_coding_block_area__2gmOW .common_cont__1FF3G img:last-child{
		width:20vw;
		visibility:hidden;
	}
	.common_coding_block_area__2gmOW .common_cont__1FF3G.common_on__2QUU7 .common_title__1txLf{
		visibility:visible;
	}
	.common_coding_block_area__2gmOW .common_cont__1FF3G.common_on__2QUU7 .common_sub_tit__3_GE4{
		visibility:visible;
	}
	.common_coding_block_area__2gmOW .common_cont__1FF3G.common_on__2QUU7 p{
		visibility:visible;
	}
	.common_coding_block_area__2gmOW .common_cont__1FF3G.common_on__2QUU7 img:last-child{
		visibility:visible;
	}
	.common_coding_block_area__2gmOW .common_cont__1FF3G.common_on__2QUU7{
		transform:scale(1);
		margin:2vh 0;
		overflow:visible;
		height:auto;
	}
	.common_coding_block_area__2gmOW .common_cont__1FF3G.common_on__2QUU7:hover{
		transform:scale(1);
	}
	.common_coding_block_area__2gmOW .common_cont__1FF3G:hover{
		transform:scale(1);
	}
	.common_coding_block_area__2gmOW .common_cont__1FF3G:last-child.common_on__2QUU7{
		margin-left:0;
	}
	.common_coding_block_area__2gmOW .common_arrow_wrap__2RrYr{
		display:none;
	}

	/*yf_area*/
	.common_yf_area__4xLfs{
		padding-top:80px;
		padding-bottom:10vh;
	}
	.common_yf_area__4xLfs .common_content_area__2tBPk{
		height:auto;
		width:100%;
		-webkit-box-align:Center;
		flex-direction:column;
		align-items:center;
		margin-top:2vh;
	}
	.common_yf_area__4xLfs .common_content_area__2tBPk .common_btn_area__3WMBi{ 
		width:100%;
		height:36px;
		padding-bottom:0;
		margin-right:0;
		margin-left:0;
		margin-bottom:1vh;
		-webkit-box-pack:center;
		flex-direction:row;
		justify-content:center;
	}
	.common_yf_area__4xLfs .common_content_area__2tBPk .common_btn_area__3WMBi li{
		width:auto;
		height:60%;
		margin:0 3vw;
	}
	.common_yf_area__4xLfs .common_content_area__2tBPk .common_btn_area__3WMBi li.common_on__2QUU7{
		height:100%;
	}
	.common_yf_area__4xLfs .common_content_area__2tBPk .common_btn_area__3WMBi li.common_on__2QUU7:hover{
		height:100%;
	}
	.common_yf_area__4xLfs .common_content_area__2tBPk .common_btn_area__3WMBi li:hover{
		height:80%;
	}
	.common_yf_area__4xLfs .common_yf_img_area__3eH7p{
		height:160px;
		margin-right:0;
	}
	.common_yf_area__4xLfs .common_yf_img_area__3eH7p img{
		height:100%;
	}
	.common_yf_area__4xLfs .common_yf_info_area__3AaKI{
		width:100%;
		height:90px;
		margin-top:15px;
		justify-content:flex-start;
	} 
	.common_yf_area__4xLfs .common_yf_info_area__3AaKI .common_album_area__2b8yB{
		display:none;
	}	
	.common_yf_area__4xLfs .common_yf_info_area__3AaKI .common_txt_area__25xQw{
		width:100%;
		height:auto;
	}	
	.common_yf_area__4xLfs .common_yf_info_area__3AaKI .common_txt_box__3wTLx{
		width:100%;
		height:auto;
		padding:3vmin;
		-webkit-box-align:center;
		-webkit-box-pack:center;
		align-items:center;
		justify-content:center;
	}	
	.common_yf_area__4xLfs .common_yf_info_area__3AaKI .common_txt_box__3wTLx span{
		font-size:14px;
	}
	.common_yf_area__4xLfs .common_yf_info_area__3AaKI .common_txt_box__3wTLx p{
		font-size:12px;
		text-align:center;
	}

	/* floating_paging_btn_wrap */
	.common_floating_paging_btn_wrap__10eqW{
		visibility:hidden;
	}
	
	/*use.html*/
	#common_content__2kYUC .common_wrap__ornby{width:100%;}
	/*privacy.html*/
	#common_content__2kYUC .common_box_wrap__1S8pe .common_title__1txLf span{
		font-size:16px;
		width:70%;
	}
	#common_content__2kYUC .common_box_wrap__1S8pe .common_title__1txLf span:first-child{
		width:30%;
	}
	#common_content__2kYUC .common_box_wrap__1S8pe .common_list__1inqC span{
		width:70%;
		font-size:14px;
		padding:20px;
	}
	#common_content__2kYUC .common_box_wrap__1S8pe .common_list__1inqC span.common_name__3XKX4{
		width:30%;
		font-size:15px;
		padding:0;
	}
	#common_content__2kYUC .common_wrap__ornby p{
		padding:10px 0;
		font-size:16px;
	}
	
	/*pay.html*/
	.common_pay_wrap__3jp2v .common_product_area__19QlX{
		width:100%;
		padding:20px;
	} 
	.common_pay_wrap__3jp2v .common_product_area__19QlX .common_top_top_area__3ryUB{
		flex-direction:column;
		height:auto;
		padding:10px;
	}
	.common_product_area__19QlX .common_top_top_area__3ryUB img{
		margin-right:0;
		margin-bottom:10px;
	}
	.common_pay_wrap__3jp2v .common_product_area__19QlX .common_title__1txLf br{
		display:block;
	}
	.common_pay_wrap__3jp2v .common_product_area__19QlX ul{
		width:100%;
		height:auto;
		position:relative;
		display:-webkit-box;
		-webkit-box-pack:center;
		display:flex;
		justify-content:center;
		flex-direction:column;
	}
	.common_pay_wrap__3jp2v .common_product_area__19QlX li{
		width:100%;
		padding:20px;
		padding-top:12px;
		margin:0;
		margin-bottom:20px;
	}
	.common_pay_wrap__3jp2v .common_product_area__19QlX li .common_scratch__1OsJ7{
		width:90%;
	}
	.common_pay_wrap__3jp2v .common_product_name__c4yj2{
		padding-bottom:0;
		border:none;
	}
	.common_pay_wrap__3jp2v .common_product_price__18u_E{
		border:none;
	}
	.common_pay_wrap__3jp2v .common_student_count_area__38JPj{
		padding:0;
		border:none;
	}
	.common_pay_wrap__3jp2v .common_student_count_area__38JPj .common_count_area__QVG2P{
		margin-bottom:16px;
	}
	.common_pay_wrap__3jp2v .common_student_count_area__38JPj .common_count_btn_area__2E1BV{
		height:40px;
	}
	.common_pay_wrap__3jp2v .common_product_total_price_area__3WcMj{
		height:auto;
		padding:20px;
	}
	.common_pay_wrap__3jp2v .common_product_total_price_area__3WcMj span{
		font-size:15px;
	}
	.common_pay_wrap__3jp2v .common_btn_area__3WMBi{
		width:100%;
	}
	.common_pay_wrap__3jp2v .common_pay_guide__1EGI8{
		width:100%;
		height:auto;
		padding:0;
		padding-bottom:60px;
		-webkit-box-align:center;
		-webkit-box-pack:center;
		align-content:center;
		justify-content:center;
		flex-direction:column;
		flex-wrap:no-wrap;
	}
	.common_pay_wrap__3jp2v .common_pay_guide__1EGI8 li{
		margin-right:0;
	}
	.common_pay_wrap__3jp2v .common_pay_guide__1EGI8 li br{
		display:block;
	}
	.common_pay_wrap__3jp2v .common_pay_guide__1EGI8 li:first-child::before{
		position:relative;
		top:-9px;
	}
	.common_pay_wrap__3jp2v .common_pay_guide__1EGI8 li:nth-child(2)::before{
		position:relative;
		top:-20px;
	}
	.common_pay_wrap__3jp2v .common_pay_guide__1EGI8 li a{
		text-decoration:underline;
	}
	.common_pay_wrap__3jp2v .common_pay_guide__1EGI8 li a::before{
		display:none;
	}
	.common_pay_wrap__3jp2v .common_pay_guide__1EGI8 li a::after{
		position:relative;
		top:11px;
		left:-66px;
	}
	.common_pay_wrap__3jp2v .common_pay_product_info_top_line__1xvcW::after{
		width:70%;
		max-width:334px;
	}
	.common_pay_wrap__3jp2v .common_pay_product_info_area__1w_Gx{
		padding-bottom:120px;
		background-size:110% auto;
		padding-top:54%;
	}
	.common_pay_wrap__3jp2v .common_pay_product_title__3E5aW{
		font-size:22px;
	}
	.common_pay_wrap__3jp2v .common_pay_product_3d_area__2Yc3K{
		height:auto;
		background:#ffffff;
		border:20px solid #bc9d70;
		padding:20px 0;
		margin-top:70px;
	}
	.common_pay_wrap__3jp2v .common_pay_product_3d_area__2Yc3K::before{
		content:'';
		display:block;
		width:14%;
		height:106px;
		background:url(/static/media/pay_product_box_bg1_top.a74e719b.png) no-repeat center;
		background-size:100% auto;
		position:absolute;
		top:-80px;
		left:50%;
		transform:translateX(-50%);
	}
	.common_pay_wrap__3jp2v .common_pay_product_top_area__1GTNJ{
		width:100%;
		padding-left:30px;
		padding-right:0;
	}
	.common_pay_wrap__3jp2v .common_pay_product_3d_bottom_area__3nDMn{
		width:100%;
		-webkit-box-pack:center;
		align-items:center;
		justify-content:center;
		flex-direction:column;
	}
	.common_pay_wrap__3jp2v .common_pay_product_3d_bottom_area__3nDMn .common_img_area__3KniS{
		width:90%;
		margin-top:20px;
	}
	.common_pay_wrap__3jp2v .common_pay_product_3d_bottom_area__3nDMn img{
		width:100%;
		height:auto;
	}
	.common_pay_wrap__3jp2v .common_pay_product_3d_bottom_area__3nDMn .common_img_area__3KniS:first-child{
		width:90%;
	}
	.common_pay_wrap__3jp2v .common_pay_product_3d_bottom_area__3nDMn .common_img_area__3KniS:first-child p{
		width:100%;
		padding-left:0;
	}
	.common_pay_wrap__3jp2v .common_pay_product_3d_bottom_area__3nDMn .common_img_area__3KniS:nth-child(2){
		margin-left:0; 
		margin-right:0; 
	}
	.common_pay_wrap__3jp2v .common_pay_product_contents_area__1BR0F{
		height:auto;
		background:#ffffff;
		border:20px solid #bc9d70;
		padding:20px 0;
		margin-top:70px;
	}
	.common_pay_wrap__3jp2v .common_pay_product_contents_area__1BR0F::before{
		content:'';
		display:block;
		width:14%;
		height:100px;
		background:url(/static/media/pay_product_box_bg2_top.755d0a6c.png) no-repeat center;
		background-size:100% auto;
		position:absolute;
		top:-90px;
		left:50%;
		transform:translateX(-50%);
	}
	.common_pay_wrap__3jp2v .common_pay_product_contents_bottom_area__vR26S{
		width:100%;
	}
	.common_pay_wrap__3jp2v .common_pay_product_top_area__1GTNJ span::before{
		position:absolute;
		top:15px;
		left:-15px;
	}
	.common_pay_wrap__3jp2v .common_pay_product_top_area__1GTNJ p{
		padding-left:0;
		padding-right:10px;
	}
	.common_pay_wrap__3jp2v .common_pay_product_contents_bottom_area__vR26S .common_top_area__1Ir0S .common_img_area__3KniS div{
		-webkit-box-pack:center;
		flex-direction:column;
		justify-content:center;
	}
	.common_pay_wrap__3jp2v .common_pay_product_contents_bottom_area__vR26S .common_top_area__1Ir0S img{
		width:90%;
	}
	.common_pay_wrap__3jp2v .common_pay_product_contents_bottom_area__vR26S .common_top_area__1Ir0S img:nth-child(2){
		width:90%;
		margin-left:0;
		margin-top:20px;
	}
	.common_pay_wrap__3jp2v .common_pay_product_contents_bottom_area__vR26S p{
		width:100%;
		margin:0 auto;
		margin-top:12px;
	}
	.common_pay_wrap__3jp2v .common_pay_product_contents_bottom_area__vR26S .common_bottom_area__16Bx2{
		-webkit-box-align:Center;
		flex-direction:column;
		align-items:center;
	}
	.common_pay_wrap__3jp2v .common_pay_product_contents_bottom_area__vR26S p br.common_mobile__3lWUV{
		display:block;
	}
	.common_pay_product_contents_bottom_area__vR26S .common_bottom_area__16Bx2 .common_img_area__3KniS{
		width:90%;
	}
	.common_pay_product_contents_bottom_area__vR26S .common_bottom_area__16Bx2 .common_img_area__3KniS p{
		width:100%;
	}
	.common_pay_product_contents_bottom_area__vR26S .common_bottom_area__16Bx2 .common_img_area__3KniS img{
		width:100%;
	}
	.common_pay_product_contents_bottom_area__vR26S .common_bottom_area__16Bx2 .common_img_area__3KniS:last-child{
		width:100%;
		margin-left:0;
		margin-top:20px;
	}
	.common_pay_product_contents_bottom_area__vR26S .common_bottom_area__16Bx2 .common_img_area__3KniS:last-child p{
		width:90%;
	}
	.common_pay_product_contents_bottom_area__vR26S .common_bottom_area__16Bx2 .common_img_area__3KniS:last-child div{
		justify-content:Center;
	}
	.common_pay_product_contents_bottom_area__vR26S .common_bottom_area__16Bx2 .common_img_area__3KniS:last-child div img{
		width:44%;
	}
	.common_pay_wrap__3jp2v .common_pay_product_etc_area__3BH1H{
		height:auto;
		background:#bc9d70;
		padding:40px 0;
		margin-top:70px;
	}
	.common_pay_wrap__3jp2v .common_pay_product_etc_area__3BH1H::before{
		content:'';
		display:block;
		width:12%;
		height:100px;
		background:url(/static/media/pay_product_box_bg3_top.cc6c8754.png) no-repeat center;
		background-size:100% auto;
		position:absolute;
		top:-65px;
		left:50%;
		transform:translateX(-50%);
	}
	.common_pay_wrap__3jp2v .common_pay_product_etc_area__3BH1H .common_pay_product_top_area__1GTNJ{
		width:100%;
		padding-left:40px;
	}
	.common_pay_product_etc_area__3BH1H img{
		width:96%;
	}
	.common_pay_wrap__3jp2v .common_pay_bottom_btn_area__312Ua a{
		left:15%;
		width:80%;
		max-width:374px;
	}
	
	/* order.html */
	.common_order_wrap__3_JTa li .common_left_area__2Nwxl{
		width:40%;
		min-width:40%;
		padding-left:16px;
	}
	.common_order_wrap__3_JTa li .common_right_area__3060r{
		width:60%;
		flex-wrap:wrap;
		padding:20px 0;
		padding-left:16px;
	}
	.common_order_wrap__3_JTa li.common_how_to_pay_wrap__21JeV{
		height:200px;
	}
	.common_how_to_pay_wrap__21JeV .common_radio_btn_area__3nYLt{
		margin-right:0;
		width:100%;
	}


	/*download.html*/
	.common_download_area__1X_we{
		padding-bottom:100px;
	}
	.common_download_area__1X_we .common_download_wrap__3LJhK{
		width:90%; 
		padding:30px;
		padding-top:70px;
		border-radius:60px;
		margin-bottom:50px;
		margin-top:60px;
	}
	.common_download_wrap__3LJhK .common_top_top_area__3ryUB{
		-webkit-box-pack:center;
		-webkit-box-align:center;
		justify-content:center;
		align-items:center;
		flex-direction:column;
	}
	.common_download_wrap__3LJhK .common_left_area__2Nwxl{
		width:100%;
		-webkit-box-pack:center;
		-webkit-box-align:center;
		justify-content:center;
		align-items:center;
		position:relative;
	}
	.common_download_wrap__3LJhK .common_left_area__2Nwxl img{
		width:90%;
		max-width:323px;
	}
	.common_download_wrap__3LJhK .common_left_area__2Nwxl .common_download_title_wrap__2VDLX{
		width:100%;
	}
	.common_download_wrap__3LJhK .common_download_title_wrap__2VDLX img{
		width:100%;
		max-width:100%;
	}
	.common_download_wrap__3LJhK .common_left_area__2Nwxl p{
		text-align:center;
		font-size:22px;
	}
	.common_download_wrap__3LJhK .common_left_area__2Nwxl p br{
		display:none;
	}
	.common_download_wrap__3LJhK .common_left_area__2Nwxl p.common_sub_title__hQAyC{
		font-size:20px;
	}
	.common_download_wrap__3LJhK .common_down_link_area__FxAux{
		width:100%;
		height:auto;
	}
	.common_download_wrap__3LJhK .common_down_link_area__FxAux a{
		width:46%;
	}
	.common_download_wrap__3LJhK .common_down_link_area__FxAux img{
		width:100%;
		max-width:100%;
	}
	.common_download_wrap__3LJhK .common_right_area__3060r{
		width:100%;
		margin-top:40px;
	}
	.common_download_wrap__3LJhK .common_right_area__3060r .common_top_area__1Ir0S{
		height:auto;
	}
	.common_download_wrap__3LJhK .common_right_area__3060r .common_top_area__1Ir0S img{
		width:100%;
		height:auto;
	}
	.common_download_wrap__3LJhK .common_right_area__3060r .common_bottom_area__16Bx2{
		justify-content:center;
	}
	.common_download_wrap__3LJhK .common_right_area__3060r .common_bottom_area__16Bx2 img{
		width:48%;
		height:auto;
		margin:11px 1%;
	}
	.common_download_wrap__3LJhK .common_right_area__3060r .common_bottom_area__16Bx2 img:nth-child(2){
		margin:11px 1%;
	}
	.common_download_wrap__3LJhK .common_right_area__3060r .common_bottom_area__16Bx2 img:nth-child(5){
		margin:11px 1%;
	}
	.common_download_wrap__3LJhK .common_btn_area__3WMBi{
		margin-top:60px;
		height:100px;
	}
	.common_download_wrap__3LJhK .common_btn_area__3WMBi button{
		width:100%;
	}
	
	/*mypage_html*/
	/*mypage_tab_btn_area*/
	.common_mypage_tab_btn_area__103gK{
		height:auto;
	}
	.common_mypage_tab_btn_area__103gK ul{
		height:auto;
		flex-wrap:wrap;
	}
	.common_mypage_tab_btn_area__103gK li{
		height:40px;
		width:50%;
		padding:10px;
		margin:0;
	}
	.common_mypage_tab_btn_area__103gK li.common_on__2QUU7{
		border:none;
	}
	/*mypage_report*/
	.common_my_report_top_btn_area__2T17n{
		width:100%;
	}
	.common_mypage_report__3b5HF #common_report_student_list_select__3uRrL{
		width:187px;
	}
	.common_my_report_top_btn_area__2T17n button{
		width:100px;
	}
	.common_mypage_report__3b5HF .common_for_freeuser_info__FsOrs{
		width:100%;
		font-size:12px;
		text-align:center;
		justify-content:center;
	}
	.common_mypage_report__3b5HF .common_for_freeuser_info__FsOrs br{
		display:block;
	}
	.common_my_report_missonprogress_area__15MF1{
		height:auto;
		width:100%;
	}
	.common_my_report_missonprogress_area__15MF1 .common_head__3zcNA{
		height:auto;
		-webkit-box-align:center;
		-webkit-box-pack:start;
		flex-direction:column;
		align-items:flex-start;
		justify-content:center;
		padding:20px;
	}
	.common_my_report_missonprogress_area__15MF1 .common_head__3zcNA span{
		margin-right:0;
	}
	.common_my_report_missonprogress_area__15MF1 .common_head__3zcNA p{
		margin-top:5px;
	}
	.common_my_report_missonprogress_area__15MF1 .common_wrap__ornby{
		height:auto;
		flex-direction:column;
	}
	.common_my_report_missonprogress_area__15MF1 .common_misson_progress__317wj{
		width:100%;
		height:auto;
		border-right:none;
		border-bottom:1px solid #e6e6e6;
		padding:30px;
		-webkit-box-align:center;
		-webkit-box-pack:center;
		align-items:center;
		justify-content:center;
	}
	.common_my_report_missonprogress_area__15MF1 .common_misson_progress__317wj:last-child{
		border:none;
	}
	.common_misson_progress__317wj .common_misson_left__186Ms{
		width:100%;
		height:auto;
		-webkit-box-align:center;
		-webkit-box-pack:center;
		align-items:center;
		justify-content:center;
	}
	.common_misson_progress__317wj img{
		margin-bottom:30px;
		width:60px;
		height:60px;
	}
	.common_misson_progress__317wj .common_misson_txt__2Cdse{
		width:100%;
		height:auto;
		-webkit-box-align:center;
		-webkit-box-pack:center;
		align-items:center;
		justify-content:center;
	}
	.common_misson_progress__317wj p{
		margin-top:20px;
	}
	.common_my_report_study_time_area___GX6l{
		width:100%;
	}
	.common_my_report_cognitive_ability_area__3j2Ay{
		width:100%;
		margin-bottom:100px;
	}
	.common_my_report_cognitive_ability_area__3j2Ay .common_wrap__ornby{
		height:auto;
		-webkit-box-pack:center;
		-webkit-box-align:center;
		flex-direction:column;
		align-items:center;
		justify-content:center;
		padding:40px 20px;
		box-sizing:border-box;
	}
	.common_my_report_cognitive_ability_area__3j2Ay .common_ability_area__6H0Ov{
		width:100%;
		height:auto;
		padding:12px;
	}
	.common_my_report_cognitive_ability_area__3j2Ay .common_ability_area__6H0Ov:last-child{
		padding:12px;
		padding-top:32px;
	}
	.common_my_report_cognitive_ability_area__3j2Ay .common_cognitive_ability__ai_Du{
		width:100%;
		margin-bottom:50px;
	}
	
	/*mypage_stuedent_info*/
	.common_mypage_stuedent_info__2waua .common_my_tit__38b1N{
		margin-top:60px;
	}
	.common_parents_state_area__14Fnn>p{
		margin-left:0;
		width:100%;
		display:-webkit-box;
		-webkit-box-pack:center;
		display:flex;
		justify-content:center;
	}
	.common_parents_state_area__14Fnn{
		width:100%;
		height:auto;
		margin-top:50px;
	}
	/* 학부모 상태가 유료 일때 html에서 147,151번줄의 p태그에 class="pay" 추가 */
	.common_parents_state_box__2gFsM{
		width:100%;
		height:auto;
		padding:30px;
		flex-direction:column;
	}
	.common_parents_state_box__2gFsM .common_state_head__160ZN{
		height:auto;
		width:100%;
		padding:10px;
		border:none;
		border-bottom:1px solid #e6e6e6;
	}
	.common_state_head__160ZN .common_profile_wrap__2OQ0C{
		margin-right:30px;
	}
	.common_parents_state_box__2gFsM .common_right_area__3060r{
		width:100%;
		height:auto;
		flex-direction:column;
	}
	.common_parents_state_box__2gFsM .common_state_list__TV0DT{
		height:auto;
		width:100%;
		-webkit-box-align:center;
		-webkit-box-pack:start;
		flex-direction:row;
		align-items:center;
		justify-content:flex-start;
		padding:20px;
		position:relative;
		padding-right:0;
	}
	.common_parents_state_box__2gFsM .common_state_list__TV0DT:nth-child(1){
		padding-left:20px;
		width:100%;
	}
	.common_parents_state_box__2gFsM .common_state_list__TV0DT:nth-child(2){
		padding-left:20px;
	}
	.common_parents_state_box__2gFsM .common_state_list__TV0DT:nth-child(3){
		padding-left:20px;
		width:100%;
	}
	.common_parents_state_box__2gFsM .common_state_list__TV0DT:nth-child(4){
		padding-left:20px;
	}
	.common_state_list__TV0DT .common_info_popup__25W_w{
		width:100%;
		left:-15%;
	}
	.common_parents_state_box__2gFsM .common_state_list__TV0DT div p{
		width:80%;
	}
	.common_state_list__TV0DT .common_info_popup_btn__1REaP{
		margin-left:10px;
	}
	.common_state_list__TV0DT span{
		margin-bottom:0;
		width:30%;
	}
	.common_state_list__TV0DT p{
		width:70%;
	}
	.common_state_list__TV0DT p.common_ellip__2foBj{
		width:70%;
	}
	.common_mypage_stuedent_info__2waua .common_line__RCi7Y{
		width:80%;
	}
	.common_student_state_area__2A2tX{
		width:100%;
	}
	.common_student_state_box__Ff_ya{
		width:100%;
		height:auto;
		display:-webkit-box;
		-webkit-box-pack:center;
		-webkit-box-align:center;
		display:flex;
		align-items:center;
		justify-content:center;
		padding:30px;
		box-sizing:border-box;
		flex-direction:column;
		margin-top:60px;
	}
	/*학습자 정보/관리 페이지에 처음 들어왔을때 새로운 학습자가 생성되었을때 클래스 student_state_box에 클래스 new 추가 */
	.common_student_state_box__Ff_ya.common_new__NHR6G::after{
		top:422px;
		left:0;
		font-size:12px;
	}
	.common_student_state_box__Ff_ya .common_state_head__160ZN{
		height:auto;
		width:100%;
		border:none;
		padding-left:0;
		padding:10px;
		border-bottom:1px solid #e6e6e6;
	}
	.common_student_state_box__Ff_ya .common_state_head__160ZN .common_profile_wrap__2OQ0C{
		margin-right:20px; 
	}
	.common_student_state_box__Ff_ya .common_state_head__160ZN>span{
		text-align:left;
	}
	.common_student_state_box__Ff_ya .common_right_area__3060r{
		width:100%;
		height:auto;
		flex-direction:column;
	}
	.common_student_state_box__Ff_ya .common_state_list__TV0DT{
		height:auto;
		width:100%;
		-webkit-box-align:center;
		-webkit-box-pack:start;
		flex-direction:row;
		align-items:center;
		justify-content:flex-start;
		padding:20px;
		position:relative;
	}
	.common_student_state_box__Ff_ya .common_state_list__TV0DT:nth-child(1){
		width:100%;
	}
	.common_student_state_box__Ff_ya .common_state_list__TV0DT:nth-child(2){
		width:100%;
	}
	.common_student_state_box__Ff_ya .common_state_list__TV0DT span{
		margin:0;
	}
	.common_student_state_box__Ff_ya .common_state_list__TV0DT:last-child{
		width:100%;
		height:auto;
		padding-left:0;
		padding:20px;
		justify-content:center;
	}
	.common_student_state_box__Ff_ya .common_state_list__TV0DT:last-child button{
		margin-top:0;
		margin-left:10px;
	}
	.common_mypage_stuedent_info__2waua .common_go_price__3todw{
		width:260px;
		margin-bottom:100px;
	}
	
	/*mypage_parents_info*/
	.common_mypage_parents_info__6vf6J .common_my_tit__38b1N{
		margin-top:60px;
	}
	.common_mypage_parents_info__6vf6J .common_parents_info_area__3V1oO{
		width:100%;
		height:auto;
		padding:20px;
	}
	.common_parents_info_area__3V1oO ul{
		height:auto;
	}
	.common_parents_info_area__3V1oO li{
		height:60px;
	}
	.common_parents_info_area__3V1oO li .common_head__3zcNA{
		display:none;
	}
	.common_parents_info_area__3V1oO .common_right_area__3060r{
		width:100%;
	}
	.common_parents_info_area__3V1oO p{
		padding-left:20px;
	}
	.common_parents_info_area__3V1oO p.common_info__3UlbF{
		font-size:12px;
		line-height:1.5;
		margin-top:10px;
	}
	.common_parents_info_area__3V1oO input{
		width:70%;
		max-width:257px;
		height:40px;
		padding:10px 20px;
		margin-right:4px;
		font-size:14px;
	}
	.common_parents_info_area__3V1oO input::-webkit-input-placeholder{
		font-size:12px;
	}
	.common_parents_info_area__3V1oO input:-ms-input-placeholder{
		font-size:12px;
	}
	.common_parents_info_area__3V1oO input::placeholder{
		font-size:12px;
	}
	.common_parents_info_area__3V1oO li:nth-child(4) input{
		width:140px;
		padding:10px;
	}
	.common_parents_info_area__3V1oO button{
		width:100px;
		height:35px;
		font-size:12px;
	}
	.common_parents_info_area__3V1oO li.common_base__15Urx{
		height:80px;
	}
	.common_parents_info_area__3V1oO li.common_adress_area__2wW7n{
		height:214px;
	}
	.common_parents_info_area__3V1oO li.common_adress_area__2wW7n input{
		width:100%;
		max-width:100%;
	}
	.common_parents_info_area__3V1oO li.common_adress_area__2wW7n .common_top_area__1Ir0S input{
		width:140px;
	}
	.common_parents_info_area__3V1oO li.common_sms_add__3m9BZ{
		height:114px;
	}
	.common_parents_info_area__3V1oO li.common_sms_add__3m9BZ .common_head__3zcNA{
		width:30%;
		max-width:200px;
		font-size:14px;
		position:relative;
		display:-webkit-box;
		-webkit-box-align:center;
		-webkit-box-pack:start;
		display:flex;
		align-items:center;
		justify-content:flex-start;
	}
	.common_parents_info_area__3V1oO li.common_sms_add__3m9BZ br{
		display:block;
	}
	.common_parents_info_area__3V1oO li.common_sms_add__3m9BZ .common_right_area__3060r{
		width:70%;
		flex-direction:column;
		box-sizing:border-box;
		padding:20px 0;
	}
	.common_parents_info_area__3V1oO li.common_sms_add__3m9BZ .common_top_area__1Ir0S{
		display:-webkit-box;
		-webkit-box-align:center;
		-webkit-box-pack:start;
		display:flex;
		align-items:center;
		justify-content:flex-start;
		width:100%;
	}
	.common_parents_info_area__3V1oO .common_radio_btn_area__3nYLt input{
		width:20px;
		height:20px;
		margin-right:8px;
	}
	.common_parents_info_area__3V1oO .common_radio_btn_area__3nYLt input:checked::after{
		width:10px;
		height:10px;
	}
	.common_parents_info_area__3V1oO .common_radio_btn_area__3nYLt span{
		margin-right:10px;
	}
	.common_parents_info_area__3V1oO .common_btn_area__3WMBi{
		margin-top:50px;
	}
	

	/*mypage_pay_info*/
	.common_mypage_pay_info__Oxy1- .common_my_tit__38b1N{
		margin-top:60px;
	}
	.common_mypage_pay_info__Oxy1- .common_pay_info_area__2GMDi{
		width:100%;
		margin-bottom:100px;
	}
	.common_pay_info_list_wrap__35I6x li.common_list_head__3Dy0F div{
		width:100%;
	}
	.common_pay_info_list_wrap__35I6x li.common_list_head__3Dy0F div:nth-child(1){
		display:none;
	}
	.common_pay_info_list_wrap__35I6x li.common_list_head__3Dy0F div:nth-child(2){
		width:100%;
	}
	.common_pay_info_list_wrap__35I6x li.common_list_head__3Dy0F div:nth-child(3){
		display:none;
	}
	.common_pay_info_list_wrap__35I6x li.common_list_head__3Dy0F div:nth-child(4){
		display:none;
	}
	.common_pay_info_list_wrap__35I6x li.common_list_head__3Dy0F div:nth-child(5){
		display:none;
	}
	.common_pay_info_list_wrap__35I6x li.common_list_head__3Dy0F div:nth-child(6){
		display:none;
	}
	.common_pay_info_list_wrap__35I6x .common_list__1inqC{
		flex-direction:column;
		position:relative;
		height:150px;
	}
	.common_pay_info_list_wrap__35I6x li.common_list__1inqC li{
		width:auto;
		height:auto;
	}
	.common_pay_info_list_wrap__35I6x li.common_list__1inqC li br{
		display:none;
	}
	.common_pay_info_list_wrap__35I6x ul.common_top_area__1Ir0S{
		width:100%;
		height:50%;
		-webkit-box-align:start;
		-webkit-box-pack:center;
		align-items:flex-start;
		justify-content:center;
		flex-direction:column;
		box-sizing:border-box;
		padding:10px 10px 0 10px;
	}
	.common_pay_info_list_wrap__35I6x ul.common_bottom_area__16Bx2{
		width:100%;
		height:50%;
		padding:0 10px 10px 10px;
		align-items:flex-start;
	}
	.common_pay_info_list_wrap__35I6x li.common_list__1inqC .common_top_area__1Ir0S li:nth-child(2){
		width:auto;
	}
	.common_pay_info_list_wrap__35I6x li.common_list__1inqC .common_bottom_area__16Bx2 li:nth-child(1){
		width:auto;
	}
	.common_pay_info_list_wrap__35I6x li.common_list__1inqC .common_bottom_area__16Bx2 li:nth-child(2){
		width:auto;
	}
	.common_pay_info_list_wrap__35I6x li.common_list__1inqC .common_bottom_area__16Bx2 li:nth-child(3){
		width:auto; 
	}
	.common_pay_info_list_wrap__35I6x li.common_list__1inqC .common_bottom_area__16Bx2 li:nth-child(4){
		width:auto;
		flex-direction:row;
	}
	.common_pay_info_list_wrap__35I6x .common_list__1inqC .common_date__U96yZ{
		font-size:13px;
	}
	.common_pay_info_list_wrap__35I6x .common_list__1inqC .common_name__3XKX4{
		text-align:left;
		width:100%;
		max-width:270px;
		white-space:nowrap;
		-webkit-line-clamp:1;
		display:block;
	}
	.common_pay_info_list_wrap__35I6x .common_list__1inqC .common_student_name__2I8pt{
		font-size:13px;
		margin-right:20px;
		max-width:52px;
	}
	.common_pay_info_list_wrap__35I6x .common_list__1inqC .common_fee__NdZlb{
		font-size:13px;
		margin-right:20px;
	}
	.common_pay_info_list_wrap__35I6x .common_list__1inqC .common_pay_condition__2EJZF{
		font-size:13px;
	}
	.common_pay_info_list_wrap__35I6x .common_list__1inqC a{
		font-size:12px;
		width:45%;
		height:32px;
		position:absolute;
		bottom:14px;
		margin:0;
		left:3%;
	}
	.common_pay_info_list_wrap__35I6x .common_list__1inqC a:last-child{
		left:52%;
	}
	.common_pay_info_list_pagebtn_area__bifGV li{
		margin:0 5px;
	}
	
	/*mypage_pay_info_more*/
	.common_mypage_pay_info_more__JCcY_ .common_my_tit__38b1N{
		margin-top:60px;
	}
	.common_mypage_pay_info_more__JCcY_ .common_pay_info_more_area__2JMyP{
		width:100%;
		margin-bottom:100px;
	}
	.common_pay_info_more_area__2JMyP ul{
		width:100%;
		box-sizing:border-box;
		padding:0 15px;
	}
	.common_pay_info_more_area__2JMyP li{
		height:100px;
		position:relative;
		justify-content:center;
	}
	.common_pay_info_more_area__2JMyP li:nth-child(4){
		height:auto;
		padding:10px 0;
	}
	.common_pay_info_more_area__2JMyP li:nth-child(4) .common_head__3zcNA{
		height:inherit;
	}
	.common_pay_info_more_area__2JMyP li:nth-child(5){
		height:auto;
		padding:10px 0;
	}
	.common_pay_info_more_area__2JMyP li:nth-child(5) .common_head__3zcNA{
		height:inherit;
	}
	.common_pay_info_more_area__2JMyP li:nth-child(6){
		height:auto;
		padding:10px 0;
	}
	.common_pay_info_more_area__2JMyP li:nth-child(6) .common_head__3zcNA{
		height:inherit;
	}
	.common_pay_info_more_area__2JMyP .common_head__3zcNA{
		width:20%;
		min-width:95px;
		max-width:326px;
		padding-left:0px;
	}
	.common_pay_info_more_area__2JMyP p{
		width:40%;
		min-width:160px;
		max-width:484px;
	}
	.common_pay_info_more_area__2JMyP li:nth-child(4) p{
		display:block;
		width:40%;
		overflow:hidden;
		text-overflow:ellipsis;
		white-space:nowrap;
		word-break:normal;
	}
	.common_pay_info_more_area__2JMyP a{
		margin-top:50px;
	}

	/*mypage_student_register*/
	.common_mypage_student_register__28b__ .common_my_tit__38b1N{
		margin-top:60px;
	}
	.common_mypage_student_register__28b__ .common_student_register_area__2_Hpz{
		width:100%;
		height:auto;
		padding:15px;
	}
	.common_student_register_area__2_Hpz li:first-child{
		height:146px;
	}
	.common_student_register_area__2_Hpz li.common_select_list__1_Qkr:first-child{
		height:40px;
	}
	.common_student_register_area__2_Hpz li .common_head__3zcNA{
		height:100%;
		width:32%;
		min-width:92px;
		font-size:13px;
		padding-left:0;
	}
	.common_student_register_area__2_Hpz .common_right_area__3060r{
		width:65%;
		min-width:166px;
	}
	.common_student_register_area__2_Hpz p.common_info__3UlbF{
		margin-top:10px;
	}
	/*셀렉트박스(제작)*/
	.common_student_register_area__2_Hpz .common_product_list_select_area__fxs_A{
		width:110%;
	}
	.common_product_list_select_area__fxs_A .common_select_head__39mjD{
		padding:15px;
	}
	.common_product_list_select_area__fxs_A .common_selected_option__3uzwW .common_name__3XKX4{
		font-size:13px;
	}
	.common_product_list_select_area__fxs_A .common_selected_option__3uzwW .common_p_name__sX0-u{
		font-size:13px;
	}
	.common_product_list_select_area__fxs_A ul{
		min-width:100%;
	}
	.common_product_list_select_area__fxs_A li.common_select_list__1_Qkr{
		font-size:14px;
		margin-right:0;
	}
	.common_product_list_select_area__fxs_A li.common_select_list__1_Qkr .common_name__3XKX4{
		font-size:14px;
	}

	.common_student_register_area__2_Hpz input{
		width:80%;
		max-width:190px;
		height:50px;
		padding:10px 20px;
		font-size:15px;
	}
	.common_student_register_area__2_Hpz .common_radio_btn_area__3nYLt span{
		margin-right:30px;
	}
	.common_student_register_area__2_Hpz select{
		width:90px;
		height:40px;
		padding:5px 10px;
		margin-right:20px;
		font-size:14px;
	}
	.common_student_register_area__2_Hpz .common_month_list_select_area__2y-Kx{
		width:70px;
		height:40px;
	}
	.common_month_list_select_area__2y-Kx .common_select_head__39mjD{
		padding:5px 10px;
	}
	.common_month_list_select_area__2y-Kx li.common_select_list__1_Qkr{
		height:40px;
	}
	
	/*mypage_student_infofix*/
	.common_mypage_student_infofix__1H8xR .common_student_register_area__2_Hpz li.common_infofix_vertical__3wIUi{
		margin-top:0px;
		height:128px;
	}
	.common_month_list_select_area__2y-Kx li.common_select_list__1_Qkr:first-child{
		height:40px;
	}
	.common_mypage_student_infofix__1H8xR .common_student_register_area__2_Hpz li:last-child{
		margin-bottom:0px;
	}
	.common_mypage_student_infofix__1H8xR .common_student_register_area__2_Hpz li.common_infofix_vertical__3wIUi .common_right_area__3060r span{
		font-size:14px;
	}
	.common_mypage_student_infofix__1H8xR .common_student_register_area__2_Hpz li.common_infofix_vertical__3wIUi .common_right_area__3060r span:last-child{
		font-size:12px;
	}

	/* 자주묻는질문 */
	.common_faq_tab__2Ju4A .common_faq_area__3FH6w{
		width:100%;
	}
	.common_faq_tab__2Ju4A .common_faq_txt__1j9bD{
		text-align:center;
		font-size:13px;
	}
	#common_faq_srch_select__WSoQ9{
		width:80px;
		padding:0 4px;
		font-size:13px;
	}
	.common_faq_srch_area__ULz-k #common_faq_input_id__2Yevp{
		width:240px;
		font-size:15px;
	}
	.common_faq_categori_area__153vP #common_faq_categori_select__o9_Kc{
		font-size:15px;
	}
	.common_faq_list_wrap__1wdVE .common_question_area__XhvBu{
		padding:20px;
	}
	.common_faq_list_wrap__1wdVE .common_question_area__XhvBu span{
		margin-right:20px;
	}
	.common_faq_list_wrap__1wdVE .common_question_area__XhvBu p{
		width:82%;
		margin-right:20px;
	}
	.common_faq_list_wrap__1wdVE .common_answer_area__oGPX5{
		padding:30px;
	}

	/* 404 not found */
	.common_not_found_area__1RQ0b img{
		width:260px;
	}
	.common_not_found_area__1RQ0b p{
		font-size:24px;
	}
	.common_not_found_area__1RQ0b a{
		width:180px;
		margin-top:34px;
	}

}
/* 타블렛 가로 */
@media screen and (min-width:768px) and (max-width:1199px) and (orientation: landscape){
	
	section.common_footer_section__23XeD{
		padding-top:30px;
		padding-bottom:30px;
	}
	
	/*index.html*/
	/*main_banner_area */
	.common_bn_content_wrap__2v3GE{
		padding-bottom:3vh;
	}
	.common_bn_content_wrap__2v3GE .common_inner90__3qaGh{
		justify-content:center;
	}

	/*introduce_area*/
	.common_introduce_area__2LRd7 .common_top_area__1Ir0S{
		flex-direction:row;
	}
	.common_introduce_area__2LRd7 .common_top_area__1Ir0S img{
		height:40vh;
		margin-right:4vw;
	}
	.common_introduce_area__2LRd7 .common_top_area__1Ir0S .common_right_area__3060r{
		align-items:flex-start;
		margin-bottom:0;
	}
	.common_introduce_area__2LRd7 .common_right_area__3060r p{
		text-align:left;
	}
	.common_introduce_area__2LRd7 .common_right_area__3060r p br{
		display:block;
	}
	.common_introduce_area__2LRd7 .common_ym_who_popup__3du5G.common_on__2QUU7{
		flex-direction:row;
	}

	/*ym_world_area*/
	.common_ym_world_area__3ao46 .common_index_tit__3e1q3{
		flex-direction:row;
	}
	.common_ym_world_area__3ao46 p.common_txt__3s3ZA br{
		display:block;
	}
	.common_ym_world_area__3ao46 .common_bottom_area__16Bx2{
		-webkit-box-align:center;
		-webkit-box-pack:center;
		flex-direction:row;
		justify-content:center;
		align-items:center;
	}
	.common_ym_world_area__3ao46 .common_right_area__3060r{
		height:20vh;
		width:30vw;
		margin-left:3vw;
		top:0;
	}
	.common_ym_world_area__3ao46 .common_building_wrap__3bCH9{
		width:100%;
		height:100%;
	}

	/*ym_difference_area*/
	.common_ym_difference_area__GVl5X .common_middle_area__2Omh4{
		margin-top:4vh;
	}

	/*ym_myroom_area*/
	.common_ym_myroom_area__1iNyx .common_index_tit__3e1q3{
		flex-direction:row;
	}
	.common_ym_myroom_area__1iNyx .common_bottom_area__16Bx2{
		flex-direction:row;
		margin-top:4vh;
	}
	.common_ym_myroom_area__1iNyx .common_box__2MqeC{
		width:25vw;
		height:auto;
		margin:0 1vw;
	}
	.common_ym_myroom_area__1iNyx .common_box__2MqeC.common_on__2QUU7{
		margin:0 2vw;
	}
	.common_ym_myroom_area__1iNyx .common_content__2kYUC .common_graph_area3__22g6W .common_counting__1dwuc{
		font-size:1em;
	}
	.common_ym_myroom_area__1iNyx .common_content__2kYUC .common_graph_area3__22g6W .common_count1__UguBq{
		top:55%;
	}
	.common_ym_myroom_area__1iNyx .common_content__2kYUC .common_graph_area3__22g6W .common_count2__3ZZTt{
		top:55%;
	}
	.common_ym_myroom_area__1iNyx .common_content__2kYUC .common_graph_area3__22g6W .common_count3__3sNcj{
		top:55%;
	}
	.common_ym_myroom_area__1iNyx .common_content__2kYUC .common_graph_area1_graph__gMu1n{
		width:75%;
		position:relative;
		top:-8%;
	}

	/*brain_music_area*/
	.common_brain_music_area__NtTkf{
		background:url(/static/media/뮤직룸.8bcd004e.png) no-repeat center;
		background-size:cover;
	}
	.common_brain_music_area__NtTkf .common_inner90__3qaGh{
		justify-content:flex-start;
	}
	.common_brain_music_area__NtTkf .common_wrap__ornby{
		align-items:flex-start;
	}
	.common_brain_music_area__NtTkf .common_wrap__ornby ul{
		width:100%;
	}
	.common_brain_music_area__NtTkf .common_wrap__ornby li{
		padding:1vh 2vw;
	}
	.common_brain_music_area__NtTkf .common_wrap__ornby li .common_play_wrap__3KOSn{
		margin-right:2vw;
	}

	/*coding_block_area*/
	.common_coding_block_area__2gmOW .common_index_tit__3e1q3{
		flex-direction:row;
	}
	.common_coding_block_area__2gmOW .common_bottom_area__16Bx2{
		flex-direction:row;
		height:auto;
	}
	.common_coding_block_area__2gmOW .common_cont__1FF3G .common_num__1L4QU{
		height:4.5vh;
	}
	.common_coding_block_area__2gmOW .common_cont__1FF3G img:last-child{
		display:none;
	}

	/*yf_area*/
	.common_yf_area__4xLfs .common_content_area__2tBPk{
		flex-direction:row;
	}
	.common_yf_area__4xLfs .common_content_area__2tBPk .common_btn_area__3WMBi{ 
		width:20%;
		height:22vh;
		flex-direction:column;
	}
	.common_yf_area__4xLfs .common_content_area__2tBPk .common_btn_area__3WMBi li{
		margin:1vh 0;
		height:20%;
		position:relative;
	}
	.common_yf_area__4xLfs .common_content_area__2tBPk .common_btn_area__3WMBi li.common_on__2QUU7{
		height:20%;
	}
	.common_yf_area__4xLfs .common_content_area__2tBPk .common_btn_area__3WMBi li.common_on__2QUU7:hover{
		height:20%;
	}
	.common_yf_area__4xLfs .common_content_area__2tBPk .common_btn_area__3WMBi li img{
		height:70%;
	}
	.common_yf_area__4xLfs .common_content_area__2tBPk .common_btn_area__3WMBi li.common_on__2QUU7 img{
		height:100%;
	}
	.common_yf_area__4xLfs .common_yf_info_area__3AaKI{
		width:50%;
	} 

}
/* 모바일 가로 */
@media screen and (min-width:320px) and (max-width:1024px) and (orientation: landscape){
	
	
	/* header */
	.common_mobile_menu_area__2aife{
		width:50%;
	}
	.common_mobile_menu_wrap__2klDq .common_mobile_menu_top_area__3ziQd{
		height:50px;
	}
	.common_mobile_menu_wrap__2klDq .common_mobile_menu_top_area__3ziQd img{
		height:32px;
	}
	.common_mobile_my_area__x2h0y .common_my_wrap__1oYVs .common_top_area__1Ir0S{
		height:50px;
	}
	.common_mobile_my_area__x2h0y .common_my_wrap__1oYVs .common_left_area__2Nwxl{
		height:32px;
	}
	.common_mobile_my_area__x2h0y .common_my_wrap__1oYVs .common_bottom_area__16Bx2{
		height:32px;
	}
	.common_mobile_menu_wrap__2klDq .common_bottom_menu_area__3f9w5 a{
		height:32px;
		font-size:13px;
	}
	
	/* footer */
	section.common_footer_section__23XeD{
		padding-top:30px;
		padding-bottom:30px;
	}
	.common_footer__18aab .common_inner90__3qaGh{
		-webkit-box-align:start;
		flex-direction:row;
		align-items:flex-start;
	}
	.common_f_left__1epgt{
		width:75%;
	}
	.common_f_right__-R8OE{
		width:25%;
	}
	.common_f_right__-R8OE ul{
		margin-top:0;
	}
	.common_f_right__-R8OE li:nth-child(3){
		text-align:center;
	}
	.common_f_left__1epgt ul{
		align-items:flex-start;
	}
	.common_f_left__1epgt li{
		text-align:left;
	}
	.common_f_left__1epgt li:nth-child(1){
		margin-bottom:15px;
		margin-left:64px;
		display:none;
	}
	.common_f_left__1epgt li:nth-child(3){
		margin-bottom:16px;
	}
	.common_f_left__1epgt li br{
		display:none
	}
	
	/*index.html*/
	.common_index_tit__3e1q3{
		font-size:1.25em;
	}
	/*main_banner_area */
	.common_bn_content_wrap__2v3GE{
		padding-top:0;
		padding-bottom:5vh;
	}
	.common_bn_content_wrap__2v3GE .common_inner90__3qaGh{
		-webkit-box-pack:center;
		justify-content:center;
		flex-direction:row;
	}
	.common_bn_content_wrap__2v3GE img{
		margin-bottom:0;
		margin-left:3vw;
		height:auto;
		width:40vw;
	}

	/*introduce_area*/
	.common_introduce_area__2LRd7{
		padding-top:0;
		padding-bottom:5vh;
	}
	.common_introduce_area__2LRd7 .common_top_area__1Ir0S{
		flex-direction:row;
	}
	.common_introduce_area__2LRd7 .common_top_area__1Ir0S img{
		height:40vh;
		margin-right:4vw;
	}
	.common_introduce_area__2LRd7 .common_top_area__1Ir0S .common_right_area__3060r{
		align-items:flex-start;
		margin-bottom:0;
	}
	.common_introduce_area__2LRd7 .common_right_area__3060r p{
		text-align:left;
	}
	.common_introduce_area__2LRd7 .common_right_area__3060r p br{
		display:block;
	}
	.common_introduce_area__2LRd7 .common_ym_who_popup__3du5G.common_on__2QUU7{
		flex-direction:row;
	}
	.common_introduce_area__2LRd7 .common_ym_who_btn__1oA6w{
		margin-top:3vh;
	}
	.common_introduce_area__2LRd7 .common_ym_who_btn__1oA6w span{
		font-size:13px;
	}

	/*ym_world_area*/
	.common_ym_world_area__3ao46{
		padding-top:0;
		padding-bottom:5vh;
	}
	.common_ym_world_area__3ao46 .common_index_tit__3e1q3{
		flex-direction:row;
	}
	.common_ym_world_area__3ao46 p.common_txt__3s3ZA br{
		display:block;
	}
	.common_ym_world_area__3ao46 .common_bottom_area__16Bx2{
		-webkit-box-align:center;
		-webkit-box-pack:center;
		flex-direction:row;
		justify-content:center;
		align-items:center;
	}
	.common_ym_world_area__3ao46 .common_right_area__3060r{
		height:30vh;
		width:50%;
		margin-left:3vw;
		top:0;
	}
	.common_ym_world_area__3ao46 .common_building_wrap__3bCH9{
		width:100%;
		height:100%;
	}

	/*ym_difference_area*/
	.common_ym_difference_area__GVl5X{
		padding-top:0;
		padding-bottom:5vh;
	}
	.common_ym_difference_area__GVl5X .common_middle_area__2Omh4{
		margin-top:4vh;
		height:25vh;
	}
	.common_ym_difference_area__GVl5X .common_img_area__3KniS{
		display:none;
	}

	/*ym_myroom_area*/
	.common_ym_myroom_area__1iNyx{
		padding-top:0;
		padding-bottom:5vh;
	}
	.common_ym_myroom_area__1iNyx .common_index_tit__3e1q3{
		flex-direction:row;
	}
	.common_ym_myroom_area__1iNyx .common_bottom_area__16Bx2{
		flex-direction:row;
	}
	.common_ym_myroom_area__1iNyx .common_box__2MqeC{
		width:22vw;
		height:auto;
		margin:0 1vw;
	}
	.common_ym_myroom_area__1iNyx .common_box__2MqeC.common_on__2QUU7{
		margin:0 2vw;
	}
	.common_ym_myroom_area__1iNyx .common_content__2kYUC .common_graph_area3__22g6W img{
		width:80%;
	}
	.common_ym_myroom_area__1iNyx .common_content__2kYUC .common_graph_area3__22g6W .common_counting__1dwuc{
		font-size:.8em;
	}
	.common_ym_myroom_area__1iNyx .common_content__2kYUC .common_graph_area3__22g6W .common_count1__UguBq{
		top:62%;
		left:8%;
	}
	.common_ym_myroom_area__1iNyx .common_content__2kYUC .common_graph_area3__22g6W .common_count2__3ZZTt{
		top:62%;
	}
	.common_ym_myroom_area__1iNyx .common_content__2kYUC .common_graph_area3__22g6W .common_count3__3sNcj{
		top:62%;
		left:67%;
	}
	.common_ym_myroom_area__1iNyx .common_graph_area1__2BgSb .common_wrap__ornby{
		width:53%;
	}
	.common_ym_myroom_area__1iNyx .common_content__2kYUC .common_graph_area1_graph__gMu1n{
		width:75%;
		position:relative;
		top:-8%;
	}
	.common_ym_myroom_area__1iNyx .common_content__2kYUC .common_bottom_area__16Bx2{
		height:48%;
	}

	/*brain_music_area*/
	.common_brain_music_area__NtTkf{
		padding-top:0;
		padding-bottom:15vh;
	}
	.common_brain_music_area__NtTkf .common_index_tit__3e1q3{
		justify-content:center;
	}
	.common_brain_music_area__NtTkf .common_wrap__ornby{
		width:64%;
	}
	.common_brain_music_area__NtTkf .common_wrap__ornby ul{
		width:100%;
	}
	.common_brain_music_area__NtTkf .common_wrap__ornby li{
		padding:1vh 2vw;
	}
	.common_brain_music_area__NtTkf .common_wrap__ornby li .common_play_wrap__3KOSn{
		margin-right:2vw;
	}

	/*coding_block_area*/
	.common_coding_block_area__2gmOW{
		padding-top:0;
		padding-bottom:10vh;
	}
	.common_coding_block_area__2gmOW .common_index_tit__3e1q3{
		flex-direction:row;
	}
	.common_coding_block_area__2gmOW .common_bottom_area__16Bx2{
		flex-direction:row;
		height:auto;
	}
	.common_coding_block_area__2gmOW .common_cont__1FF3G .common_num__1L4QU{
		height:4.5vh;
	}
	.common_coding_block_area__2gmOW .common_cont__1FF3G img:last-child{
		display:none;
	}

	/*yf_area*/
	.common_yf_area__4xLfs{
		padding-top:0;
		padding-bottom:10vh;
	}
	.common_yf_area__4xLfs .common_content_area__2tBPk{
		flex-direction:row;
	}
	.common_yf_area__4xLfs .common_content_area__2tBPk .common_btn_area__3WMBi{ 
		width:20%;
		height:36vh;
		flex-direction:column;
	}
	.common_yf_area__4xLfs .common_content_area__2tBPk .common_btn_area__3WMBi li{
		margin:1vh 0;
		height:20%;
		position:relative;
	}
	.common_yf_area__4xLfs .common_content_area__2tBPk .common_btn_area__3WMBi li.common_on__2QUU7{
		height:20%;
	}
	.common_yf_area__4xLfs .common_content_area__2tBPk .common_btn_area__3WMBi li.common_on__2QUU7:hover{
		height:20%;
	}
	.common_yf_area__4xLfs .common_content_area__2tBPk .common_btn_area__3WMBi li img{
		height:70%;
	}
	.common_yf_area__4xLfs .common_content_area__2tBPk .common_btn_area__3WMBi li.common_on__2QUU7 img{
		height:100%;
	}
	.common_yf_area__4xLfs .common_yf_info_area__3AaKI{
		width:50%;
	} 

}

/* 모바일 가로 - 기기의 세로가 태블릿의 범주일때 틀어짐 방지 (헤더/푸터/메인페이지) */
@media screen and (min-device-width:320px) and (max-device-width:1024px) and (orientation: landscape){
	
	/* 모바일 세로 미디어쿼리 + 모바일 가로 미디어 쿼리 */
	
	.common_inner__1cU6t{
		width:90%;
	}
	
	/*header*/
	.common_header__3rYGR .common_inner__1cU6t{
		width:90%;
	}
	.common_header__3rYGR .common_logo__32lKi{
		margin:0 auto;
		margin-top:12px;
		height:44px;
	}
	.common_menu_wrapper__3UAYG{
		display:none;
	}
	/*login&mypage*/
	.common_header__3rYGR .common_button__2VIg2{
		display:none; 
	}
	/*mobile_menu_btn_area*/
	.common_mobile_menu_btn_area__u63Re{
		display:block; 
	}
	.common_mobile_menu_area__2aife{
		width:70%;
	}
	
	
	/*footer*/
	section.common_footer_section__23XeD{
		height:auto;
		background:#1a1a1a;
		padding-top:0;
		padding-bottom:0;
		top:0;
		position:relative;
	}
	.common_footer__18aab{
		height:auto;
	}
	.common_footer__18aab .common_inner90__3qaGh{
		flex-direction:column-reverse;
	}
	.common_f_left__1epgt{
		width:100%;
		height:auto;
		margin:20px 0;
	}
	.common_f_left__1epgt ul{
		align-items:center;
	}
	.common_f_left__1epgt li{
		text-align:center;
	}
	.common_f_right__-R8OE{
		width:100%;
		height:auto;
	}
	.common_f_right__-R8OE ul{
		align-items:center;
		margin-top:40px;
	}
	
	/*index.html*/
	.common_stakato__e3n3a::before{
		width:.5vh;
		height:.5vh;
		top:-.7vh;
	}
	.common_index_tit__3e1q3 .common_stakato__e3n3a::before{
		width:.8vh;
		height:.8vh;
		top:-.3vh;
	}
	.common_sub_title__hQAyC{
		font-size:.8em;
	}
	p.common_txt__3s3ZA{
		font-size:12px;
	}
	section{
		height:100vh;
		position:fixed;
		top:100vh;
	}
	/*main_banner_area */
	.common_bn_content_wrap__2v3GE{
		padding-top:80px;
		padding-bottom:20vh;
	}
	.common_bn_content_wrap__2v3GE .common_bn_tit__3dw1P{
		font-size:1.9em;
	}
	.common_bn_content_wrap__2v3GE .common_bn_txt__3TdUu{
		font-size:.7em;
	}
	.common_bn_content_wrap__2v3GE .common_inner90__3qaGh{
		-webkit-box-pack:start;
		flex-direction:column-reverse;
		justify-content:flex-start;
	}
	.common_bn_content_wrap__2v3GE img{
		height:35vmin;
		margin-top:0;
		margin-bottom:12vh;
	}

	/*introduce_area*/
	.common_introduce_area__2LRd7{
		padding-top:80px;
		padding-bottom:10vh;
	}
	.common_introduce_area__2LRd7 .common_inner90__3qaGh{
		justify-content:center;
	}
	.common_introduce_area__2LRd7 .common_top_area__1Ir0S{
		height:auto;
		flex-direction:column-reverse;
	}
	.common_introduce_area__2LRd7 .common_top_area__1Ir0S img{
		height:30vh;
		margin-right:0;
	}
	.common_introduce_area__2LRd7 .common_top_area__1Ir0S .common_right_area__3060r{
		display:-webkit-box;
		-webkit-box-align:Center;
		display:flex;
		flex-direction:column;
		align-items:center;
		margin-bottom:2.5vh;
	}
	.common_introduce_area__2LRd7 .common_right_area__3060r p{
		font-size:12px;
		text-align:center;
		margin-top:1.5vh;
	}
	.common_introduce_area__2LRd7 .common_right_area__3060r p br{
		display:none;
	}
	.common_introduce_area__2LRd7 span.common_index_tit__3e1q3{
		text-align:center;
		margin-top:1vh;
	}
	.common_introduce_area__2LRd7 .common_ym_who_popup__3du5G.common_on__2QUU7{
		width:100%;
		height:104%;
		padding:4vmin;
		flex-direction:column;
		border-radius:30px;
	}
	.common_introduce_area__2LRd7 .common_ym_who_popup__3du5G.common_on__2QUU7 .common_ym_wyo_box__3T_QO{
		width:100%;
		margin-top:3vh;
	}
	.common_ym_who_popup__3du5G.common_on__2QUU7 .common_ym_wyo_box__3T_QO:first-child{
		width:100%;
		margin:0;
	}
	.common_ym_who_popup__3du5G.common_on__2QUU7 .common_ym_wyo_box__3T_QO:nth-child(2){
		width:100%;
		margin:0;
		margin-top:3vh;
	}
	.common_ym_who_popup__3du5G.common_on__2QUU7 .common_ym_wyo_box__3T_QO span{
		font-size:16px;
	}
	.common_ym_who_popup__3du5G.common_on__2QUU7 .common_ym_wyo_box__3T_QO p{
		margin-top:1vh;
		font-size:12px;
	}
	.common_introduce_area__2LRd7 .common_ym_who_btn__1oA6w{
		width:auto;
		height:auto;
		box-sizing:border-box;
		padding:1vh 15vmin;
		margin-top:6vh;
	}
	.common_introduce_area__2LRd7 .common_ym_who_btn__1oA6w span{
		font-size:14px;
	}

	/*ym_world_area*/
	.common_ym_world_area__3ao46{
		padding-top:80px;
		padding-bottom:10vh;
	}
	.common_ym_world_area__3ao46 .common_inner90__3qaGh{
		justify-content:center;
	}
	.common_ym_world_area__3ao46 .common_index_tit__3e1q3{
		-webkit-box-align:center;
		flex-direction:column;
		align-items:center;	
	}
	.common_ym_world_area__3ao46 .common_index_tit__3e1q3 .common_br__2s69l{
		margin-right:0;
	}
	.common_ym_world_area__3ao46 p.common_txt__3s3ZA{
		margin-top:1vh;
	}
	.common_ym_world_area__3ao46 p.common_txt__3s3ZA br{
		display:none;
	}
	.common_ym_world_area__3ao46 .common_bottom_area__16Bx2{
		height:auto;
		width:100%;
		margin-top:1.5vh;
		flex-direction:column-reverse;
	}
	.common_ym_world_area__3ao46 .common_left_area__2Nwxl{
		height:30vh;
		margin-left:0;
		margin-top:3.5vh;
	}
	.common_ym_world_area__3ao46 .common_left_area__2Nwxl .common_loby__2dEHE{
		height:135%;
		margin-top:0;
	}
	.common_ym_world_area__3ao46 .common_plus_wrap__3LtaP .common_plus__2zQZU{
		width:3vh;
		height:3vh;
	}
	.common_ym_world_area__3ao46 .common_plus__2zQZU:nth-child(1){
		top:16%;
		left:34%;
	}
	.common_ym_world_area__3ao46 .common_plus__2zQZU:nth-child(2){
		top:-10%;
		left:37%;
	}
	.common_ym_world_area__3ao46 .common_plus__2zQZU:nth-child(3){
		top:-16%;
		left:58%;
	}
	.common_ym_world_area__3ao46 .common_plus__2zQZU:nth-child(4){
		top:-15%;
		left:69%;
	}
	.common_ym_world_area__3ao46 .common_plus__2zQZU:nth-child(5){
		top:11%;
		left:46%;
	}
	.common_ym_world_area__3ao46 .common_plus__2zQZU:nth-child(6){
		top:-11%;
		left:35%;
	}
	.common_ym_world_area__3ao46 .common_right_area__3060r{
		height:auto;
		margin-left:0;
		top:1.5vh;
	}
	.common_ym_world_area__3ao46 .common_building_wrap__3bCH9{
		width:80%;
		height:14vmax;
		padding:0;
		border:2px dashed #ffffff;
		border-radius:30px;
	}
	.common_ym_world_area__3ao46 .common_building__1tZuc{
		padding:1.5vh;
		justify-content:center;
	}
	.common_ym_world_area__3ao46 .common_building__1tZuc img{
		display:none;
	}
	.common_ym_world_area__3ao46 .common_building__1tZuc span{
		font-size:13px;
		margin-bottom:1vh;
		padding:0 2vw;
	}
	.common_ym_world_area__3ao46 .common_building__1tZuc p{
		font-size:12px;
	}
	.common_ym_world_area__3ao46 .common_building__1tZuc p br{
		display:none;
	}

	/*ym_difference_area*/
	.common_ym_difference_area__GVl5X{
		padding-top:80px;
		padding-bottom:10vh;
	}
	.common_ym_difference_area__GVl5X .common_inner90__3qaGh{
		justify-content:center;
	}
	.common_ym_difference_area__GVl5X .common_tabbtn_area__Ixj-V .common_btn__3q49s:first-child{
		margin-right:4.5vw;
	}
	.common_ym_difference_area__GVl5X .common_tabbtn_area__Ixj-V .common_btn__3q49s{
		font-size:14px;
	}
	.common_ym_difference_area__GVl5X .common_tabbtn_area__Ixj-V .common_btn__3q49s::before{
		margin-right:1vw;
	}
	.common_ym_difference_area__GVl5X .common_middle_area__2Omh4 p{
		font-size:12px;
	}
	.common_ym_difference_area__GVl5X .common_img_area__3KniS{
		height:27vmin;
		margin-top:6vh;
	}

	/*ym_myroom_area*/
	.common_ym_myroom_area__1iNyx{
		padding-top:80px;
		padding-bottom:10vh;
	}
	.common_ym_myroom_area__1iNyx .common_inner90__3qaGh{
		justify-content:center;
	}
	.common_ym_myroom_area__1iNyx .common_index_tit__3e1q3{
		flex-direction:column;
	}
	.common_ym_myroom_area__1iNyx .common_index_tit__3e1q3 .common_br__2s69l{
		margin-right:0;
	}
	.common_ym_myroom_area__1iNyx p.common_txt__3s3ZA br{
		display:none;
	}
	.common_ym_myroom_area__1iNyx .common_bottom_area__16Bx2{
		margin-top:2vh;
		flex-direction:column;
		width:100%;
		height:auto;
	}
	.common_ym_myroom_area__1iNyx .common_box__2MqeC{
		transform:scale(1);
		top:0;
		width:65%;
		height:5vh;
		box-shadow:0 0 10px 0 rgb(0,0,0,.35);
		transition:all .3s;
		border-radius:30px;
		margin:3px 0;
	}
	.common_ym_myroom_area__1iNyx .common_box__2MqeC.common_on__2QUU7{
		transform:scale(1);
		top:0;
		height:auto;
		box-shadow:0 0 10px 0 rgb(0,0,0,.35);
		margin:5px 0;
	}
	.common_ym_myroom_area__1iNyx .common_box__2MqeC:hover{
		transform:scale(1);
	}
	.common_ym_myroom_area__1iNyx .common_box__2MqeC.common_on__2QUU7:hover{
		transform:scale(1);
	}
	.common_ym_myroom_area__1iNyx .common_box__2MqeC .common_cover__1DbYf{
		font-size:10px;
	}
	.common_ym_myroom_area__1iNyx .common_box__2MqeC .common_content__2kYUC{
		padding:1.5vh;
	}
	.common_ym_myroom_area__1iNyx .common_content__2kYUC span{
		font-size:14px;
	}
	.common_ym_myroom_area__1iNyx .common_content__2kYUC p{
		font-size:12px;
	}
	.common_ym_myroom_area__1iNyx .common_box__2MqeC .common_content__2kYUC .common_bottom_area__16Bx2{
		margin-top:0;
	}
	.common_ym_myroom_area__1iNyx .common_content__2kYUC .common_graph_area3__22g6W .common_count_wrap__1OTx0{
		height:29%;
	}
	.common_ym_myroom_area__1iNyx .common_content__2kYUC .common_graph_area3__22g6W .common_count1__UguBq{
		top:58%;
	}
	.common_ym_myroom_area__1iNyx .common_content__2kYUC .common_graph_area3__22g6W .common_count2__3ZZTt{
		top:58%;
	}
	.common_ym_myroom_area__1iNyx .common_content__2kYUC .common_graph_area3__22g6W .common_count3__3sNcj{
		top:58%;
	}
	.common_ym_myroom_area__1iNyx .common_content__2kYUC .common_graph_area3__22g6W .common_counting__1dwuc{
		font-size:1em;
	}
	.common_ym_myroom_area__1iNyx .common_content__2kYUC .common_graph_area1_graph__gMu1n{
		width:80%;
	}

	/*brain_music_area*/
	.common_brain_music_area__NtTkf{
		padding-top:80px;
		padding-bottom:10vh;
		background:url(/static/media/musicroom_mobile.5dd819f9.png) no-repeat center;
		background-size:cover;
	}
	.common_brain_music_area__NtTkf .common_inner90__3qaGh{
		justify-content:center;
	}
	.common_brain_music_area__NtTkf .common_index_tit__3e1q3{
		width:100%;
		text-align:center;
		padding-left:0;
	}
	.common_brain_music_area__NtTkf .common_sub_title__hQAyC{
		text-align:center;
		padding-left:0;
	}
	.common_brain_music_area__NtTkf .common_wrap__ornby{
		margin-left:0;
		top:0;
		align-items:center;
	}
	.common_brain_music_area__NtTkf .common_wrap__ornby ul{
		display:-webkit-box;
		-webkit-box-align:center;
		display:flex;
		flex-direction:column;
		align-items:center;
		margin-top:4vh;
	}
	.common_brain_music_area__NtTkf .common_wrap__ornby li{
		padding:1vh 4vw;
		width:100%;
		height:auto;
		min-width:auto;
	}
	.common_brain_music_area__NtTkf .common_wrap__ornby li:nth-child(2){
		margin:2vh 0;
	}
	.common_brain_music_area__NtTkf .common_wrap__ornby li .common_play_wrap__3KOSn{
		width:8vh;
		height:8vh;
		margin-right:4vw;
	}
	.common_brain_music_area__NtTkf .common_wrap__ornby li .common_right_area__3060r{
		width:70%;
	}
	.common_brain_music_area__NtTkf .common_wrap__ornby li .common_right_area__3060r span{
		font-size:14px;
	}
	.common_brain_music_area__NtTkf .common_wrap__ornby li .common_right_area__3060r p{
		font-size:12px;
	}

	/*coding_block_area*/
	.common_coding_block_area__2gmOW{
		padding-top:80px;
		padding-bottom:10vh;
	}
	.common_coding_block_area__2gmOW .common_inner90__3qaGh{
		justify-content:center;
	}
	.common_coding_block_area__2gmOW .common_index_tit__3e1q3{
		flex-direction:column;
	}
	.common_coding_block_area__2gmOW .common_bottom_area__16Bx2{
		margin-top:2vh;
		flex-direction:column;
		height:auto;
	}
	.common_coding_block_area__2gmOW .common_cont__1FF3G{
		width:90%;
		height:6vh;
		overflow:hidden;
		padding:2vmin;
	}
	.common_coding_block_area__2gmOW .common_cont__1FF3G:last-child{
		width:100%;
	}
	.common_coding_block_area__2gmOW .common_cont__1FF3G:last-child br{
		display:none;
	}
	.common_coding_block_area__2gmOW .common_cont__1FF3G .common_num__1L4QU{
		height:4.5vh;
	}
	.common_coding_block_area__2gmOW .common_cont__1FF3G .common_title__1txLf{
		font-size:14px;
		visibility:hidden;
	}
	.common_coding_block_area__2gmOW .common_cont__1FF3G .common_sub_tit__3_GE4{
		font-size:11px;
		visibility:hidden;
	}
	.common_coding_block_area__2gmOW .common_cont__1FF3G p{
		font-size:12px;
		visibility:hidden;
	}
	.common_coding_block_area__2gmOW .common_cont__1FF3G img:last-child{
		width:20vw;
		visibility:hidden;
	}
	.common_coding_block_area__2gmOW .common_cont__1FF3G.common_on__2QUU7 .common_title__1txLf{
		visibility:visible;
	}
	.common_coding_block_area__2gmOW .common_cont__1FF3G.common_on__2QUU7 .common_sub_tit__3_GE4{
		visibility:visible;
	}
	.common_coding_block_area__2gmOW .common_cont__1FF3G.common_on__2QUU7 p{
		visibility:visible;
	}
	.common_coding_block_area__2gmOW .common_cont__1FF3G.common_on__2QUU7 img:last-child{
		visibility:visible;
	}
	.common_coding_block_area__2gmOW .common_cont__1FF3G.common_on__2QUU7{
		transform:scale(1);
		margin:2vh 0;
		overflow:visible;
		height:auto;
	}
	.common_coding_block_area__2gmOW .common_cont__1FF3G.common_on__2QUU7:hover{
		transform:scale(1);
	}
	.common_coding_block_area__2gmOW .common_cont__1FF3G:hover{
		transform:scale(1);
	}
	.common_coding_block_area__2gmOW .common_cont__1FF3G:last-child.common_on__2QUU7{
		margin-left:0;
	}
	.common_coding_block_area__2gmOW .common_arrow_wrap__2RrYr{
		display:none;
	}

	/*yf_area*/
	.common_yf_area__4xLfs{
		padding-top:80px;
		padding-bottom:10vh;
	}
	.common_yf_area__4xLfs .common_content_area__2tBPk{
		height:auto;
		width:100%;
		-webkit-box-align:Center;
		flex-direction:column;
		align-items:center;
		margin-top:2vh;
	}
	.common_yf_area__4xLfs .common_content_area__2tBPk .common_btn_area__3WMBi{ 
		width:100%;
		height:36px;
		padding-bottom:0;
		margin-right:0;
		margin-left:0;
		margin-bottom:1vh;
		-webkit-box-pack:center;
		flex-direction:row;
		justify-content:center;
	}
	.common_yf_area__4xLfs .common_content_area__2tBPk .common_btn_area__3WMBi li{
		width:auto;
		height:60%;
		margin:0 3vw;
	}
	.common_yf_area__4xLfs .common_content_area__2tBPk .common_btn_area__3WMBi li.common_on__2QUU7{
		height:100%;
	}
	.common_yf_area__4xLfs .common_content_area__2tBPk .common_btn_area__3WMBi li.common_on__2QUU7:hover{
		height:100%;
	}
	.common_yf_area__4xLfs .common_content_area__2tBPk .common_btn_area__3WMBi li:hover{
		height:80%;
	}
	.common_yf_area__4xLfs .common_yf_img_area__3eH7p{
		height:160px;
		margin-right:0;
	}
	.common_yf_area__4xLfs .common_yf_img_area__3eH7p img{
		height:100%;
	}
	.common_yf_area__4xLfs .common_yf_info_area__3AaKI{
		width:100%;
		height:90px;
		margin-top:15px;
		justify-content:flex-start;
	} 
	.common_yf_area__4xLfs .common_yf_info_area__3AaKI .common_album_area__2b8yB{
		display:none;
	}	
	.common_yf_area__4xLfs .common_yf_info_area__3AaKI .common_txt_area__25xQw{
		width:100%;
		height:auto;
	}	
	.common_yf_area__4xLfs .common_yf_info_area__3AaKI .common_txt_box__3wTLx{
		width:100%;
		height:auto;
		padding:3vmin;
		-webkit-box-align:center;
		-webkit-box-pack:center;
		align-items:center;
		justify-content:center;
	}	
	.common_yf_area__4xLfs .common_yf_info_area__3AaKI .common_txt_box__3wTLx span{
		font-size:14px;
	}
	.common_yf_area__4xLfs .common_yf_info_area__3AaKI .common_txt_box__3wTLx p{
		font-size:12px;
		text-align:center;
	}

	/* floating_paging_btn_wrap */
	.common_floating_paging_btn_wrap__10eqW{
		visibility:hidden;
	}
	
	/* header */
	.common_mobile_menu_area__2aife{
		width:50%;
	}
	.common_mobile_menu_wrap__2klDq .common_mobile_menu_top_area__3ziQd{
		height:50px;
	}
	.common_mobile_menu_wrap__2klDq .common_mobile_menu_top_area__3ziQd img{
		height:32px;
	}
	.common_mobile_my_area__x2h0y .common_my_wrap__1oYVs .common_top_area__1Ir0S{
		height:50px;
	}
	.common_mobile_my_area__x2h0y .common_my_wrap__1oYVs .common_left_area__2Nwxl{
		height:32px;
	}
	.common_mobile_my_area__x2h0y .common_my_wrap__1oYVs .common_bottom_area__16Bx2{
		height:32px;
	}
	.common_mobile_menu_wrap__2klDq .common_bottom_menu_area__3f9w5 a{
		height:32px;
		font-size:13px;
	}
	
	/* footer */
	section.common_footer_section__23XeD{
		padding-top:30px;
		padding-bottom:30px;
	}
	.common_footer__18aab .common_inner90__3qaGh{
		-webkit-box-align:start;
		flex-direction:row;
		align-items:flex-start;
	}
	.common_f_left__1epgt{
		width:75%;
	}
	.common_f_right__-R8OE{
		width:25%;
	}
	.common_f_right__-R8OE ul{
		margin-top:0;
	}
	.common_f_right__-R8OE li:nth-child(3){
		text-align:center;
	}
	.common_f_left__1epgt ul{
		align-items:flex-start;
	}
	.common_f_left__1epgt li{
		text-align:left;
	}
	.common_f_left__1epgt li:nth-child(1){
		margin-bottom:15px;
		margin-left:64px;
		display:none;
	}
	.common_f_left__1epgt li:nth-child(3){
		margin-bottom:16px;
	}
	.common_f_left__1epgt li br{
		display:none
	}
	
	/*index.html*/
	.common_index_tit__3e1q3{
		font-size:1.25em;
	}
	/*main_banner_area */
	.common_bn_content_wrap__2v3GE{
		padding-top:0;
		padding-bottom:5vh;
	}
	.common_bn_content_wrap__2v3GE .common_inner90__3qaGh{
		-webkit-box-pack:center;
		justify-content:center;
		flex-direction:row;
	}
	.common_bn_content_wrap__2v3GE img{
		margin-bottom:0;
		margin-left:3vw;
		height:auto;
		width:40vw;
	}

	/*introduce_area*/
	.common_introduce_area__2LRd7{
		padding-top:0;
		padding-bottom:5vh;
	}
	.common_introduce_area__2LRd7 .common_top_area__1Ir0S{
		flex-direction:row;
	}
	.common_introduce_area__2LRd7 .common_top_area__1Ir0S img{
		height:40vh;
		margin-right:4vw;
	}
	.common_introduce_area__2LRd7 .common_top_area__1Ir0S .common_right_area__3060r{
		align-items:flex-start;
		margin-bottom:0;
	}
	.common_introduce_area__2LRd7 .common_right_area__3060r p{
		text-align:left;
	}
	.common_introduce_area__2LRd7 .common_right_area__3060r p br{
		display:block;
	}
	.common_introduce_area__2LRd7 .common_ym_who_popup__3du5G.common_on__2QUU7{
		flex-direction:row;
	}
	.common_introduce_area__2LRd7 .common_ym_who_btn__1oA6w{
		margin-top:3vh;
	}
	.common_introduce_area__2LRd7 .common_ym_who_btn__1oA6w span{
		font-size:13px;
	}

	/*ym_world_area*/
	.common_ym_world_area__3ao46{
		padding-top:0;
		padding-bottom:5vh;
	}
	.common_ym_world_area__3ao46 .common_index_tit__3e1q3{
		flex-direction:row;
	}
	.common_ym_world_area__3ao46 p.common_txt__3s3ZA br{
		display:block;
	}
	.common_ym_world_area__3ao46 .common_bottom_area__16Bx2{
		-webkit-box-align:center;
		-webkit-box-pack:center;
		flex-direction:row;
		justify-content:center;
		align-items:center;
	}
	.common_ym_world_area__3ao46 .common_right_area__3060r{
		height:30vh;
		width:50%;
		margin-left:3vw;
		top:0;
	}
	.common_ym_world_area__3ao46 .common_building_wrap__3bCH9{
		width:100%;
		height:100%;
	}

	/*ym_difference_area*/
	.common_ym_difference_area__GVl5X{
		padding-top:0;
		padding-bottom:5vh;
	}
	.common_ym_difference_area__GVl5X .common_middle_area__2Omh4{
		margin-top:4vh;
		height:25vh;
	}
	.common_ym_difference_area__GVl5X .common_img_area__3KniS{
		display:none;
	}

	/*ym_myroom_area*/
	.common_ym_myroom_area__1iNyx{
		padding-top:0;
		padding-bottom:5vh;
	}
	.common_ym_myroom_area__1iNyx .common_index_tit__3e1q3{
		flex-direction:row;
	}
	.common_ym_myroom_area__1iNyx .common_bottom_area__16Bx2{
		flex-direction:row;
	}
	.common_ym_myroom_area__1iNyx .common_box__2MqeC{
		width:22vw;
		height:auto;
		margin:0 1vw;
	}
	.common_ym_myroom_area__1iNyx .common_box__2MqeC.common_on__2QUU7{
		margin:0 2vw;
	}
	.common_ym_myroom_area__1iNyx .common_content__2kYUC .common_graph_area3__22g6W img{
		width:80%;
	}
	.common_ym_myroom_area__1iNyx .common_content__2kYUC .common_graph_area3__22g6W .common_counting__1dwuc{
		font-size:.8em;
	}
	.common_ym_myroom_area__1iNyx .common_content__2kYUC .common_graph_area3__22g6W .common_count1__UguBq{
		top:62%;
		left:8%;
	}
	.common_ym_myroom_area__1iNyx .common_content__2kYUC .common_graph_area3__22g6W .common_count2__3ZZTt{
		top:62%;
	}
	.common_ym_myroom_area__1iNyx .common_content__2kYUC .common_graph_area3__22g6W .common_count3__3sNcj{
		top:62%;
		left:67%;
	}
	.common_ym_myroom_area__1iNyx .common_graph_area1__2BgSb .common_wrap__ornby{
		width:53%;
	}
	.common_ym_myroom_area__1iNyx .common_content__2kYUC .common_graph_area1_graph__gMu1n{
		width:75%;
		position:relative;
		top:-8%;
	}
	.common_ym_myroom_area__1iNyx .common_content__2kYUC .common_bottom_area__16Bx2{
		height:48%;
	}

	/*brain_music_area*/
	.common_brain_music_area__NtTkf{
		padding-top:0;
		padding-bottom:15vh;
	}
	.common_brain_music_area__NtTkf .common_index_tit__3e1q3{
		justify-content:center;
	}
	.common_brain_music_area__NtTkf .common_wrap__ornby{
		width:64%;
	}
	.common_brain_music_area__NtTkf .common_wrap__ornby ul{
		width:100%;
	}
	.common_brain_music_area__NtTkf .common_wrap__ornby li{
		padding:1vh 2vw;
	}
	.common_brain_music_area__NtTkf .common_wrap__ornby li .common_play_wrap__3KOSn{
		margin-right:2vw;
	}

	/*coding_block_area*/
	.common_coding_block_area__2gmOW{
		padding-top:0;
		padding-bottom:10vh;
	}
	.common_coding_block_area__2gmOW .common_index_tit__3e1q3{
		flex-direction:row;
	}
	.common_coding_block_area__2gmOW .common_bottom_area__16Bx2{
		flex-direction:row;
		height:auto;
	}
	.common_coding_block_area__2gmOW .common_cont__1FF3G .common_num__1L4QU{
		height:4.5vh;
	}
	.common_coding_block_area__2gmOW .common_cont__1FF3G img:last-child{
		display:none;
	}

	/*yf_area*/
	.common_yf_area__4xLfs{
		padding-top:0;
		padding-bottom:10vh;
	}
	.common_yf_area__4xLfs .common_content_area__2tBPk{
		flex-direction:row;
	}
	.common_yf_area__4xLfs .common_content_area__2tBPk .common_btn_area__3WMBi{ 
		width:20%;
		height:36vh;
		flex-direction:column;
	}
	.common_yf_area__4xLfs .common_content_area__2tBPk .common_btn_area__3WMBi li{
		margin:1vh 0;
		height:20%;
		position:relative;
	}
	.common_yf_area__4xLfs .common_content_area__2tBPk .common_btn_area__3WMBi li.common_on__2QUU7{
		height:20%;
	}
	.common_yf_area__4xLfs .common_content_area__2tBPk .common_btn_area__3WMBi li.common_on__2QUU7:hover{
		height:20%;
	}
	.common_yf_area__4xLfs .common_content_area__2tBPk .common_btn_area__3WMBi li img{
		height:70%;
	}
	.common_yf_area__4xLfs .common_content_area__2tBPk .common_btn_area__3WMBi li.common_on__2QUU7 img{
		height:100%;
	}
	.common_yf_area__4xLfs .common_yf_info_area__3AaKI{
		width:50%;
	} 
	
}



